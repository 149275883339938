import { Divider } from "@material-ui/core";
import Style from "./breakdown_table.module.css";
import BreakdownTableRow from "./BreakdownTableRow/BreakdownTableRow";
import { useEffect, useState } from "react";
import { getCurrencyIcon, numberToLocaleString } from "../../util/Shipment/shipment";
import SectionSubHeading from "../Common/Heading/SectionSubHeading/SectionSubHeading";
import { findLowestPriceItem, sectionName } from "sites/shipper/pages/shipment_rates/RateSheetContent";
import { TRANSPORT_MODES,CARGO_CATEGORIES } from 'util/constants';
/* eslint-disable */

const BreakdownTable = ({ data, transportMode, loadType }) => {

    const [subTotal, setSubTotal] = useState(0);
    useEffect(() => {
        let sum = 0;
        if (data) {
            let array = data.data
            if (data.title === sectionName["Shipment Charges"]) {
                if (transportMode === TRANSPORT_MODES.SEA.value && loadType === CARGO_CATEGORIES.RORO.value) {
                    array = findLowestPriceItem(data.data)
                }  
                if (transportMode === TRANSPORT_MODES.AIR.value) {
                    array = findLowestPriceItem(data.data)
                }
            }
           
            array.map(data => sum += Number(data.amount))
        }
        setSubTotal(sum)
    }, [data])


    const renderPriceLabel = () => {
        let label = "Subtotal"
        if (data.title === sectionName["Shipment Charges"]) {
            if (transportMode === TRANSPORT_MODES.AIR.label) {
                label = "Starting With"
            }
            if (transportMode === TRANSPORT_MODES.SEA.label && loadType === CARGO_CATEGORIES.RORO.value) {
                label = "Starting With"
            }
        }
        return label
    }
    return (
        <div style={{ overflowX: 'auto' }}>
            <div className={Style.container}>

                <div className={Style.head_container}>
                    {/* <div style={{ display: "flex", gap: '1rem', alignItems: 'center' }}>
                        <span className={Style.title}>{data?.title}</span>
                    </div> */}
                    <SectionSubHeading title={data.title} />
                </div>

                <Divider />

                <div className={Style.table_header}>
                    <div>Fee Code </div>
                    <div>Fee Name </div>
                    <div>Comment </div>
                    <div>Units </div>
                    <div>Unit Price </div>
                    <div>Amount</div>
                </div>

                {data?.data.map((d, index) => {
                    return (
                        <BreakdownTableRow key={index} data={d} />
                    )
                })}

                <Divider />

                <div className={Style.table_header}>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div className="font-weight-bold">{renderPriceLabel()} </div>
                    <div className="font-weight-bold">{getCurrencyIcon("usd")}{numberToLocaleString(subTotal)} USD</div>
                </div>
            </div>
        </div>
    );
}
/* eslint-enable */
export default BreakdownTable;