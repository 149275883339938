import React, { useState } from 'react'
import Table from './Table/Table'
import Style from "./Preview.module.css";
import EmptyContainer from 'sites/shipper/components/EmptyContainer/EmptyContainer';
import { Button } from '@material-ui/core';
import AddSection from '../AddSection';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';

const Preview = ({ transportMode, handleAddSection, handleReset, pageData, method, breakdownData, setBreakdownData, isSubmitDisable, setIsSubmitDisable, additionalData }) => {
    // console.log(breakdownData)
    const [addSectionPopup, setAddSectionPopup] = useState(false)

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);


    return (
        <>
            <div className={Style.container}>
                <div className='d-flex justify-content-between'>
                    <span className={Style.heading}>Preview</span>
                    <div className="d-flex c-gap-1">
                        <Button className="btn-primary px-4" onClick={() => setAddSectionPopup(true)}>
                            Add Section
                        </Button>
                        <Button className="btn-danger px-4" onClick={() => setConfirmDialogOpen(true)}>
                            Clear All
                        </Button>
                    </div>
                </div>

                <div className={Style.main}>
                    {breakdownData?.price_breakdown.length > 0 ?
                        <>
                            {breakdownData?.price_breakdown?.map((data, idx) => (
                                <Table additionalData={additionalData} isSubmitDisable={isSubmitDisable} setIsSubmitDisable={setIsSubmitDisable} transportMode={transportMode} pageData={pageData} method={method} key={idx} idx={idx} data={data} breakdownData={breakdownData} setBreakdownData={setBreakdownData} />
                            ))}
                        </>
                        :
                        <div className='d-flex align-items-center  justify-content-center'>
                            <EmptyContainer
                                title='Select Load type'
                                description='Please select a load type to create the price breakdown'
                            />
                        </div>
                    }



                </div>
            </div>

            {addSectionPopup && <AddSection
                open={addSectionPopup}
                setOpen={setAddSectionPopup}
                breakdownData={breakdownData}
                handleSubmit={handleAddSection}
            />}

            <ConfirmDialog
                confirmDialogOpen={confirmDialogOpen}
                setConfirmDialogOpen={setConfirmDialogOpen}
                confirmDialogFunction={handleReset}
                question={"Are you sure you want to reset this price breakdown?"}
            />
        </>
    )
}

export default Preview