import React, { useEffect, useState } from 'react';
import { Button, MenuItem, Tooltip } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  BlogService,
  CareerService,
  DocumentListService,
  DocumentService
} from 'service';
import { notify } from 'sites/shipper/components/Toast/toast';
import { Empty } from 'antd';

import LoaderComponent from 'sites/shipper/components/LoaderComponent';
import AddDocument from './AddDocument/AddDocument';
import { Pagination } from '@mui/material';
import ConfirmDialog from '../../shipment_rates/rate_sheet/ConfirmDialog/ConfirmDialog';
import TableWithSearchBar from 'components/Tables/TableWithSearchBar';
import CachedIcon from '@mui/icons-material/Cached';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import SmallPopupWrapper from 'components/Common/SmallPopupWrapper/SmallPopupWrapper';


/* eslint-disable */
const Document = () => {
  const [open, setOpen] = useState(false);

  const [run, setRun] = useState(false);
  const [documents, setDocuments] = useState([]);

  const [documentData, setDocumentData] = useState(null);
  const history = useHistory();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState('');

  const auth = useSelector((state) => state.session.auth);

  const searchParams = new URLSearchParams(location.search);
  
  const [documentCount, setDocumentCount] = useState(0);

  const pageValue = searchParams.get('page') ? searchParams.get('page') : 1;
  const [page, setpage] = useState(parseInt(pageValue));
  const itemsPerPage = 15;


  const [check, setCheck] = useState(true);

  useEffect(() => {
    if (loading) return;

    const element = document.getElementById('top');
    element.scrollIntoView({ behavior: 'smooth' });
  }, [check]);

  function handlePageChange(event, value) {
    setpage(value);
    setCheck(!check);
    history.push(`/document?page=${value}`);
  }


  useEffect(() => {
    setLoading(true);

    DocumentListService.fetchDocuments(auth.token, search,page).then((res) => {
      if (res.error) {
        notify(dispatch, 'error', res.error);
        setLoading(false);
      } else {
        setDocuments(res?.data);
        setDocumentCount(res?.documentCount);
        setLoading(false);
      }
    });
  }, [run, search,page]);


  const TableHeading = [
    'S.No.',
    'Name',
    'Description',
    // 'Applicable for',
    'Applicable in',
    "Show on shipment overview",
    'Is Sensitive',
    'Is Active',

    'Action'
  ];

  const Keys = [
    'index',

    'name',
    'description',
    // 'applicable_for',
    'applicable_in',
    "show_in_overview",
    'is_sensitive',
    'is_active',

    '_id'
  ];

  const [openMoreOptions, setOpenMoreOptions] = useState(false);
  const [moreOptionClicked, setMoreOptionClicked] = useState(null);


  const TableData = documents?.map((item, index) =>
    Keys.map((Key) => {
      if (Key === 'index') {
        return index + 1;
      }

      if (Key === 'applicable_for' || Key === 'applicable_in') {
        return item[Key].join(", ")
      }
      if (Key === 'is_sensitive' || Key === 'is_active' || Key === "show_in_overview") {
        return item[Key] ? 'Yes' : 'No';
      }



      if (Key === '_id') {
        return (
          <>
            <div className="position-relative">
              <center>
                <Tooltip title="More" arrow>
                  <Button
                    variant="text"
                    className="btn-outline-primary d-flex align-items-center justify-content-center d-30 p-0 rounded-pill shadow-none"
                    onClick={(e) => {
                      setOpenMoreOptions(e.currentTarget);
                      setMoreOptionClicked(item);
                    }}>
                    <MoreVertTwoToneIcon style={{ fontSize: '18px' }} />
                  </Button>
                </Tooltip>
              </center>
              {moreOptionClicked?._id === item?._id && (
                <SmallPopupWrapper
                  open={openMoreOptions}
                  onClose={() => {
                    setMoreOptionClicked(null);
                    setOpenMoreOptions(null);
                  }}>
                  <MenuItem
                    onClick={() => {
                      setOpen(true);
                      setOpenMoreOptions(null);
                      setDocumentData(moreOptionClicked);

                    }}>
                    Edit
                  </MenuItem>


                </SmallPopupWrapper>
              )}
            </div>
          </>
        );
      } else {
        return item[Key] || '';
      }
    })
  );

  return (
    <div id="top">
      {/* {loading && <LoaderComponent loading={loading} />} */}
      <AddDocument
        open={open}
        setOpen={setOpen}
        run={run}
        setRun={setRun}
        handleClose={() => setOpen(false)}
        documentData={documentData}
        setDocumentData={setDocumentData}
      />

      <TableWithSearchBar
        animatedSearchbar={{
          show: true,
          value: search,
          onChange: (value) => setSearch(value),
          setLoading: setLoading
        }}
        loading={loading}
        tableMainHeading={'All Documents'}
        TableHeading={TableHeading}
        TableBody={TableData}
        TableGrid={'0.2fr 1fr 0.7fr repeat(3,1fr) repeat(3,0.2fr)'}
        actionButtons={[
          <Button
            variant="text" 
            onClick={() => setRun(!run)}
            className={`btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill`}>
            <CachedIcon className="w-50" />
          </Button>,
          <Button
            variant="contained"
            className="btn-primary shadow-none py-2"
            onClick={() => setOpen(true)}>
            Add Document
          </Button>
        ]}

        pagination={{
          count: Math.ceil(documentCount / itemsPerPage),
          page: page,
          onChange: handlePageChange
        }}
      />
    </div>
  );
};
/* eslint-enable */

export default Document;
