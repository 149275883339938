import { getBaseUrl } from 'config/config';
import { HttpMethods, HttpService } from './index';
import { httpService } from './global';

export default class CarrierVesselsService {
  // fetchTransportUnitNamesData;
  static async fetchCarrierVesselsData(id, token, keyword,page) {
    const url = `admin/fetch-carrier-vessels/${id}?keyword=${keyword}&page=${page}`;

      return httpService(HttpMethods.GET, url, null, {
        token,
        type: 'carrier',
        method: 'read',
        platform: 'Admin'
      })
        .then((res) => {
          return res;
        })
        .catch((err) => console.log(err));
 
  }

  static async updateCarrierVessel(data, token) {
    const url = `admin/update-carrier-vessel`;

          return httpService(HttpMethods.PUT, url, data, {
            token,
            type: 'carrier',
            method: 'edit',
            platform: 'Admin'
          })
            .then((res) => {
              return res;
            })
            .catch((err) => console.log(err));
   
  }

  static async createCarrierVessel(data, token) {
    const url = `admin/create-carrier-vessel`;

     return httpService(HttpMethods.POST, url, data, {
       token,
       type: 'carrier',
       method: 'create',
       platform: 'Admin'
     })
       .then((res) => {
         return res;
       })
       .catch((err) => console.log(err));
  
  }

  static async deleteCarrierVessel(data, token) {
    const url = `admin/delete-carrier-vessel`;

         return httpService(HttpMethods.DELETE, url, data, {
           token,
           type: 'carrier',
           method: 'delete',
           platform: 'Admin'
         })
           .then((res) => {
             return res;
           })
           .catch((err) => console.log(err));
   
  }
}
