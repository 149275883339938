import React from 'react'
import Style from './tableWithSearchBar.module.css'
import { Tooltip } from '@material-ui/core'
import AnimatedSearchbar from 'components/Common/AnimatedSearchbar/AnimatedSearchbar'
import SkeletonComponent from 'sites/shipper/components/Skeleton/Skeleton'
import { Pagination } from '@mui/material';
import EmptyContainer from 'sites/shipper/components/EmptyContainer/EmptyContainer';



const TableWithSearchBar = ({
  animatedSearchbar = {},
  actionButtons = [],
  tableMainHeading,
  TableHeading,
  TableBody,
  TableGrid,
  loading = false,
  pagination = null,
  EmptyContainerTitle = "No Date Found",
  EmptyContainerDesc = "",
  EmptyContainerSource = ""
}) => {
  return (
    <>
      <div className={Style.main}>
        {animatedSearchbar.show ? (
          <AnimatedSearchbar
            value={animatedSearchbar?.value}
            onChange={animatedSearchbar?.onChange}
            setLoading={animatedSearchbar?.setLoading}
            placeholder={animatedSearchbar?.placeholder}
          >
            {actionButtons.map((item, index) => {
              return item;
            })}
          </AnimatedSearchbar>
        ) : (
          <div className={Style.tableMainHeadingContainer}>
            <div className="app-page-title--heading ml-2">
              <h1>{tableMainHeading}</h1>
            </div>
            <div className="d-flex c-gap-half">
              {actionButtons.map((item, index) => {
                return item;
              })}
            </div>
          </div>
        )}

        <div className={Style.containerTable}>
          <div
            className={Style.cointainerHeading}
            style={{ gridTemplateColumns: TableGrid }}>
            {TableHeading.map((data, index) => (
              <span
                style={{
                  textAlign:
                    index === TableHeading.length - 1 ? 'center' : 'start'
                }}
                key={index}>
                {data}
              </span>
            ))}
          </div>
          {loading && <SkeletonComponent height="60px" />}
          {loading === false &&
            <>
              {TableBody?.length > 0 ?
                TableBody?.map((ex, index) => {
                  return (
                    <div
                      className={Style.containerBody}
                      style={{ gridTemplateColumns: TableGrid }}>
                      {ex.map((item, idx) => {
                        if (typeof item === 'string') {
                          return (
                            <Tooltip title={item} placement='bottom-start'>
                              <div
                                key={idx}
                                className={`text-truncate ${Style.cointainerBodyText}`}>
                                {item}
                              </div>
                            </Tooltip>
                          );
                        } else {
                          return (
                            <div
                              key={idx}
                              className={`text-truncate ${Style.cointainerBodyText}`}>
                              {item}
                            </div>
                          );
                        }
                      })}
                    </div>
                  );
                })
                :
                <div className="px-2">
                  <EmptyContainer
                    title={EmptyContainerTitle}
                    description={EmptyContainerDesc}
                    source={EmptyContainerSource}
                  />
                </div>}
            </>
          }
        </div>
        {pagination && TableBody?.length > 0 &&
          <div className="d-flex justify-content-end my-2">
            <Pagination
              className="pagination-primary"
              count={pagination.count}
              page={pagination.page}
              onChange={pagination.onChange}
            />
          </div>
        }
      </div>
    </>
  );
};

export default TableWithSearchBar