import { getBaseUrl } from 'config/config';
import { HttpMethods, HttpService } from './index';
import { httpService } from './global';

export default class TrackingSourcesService {
  static async fetchCarrierTrackingSourceData(id, token, keyword, page) {
    const url = `admin/fetch-carrier-tracking-source/${id}?keyword=${keyword}&page=${page}`;

    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'carrier',
      method: 'read',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));

  }

  static async updateCarrierTrackingSource(data, token) {
    const url = `admin/update-carrier-tracking-source`;


    return httpService(HttpMethods.PUT, url, data, {
      token,
      type: 'carrier',
      method: 'edit',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));

  }

  static async createCarrierTrackingSource(data, token) {
    const url = `admin/create-carrier-tracking-source`;

    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'carrier',
      method: 'create',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));

  }

  static async deleteCarrierTrackingSource(data, token) {
    const url = `admin/delete-carrier-tracking-source`;

    return httpService(HttpMethods.DELETE, url, data, {
      token,
      type: 'carrier',
      method: 'delete',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));

  }
}
