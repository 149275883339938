import React, { useEffect, useState } from 'react'
import Style from "./LeadDetails.module.css";
import { Button } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import { CARGO_CATEGORIES, CARGO_TYPES } from 'util/constants';
import { loadDisplay, renderShipmentAddress } from 'util/Shipment/shipmentLoads';

const ServiceText = ({ title }) => (
    <span className='d-flex c-gap-half align-items-center'>
        <div style={{ width: '7px', height: '7px', borderRadius: '50%', background: 'var(--primary)' }}></div>
        <span>{title}</span>
    </span>
)


const LeadDetails = ({ pageData }) => {



    const [services, setServices] = useState([])
    useEffect(() => {
        const array = []
        pageData?.services?.map((item) => {
            array.push(item.serviceName)
        })
        setServices(array)
    }, [pageData?.services])



    return (
        <div className={Style.container}>
            <span className={Style.title}>Shipment Summary | {pageData?.request_id}</span>

            <div className='divider' />
            <div className={Style.top_details}>
                <div>
                    <span className={Style.label}>Transportation Mode:</span>
                    <span className={Style.value}>{pageData?.transport_mode}</span>

                    <span className={Style.label}>Ready On:</span>
                    <span className={Style.value}>{pageData?.ready_on}</span>
                </div>
                <div>
                    <span className={Style.label}>Origin:</span>
                    <span className={Style.value}>
                        {renderShipmentAddress(pageData?.origin)}
                        {/* {pageData?.origin?.city?.name},&nbsp;{pageData?.origin?.country?.name}&nbsp;&nbsp;|&nbsp;&nbsp;{pageData?.origin?.port?.name} */}
                    </span>

                    <span className={Style.label}>Destination:</span>
                    <span className={Style.value}>
                        {renderShipmentAddress(pageData?.destination)}
                        {/* {pageData?.destination?.city?.name},&nbsp;{pageData?.destination?.country?.name}&nbsp;&nbsp;|&nbsp;&nbsp;{pageData?.destination?.port?.name} */}
                    </span>
                </div>
            </div>

            <div className={`d-flex align-items-center justify-content-between flex-wrap c-gap-2`}>
                <div className={Style.simple_grid}>
                    <span className={Style.label}>Shipment Details:</span>
                    <span className={Style.value}>
                        {loadDisplay(pageData?.load)}
                    </span>

                    {pageData?.load?.cargoType?.value === CARGO_CATEGORIES.RORO.value && <>
                        <span className={Style.label}>Vehicle type:</span>
                        <span className={Style.value}>
                            {pageData?.load?.cargoDetails?.isVehicleElectric ? "Electric" : "Non Electric"}
                        </span>
                    </>}

                    <span className={Style.label}>Requested Services:</span>
                    <span className={Style.value}>
                        {services.length > 0 ?
                            <>
                                {services.map((item, index) => (
                                    <ServiceText title={item} key={index} />
                                ))}
                            </>
                            :
                            <span style={{ fontSize: "12px" }}>
                                No Services Selected
                            </span>}
                    </span>

                </div>

            </div>


            <div className='divider' />

            <span className={Style.title}>Cargo Description : </span>
            <span className={Style.value}>
                {pageData?.load?.cargoDetails?.description ? pageData?.load?.cargoDetails?.description : "NA"}
            </span>

            <div className='divider' />

            <span className={Style.title}>Selected Commodities : </span>

            {pageData.commodity && pageData.commodity.length > 0 ?
                <>

                    {pageData.commodity.map((item, index) => (
                        <span className={`${Style.value} align-items-center`}>
                            <CircleRoundedIcon style={{ width: '8px', height: '8px', color: "rgba(0, 0, 0, 0.6)" }} />
                            <span className={Style.card_desc}>{item.code} - {item.description}</span>
                        </span>
                    ))}
                </>
                :
                <>
                    <span className={Style.value}>
                        No Commodity Selected
                    </span>
                </>
            }


            {pageData.additional_info &&
                <>

                    <div className='divider' />

                    <span className={Style.title}>Additional Information : </span>
                    <span className={Style.value}>
                        {pageData?.additional_info}
                    </span>
                </>
            }
        </div>
    )
}

export default LeadDetails