import { getBaseUrl } from 'config/config';
import { HttpMethods, HttpService } from './index';
import { httpService } from './global';

export default class PendingRegistrationService {


    static async fetchPendingRegistration(data, token) {
        const url = `admin/pending-registration/fetch`;

        return httpService(HttpMethods.POST, url, data, {
            token,
            type: 'pending_users',
            method: 'read',
            platform: 'Admin'
        })
            .then((res) => {
                return res;
            })
            .catch((err) => console.log(err));
    }

    static async downloadPendingRegistrationsList(data, token) {
        const url = `admin/pending-registration/download`;

        return httpService(HttpMethods.POST, url, data, {
            token,
            type: 'pending_users',
            method: 'download',
            platform: 'Admin'
        })
            .then((res) => {
                return res;
            })
            .catch((err) => console.log(err));
    }

    static async resendRegistrationLink(data, token) {
        const url = `admin/pending-registration/resend-link`;

        return httpService(HttpMethods.POST, url, data, {
            token,
            type: 'pending_users',
            method: 'sendRegistrationLink',
            platform: 'Admin'
        })
            .then((res) => {
                return res;
            })
            .catch((err) => console.log(err));
    }

    static async deletePendingRegistration(data, token) {
        const url = `admin/pending-registration/delete`;

        return httpService(HttpMethods.POST, url, data, {
            token,
            type: 'pending_users',
            method: 'delete',
            platform: 'Admin'
        })
            .then((res) => {
                return res;
            })
            .catch((err) => console.log(err));
    }
}