import React, { useEffect, useState } from 'react';

import PageContent from 'sites/shipper/components/layout/PageContent';

import { useHistory } from 'react-router-dom';

import PageTitle from 'sites/shipper/components/layout/PageTitle/PageTitle';
import { TRANSPORT_MODES } from 'util/constants';
import Tabs from 'components/Tabs';
import SeaCarriers from './CarrierTabs/seaCarriers';
import AirCarriers from './CarrierTabs/airCarriers';

/* eslint-disable */
const Carriers = () => {

  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  const tab = searchParams.get('tab') ? searchParams.get('tab') : TRANSPORT_MODES.SEA.value.toLowerCase();

  const content = [
    {
      label: TRANSPORT_MODES.SEA.label,
      value: TRANSPORT_MODES.SEA.value.toLowerCase(),
      View: <SeaCarriers />
    },
    {
      label: TRANSPORT_MODES.AIR.label,
      value: TRANSPORT_MODES.AIR.value.toLowerCase(),
      View: <AirCarriers />
    },

  ];
  return (
    <>
      <PageTitle

        titleHeading={`Carriers`}></PageTitle>
      <PageContent>

        <Tabs
          content={content}
          active={tab || content[0].value}
          onClick={(val) => history.push(`/carriers?tab=${val}`)}
        />

      </PageContent>


    </>
  );
};
/* eslint-enable */
export default Carriers;
