
import { getBaseUrl } from 'config/config';
import { HttpMethods, HttpService } from './index';
import { httpService } from './global';


export default class ConstantValuesService {


  static async uploadToS3(formData, token) {
    const url = `${getBaseUrl()}constants/upload-to-s3`;
    // return httpService(HttpMethods.POST, url, data, token).then((res) => { return res }).catch((err) => console.log(err));

    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      },
      body: formData
    })
    const result = await res.json()
    return result
  }


  static async deleteFromS3(data, token) {
    const url = `constants/delete-from-s3`;
    return httpService(HttpMethods.POST, url, data, token)
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));

  }


  static async fetchCountryList(query) {
    const url = `${getBaseUrl()}constants/fetch-country?query=${query}`
    const response = await fetch(url)
    const result = await response.json()
    return result
  }


  static async fetchStateList(country = "", query = "") {
    const url = `${getBaseUrl()}constants/fetch-state?country=${country}&query=${query}`
    const response = await fetch(url)
    const result = await response.json()
    return result
  }



  static async fetchCityList(state = "", query = "") {
    const url = `${getBaseUrl()}constants/fetch-city?state=${state}&query=${query}`
    const response = await fetch(url)
    const result = await response.json()
    return result
  }

  static async fetchPortCodes(city = "", query = "", transportMode = "") {
    const url = `${getBaseUrl()}constants/fetch-port-code?city=${city}&query=${query}&transportMode=${transportMode}`
    const response = await fetch(url)
    const result = await response.json()
    return result
  }

  static async fetchHsCodeCategory() {
    const url = `${getBaseUrl()}constants/fetch-hs-code-category`
    const response = await fetch(url)
    const result = await response.json()
    return result
  }


  // createTransportLine;
  static async createCarrier(data, token) {
    const url = `constants/save-carrier`;

    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'carrier',
      method: 'create',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));

  }

  static async editCarrier(id, data, token) {
    const url = `constants/edit-carrier?id=${id}`;

    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'carrier',
      method: 'edit',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));

  }

  static fetchCarrier(transport_mode, search, token, page) {

    const url = `constants/fetch-carrier?transport_mode=${transport_mode}&search=${search}&page=${page}`;
    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'carrier',
      method: 'read',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));

  }

  static async deleteCarrier(data, token) {
    const url = `constants/delete-carrier`;
    return httpService(HttpMethods.DELETE, url, data, {
      token,
      type: 'carrier',
      method: 'delete',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));

  }

  static fetchDocumentList(applicable_for, applicable_in, show_in_overview) {
    return HttpService.request(HttpMethods.GET, `constants/document-fetch?applicable_for=${applicable_for}&applicable_in=${applicable_in}&show_in_overview=${show_in_overview}`).then(
      (res) => res
    );
  }
}

