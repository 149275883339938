import React from 'react';



import ChatMakeOffer from '../Chat/ChatViewPane/OfferChat/ChatMakeOffer/ChatMakeOffer';
import SmartDocuments from '../SmartDocuments/SmartDocuments';
// import ShipperManagement from '../MyShipper/ShipperManagement/ShipperManagement';
import MyServices from '../MyServices/MyServices';
import MobileChatView from '../Chat/MobileChat/MobileChatView/MobileChatView';
import MobileShipmentDetails from '../Chat/MobileChat/MobileShipmentDetails/MobileShipmentDetails';
import MobileChat from '../Chat/MobileChat/MobileChat';
import AdminTeamPage from '../Team';
import Users from '../Users';
import MyShipperUser from '../Users/Shipper/MyShipper';
import ForwarderUser from '../Users/Forwarder/Forwarder';
import RateSheetInfo from '../shipment_rates/RateSheetInfo/RateSheetInfo';
import TransportLines from '../TransportLines/Carriers';
import ServiceManagement from '../ServiceManagement/ServiceManagement';
import SingleServicePage from '../ServiceManagement/SingleServicePage/SingleServicePage';
import Blogs from '../Blogs/Blogs';
import CreateBlog from '../Blogs/Blog/CreateBlog/CreateBlog';
import Careers from '../Careers/Careers';
import SubscriberManagement from '../SubscriberManagement/SubscriberManagement';
import CreateRateSheet from '../shipment_rates/rate_sheet/CreateRateSheet/CreateRateSheet';
import ViewTransportationLines from '../TransportLines/ViewTransportationLines/ViewCarriers';
import Index from '../TransportLines/ViewTransportationLines';
import Carriers from '../TransportLines/Carriers';
import DocumentList from '../DocumentList/DocumentList';
import { userPermission } from 'util/adminPermissionRoles';
// import ShippingLeads from '../ShippingLeads/ShippingLeads';

const ShippingLeads = React.lazy(() =>
  import('../ShippingLeads/ShippingLeads')
);

const ShipperDashboard = React.lazy(() => import('../Dashboard'));
const ShipperShipments = React.lazy(() => import('../Shipments'));
const ShipperProfile = React.lazy(() => import('../Profile'));
const ShipperSettings = React.lazy(() => import('../Settings'));
const ShipperTransactions = React.lazy(() => import('../Transactions'));
const ShipperProducts = React.lazy(() => import('../Products'));
const ShipperCustomers = React.lazy(() => import('../Customers'));
const ShipperQuotes = React.lazy(() => import('../Orders'));
const ShipperDocuments = React.lazy(() => import('../Document/Documents'));
const ShipperChat = React.lazy(() => import('../Chat'));
const ShipperCalendar = React.lazy(() => import('../Calendar'));
const Analytics = React.lazy(() => import('../Analytics/Analytics'));
const ShipperBilling = React.lazy(() => import('../Billing'));
const Forwarder = React.lazy(() => import('../Forwarder/Forwarder'));
const MyShipper = React.lazy(() => import('../Shipper/MyShipper'));
const ShipperManagement = React.lazy(() =>
  import('../Shipper/ShipperManagement/ShipperManagement')
);
const ForwarderManagement = React.lazy(() =>
  import('../Forwarder/ForwarderManagement/ForwarderManagement')
);
const ShipmentRates = React.lazy(() =>
  import('../shipment_rates/ShipmentRates')
);

// Freight Pages
const FreightHome = React.lazy(() => import('../Freight'));

//  Shipper Sub Pages
const ShipperShipmentDetails = React.lazy(() =>
  import('../Shipments/ShipmentDetails')
);
const ShipperAccountsPersonal = React.lazy(() =>
  import('../SettingsPersonalInfo')
);
const ShipperAccountsCompany = React.lazy(() =>
  import('../SettingsCompanyInfo')
);
const ShipperAccountsShipping = React.lazy(() =>
  import('../SettingsShippingInfo')
);
const ShipperAccountsPayments = React.lazy(() =>
  import('../SettingsSubscription')
);
const ShipperAccountsPrivacy = React.lazy(() => import('../SettingsPrivacy'));
const ShipperAccountsSecurity = React.lazy(() => import('../SettingsSecurity'));
const ShipperAccountsReferrals = React.lazy(() =>
  import('../SettingsReferrals')
);

const MakeOffer = React.lazy(() =>
  import('../ShippingLeads/MakeOffer/MakeOffer')
);

const ShipmentDetail = React.lazy(() => import('../ShipmentDetail'))

const ForwarderAnalytics = React.lazy(() => import('../Billing/Payout/ForwarderAnalytics/ForwarderAnalytics'))
const TicketSystem = React.lazy(() => import('../TicketSystem/TicketSystem'))
const IndividualTicket = React.lazy(() => import('../TicketSystem/TicketCompletePopup/IndividualTicket'))

const SourceManagment = React.lazy(() => import('../SourceManagment/SourceManagment'))
const TagManagment = React.lazy(() => import('../TagManagment/TagManagment'))
const PendingRegistrations = React.lazy(() => import('../PendingRegistrations/PendingRegistrations'))
const ShipperMetaData = React.lazy(() => import('../UserMetrics/ShipperMetaData/ShipperMetaData'))
const UserMetrics = React.lazy(() => import('../UserMetrics'))
const ForwarderMetaData = React.lazy(() => import('../UserMetrics/ForwarderMetaData/ForwarderMetaData'))


const RouteMaps = {



  // '/smart-documents': {
  //   protected: true,
  //   exact: true,
  //   label: 'Smart Documents',
  //   primary: true,
  //   path: '/smart-documents',
  //   component: SmartDocuments
  // },
  // '/smart-documents/:route': {
  //   protected: true,
  //   exact: true,
  //   label: 'Smart Documents',
  //   primary: true,
  //   path: '/smart-documents/:route',
  //   component: SmartDocuments
  // },



  '/pending-users': {
    allowed: userPermission.pending_users.read,
    protected: true,
    exact: true,
    label: 'Pending Users',
    primary: true,
    path: '/pending-users',
    component: PendingRegistrations
  },

  '/source-managment': {
    allowed: userPermission.source_management.read,
    protected: true,
    exact: true,
    label: 'Source Managment',
    primary: true,
    path: '/source-managment',
    component: SourceManagment
  },
  '/tag-managment': {
    allowed: userPermission.tag_management.read,
    protected: true,
    exact: true,
    label: 'Tag Managment',
    primary: true,
    path: '/tag-managment',
    component: TagManagment
  },

  '/my-tickets': {
    allowed: [],
    protected: true,
    exact: true,
    label: 'My Tickets',
    primary: true,
    path: '/my-tickets',
    component: TicketSystem
  },
  '/my-tickets/:ticket_id': {
    allowed: [],
    protected: true,
    exact: true,
    label: 'Individual Ticket',
    primary: true,
    path: '/my-tickets/:ticket_id',
    component: IndividualTicket
  },




  '/articles': {
    allowed: userPermission.articles.read,
    protected: true,
    exact: true,
    label: 'Articles',
    primary: true,
    path: '/articles',
    component: Blogs
  },
  '/articles/:tab': {
    allowed: userPermission.articles.read,
    protected: true,
    exact: true,
    label: 'Articles',
    primary: true,
    path: '/articles/:tab',
    component: Blogs
  },

  '/blog-create': {
    allowed: userPermission.articles_blog.create,
    protected: true,
    exact: true,
    label: 'Blogs',
    primary: true,
    path: '/blog-create',
    component: CreateBlog
  },

  '/career': {
    allowed: userPermission.career.read,
    protected: true,
    exact: true,
    label: 'Careers',
    primary: true,
    path: '/career',
    component: Careers
  },
  '/subscriber-management': {
    allowed: userPermission.subscribers.read,
    protected: true,
    exact: true,
    label: 'Subscriber Management',
    primary: true,
    path: '/subscriber-management',
    component: SubscriberManagement
  },

  '/rate-sheet': {
    allowed: userPermission.rate_sheet.read,
    protected: true,
    exact: true,
    label: 'Rate Sheet',
    primary: true,
    path: '/rate-sheet',
    component: ShipmentRates
  },

  '/rate-sheet/:rateSheetId': {
    allowed: userPermission.rate_sheet.read,
    protected: true,
    exact: true,
    label: 'Rate Sheet',
    primary: true,
    path: '/rate-sheet/:rateSheetId',
    component: RateSheetInfo
  },
  '/rate-sheet-create': {
    allowed: userPermission.rate_sheet.create,
    protected: true,
    exact: true,
    label: 'Rate Sheet',
    primary: true,
    path: '/rate-sheet-create',
    component: CreateRateSheet
  },

  '/document': {
    allowed: userPermission.document.read,
    protected: true,
    exact: true,
    label: 'Documents',
    primary: true,
    path: '/document',
    component: DocumentList
  },

  '/carriers': {
    allowed: userPermission.carrier.read,
    protected: true,
    exact: true,
    label: 'Carriers',
    primary: true,
    path: '/carriers',
    component: Carriers
  },

  '/carriers/:id': {
    allowed: userPermission.carrier.read,
    protected: true,
    exact: true,
    label: 'Carriers',
    primary: true,
    path: '/carriers/:id',
    component: Index
  },

  '/service-management': {
    allowed: userPermission.service_management.read,
    protected: true,
    exact: true,
    label: 'Service Management',
    primary: true,
    path: '/service-management',
    component: ServiceManagement
  },

  '/service-management/:service_id': {
    allowed: userPermission.service_management.read,
    protected: true,
    exact: true,
    label: 'Service Management',
    primary: true,
    path: '/service-management/:service_id',
    component: SingleServicePage
  },

  '/shipment-request': {
    allowed: userPermission.shipment_request.read,
    protected: true,
    exact: true,
    label: 'Shipping Requests',
    primary: true,
    path: '/shipment-request',
    component: ShippingLeads
  },
  '/tools/shipment-request/make-offer/:id': {
    allowed: userPermission.shipment_request.create,
    protected: true,
    exact: true,
    label: 'Shipping Leads',
    primary: true,
    path: '/tools/shipment-request/make-offer/:id',
    component: MakeOffer
  },
  // '/shippment/my-proposal/view-offer/:id': {
  //   protected: true,
  //   exact: true,
  //   label: 'Shipping Leads',
  //   primary: true,
  //   path: '/shippment/my-proposal/view-offer/:id',
  //   component: MakeOffer
  // },
  // '/chat/make-offer': {
  //   protected: true,
  //   exact: true,
  //   label: 'Shipping Leads',
  //   primary: true,
  //   path: '/chat/make-offer',
  //   component: ChatMakeOffer
  // },

  // '/services': {
  //   protected: true,
  //   exact: true,
  //   label: 'My Services',
  //   primary: false,
  //   path: '/services',
  //   component: MyServices
  // },

  // '/mobi-chat/id': {
  //   protected: true,
  //   exact: true,
  //   label: 'Mobile Chat',
  //   primary: false,
  //   path: '/mobi-chat/id',
  //   component: MobileChatView
  // },
  // '/mobi-chat/id/shipment-details': {
  //   protected: true,
  //   exact: true,
  //   label: 'Mobile Chat',
  //   primary: false,
  //   path: '/mobi-chat/id/shipment-details',
  //   component: MobileShipmentDetails
  // },
  // '/mobi-chat': {
  //   protected: true,
  //   exact: true,
  //   label: 'Mobile Chat',
  //   primary: false,
  //   path: '/mobi-chat',
  //   component: MobileChat
  // },

  // '/': {
  //   allowed: userPermission.dashboard.read,
  //   protected: true,
  //   exact: true,
  //   label: 'Dashboard',
  //   primary: true,
  //   path: '/',
  //   component: Analytics
  // },

  '/dashboard': {
    allowed: userPermission.dashboard.read,
    protected: true,
    exact: true,
    label: 'Dashboard',
    primary: true,
    path: '/dashboard',
    component: Analytics
  },
  '/forwarder': {
    allowed: userPermission.forwarder.read,
    protected: true,
    exact: true,
    label: 'forwarder',
    primary: true,
    path: '/forwarder',
    component: Forwarder
  },
  '/forwarder/:id': {
    allowed: userPermission.forwarder_managment.read,
    protected: true,
    exact: true,
    label: 'Forwarder Management',
    primary: true,
    path: '/forwarder/:id',
    component: ForwarderManagement
  },
  '/shipper': {
    allowed: userPermission.shipper.read,
    protected: true,
    exact: true,
    label: 'Shipper',
    primary: true,
    path: '/shipper',
    component: MyShipper
  },
  '/shipper/:id': {
    allowed: userPermission.shipper_managment.read,
    protected: true,
    exact: true,
    label: 'My Shipper Management',
    primary: true,
    path: '/shipper/:id',
    component: ShipperManagement
  },
  '/user-metrics': {
    allowed: userPermission.user_metrics.read,
    protected: true,
    exact: true,
    label: 'User Metrics',
    primary: true,
    path: '/user-metrics',
    component: UserMetrics
  },
  '/shipper-meta/:id': {
    allowed: userPermission.user_metrics.read,
    protected: true,
    exact: true,
    label: 'Shipper Meta Details',
    primary: true,
    path: '/shipper-meta/:id',
    component: ShipperMetaData
  },
  '/forwarder-meta/:id': {
    allowed: userPermission.user_metrics.read,
    protected: true,
    exact: true,
    label: 'Forwarder Meta Details',
    primary: true,
    path: '/forwarder-meta/:id',
    component: ForwarderMetaData
  },
  '/team': {
    allowed: userPermission.team.read,
    protected: true,
    exact: true,
    label: 'My Team Management',
    primary: true,
    path: '/team',
    component: AdminTeamPage
  },

  // '/users': {
  //   protected: true,
  //   exact: true,
  //   label: 'User Management',
  //   primary: true,
  //   path: '/users',
  //   component: Users
  // },

  // '/users/shipper': {
  //   protected: true,
  //   exact: true,
  //   label: 'Shipper Account Management',
  //   primary: true,
  //   path: '/users/shipper',
  //   component: MyShipperUser
  // },

  // '/users/forwarder': {
  //   protected: true,
  //   exact: true,
  //   label: 'Forwarder Account Management',
  //   primary: true,
  //   path: '/users/forwarder',
  //   component: ForwarderUser
  // },
  // '/users/forwarder/:id': {
  //   protected: true,
  //   exact: true,
  //   label: 'Forwarder Management',
  //   primary: true,
  //   path: '/users/forwarder/:id',
  //   component: ForwarderManagement
  // },
  // '/users/shipper/:id': {
  //   protected: true,
  //   exact: true,
  //   label: 'My Shipper Management',
  //   primary: true,
  //   path: '/users/shipper/:id',
  //   component: ShipperManagement
  // },

  // '/shipments/:shipmentid/details': {
  //   protected: true,
  //   exact: true,
  //   label: 'Details',
  //   primary: false,
  //   path: '/shipments/:shipmentid/details',
  //   component: ShipperShipmentDetails
  // },
  '/shipments': {
    allowed: userPermission.shipments.read,
    protected: true,
    exact: true,
    label: 'Shipments',
    primary: true,
    path: '/shipments',
    component: ShipperShipments
  },
  '/shipment-detail/:shipment_id': {
    allowed: userPermission.shipment_details.read,
    protected: true,
    exact: true,
    label: 'Shipments Detail',
    primary: true,
    path: '/shipment-detail/:shipment_id',
    component: ShipmentDetail
  },
  '/shipments/:status': {
    allowed: userPermission.shipments.read,
    protected: true,
    exact: true,
    label: 'Shipments',
    primary: true,
    path: '/shipments/:status',
    component: ShipperShipments
  },
  // '/bookings': {
  //   protected: true,
  //   exact: true,
  //   label: 'Bookings',
  //   primary: true,
  //   path: '/bookings',
  //   component: ShipperQuotes
  // },
  // '/quotes': {
  //   protected: true,
  //   exact: true,
  //   label: 'Quotes',
  //   primary: true,
  //   path: '/quotes',
  //   component: ShipperQuotes
  // },
  // '/documents': {
  //   protected: true,
  //   exact: true,
  //   label: 'Documents',
  //   primary: true,
  //   path: '/documents',
  //   component: ShipperDocuments
  // },
  '/billing': {
    allowed: userPermission.billing.read,
    protected: true,
    exact: true,
    label: 'Billing',
    primary: true,
    path: '/billing',
    component: ShipperBilling
  },

  '/billing/:tab': {
    allowed: userPermission.billing.read,
    protected: true,
    exact: true,
    label: 'Billing',
    primary: true,
    path: '/billing/:tab',
    component: ShipperBilling
  },

  '/billing/forwarder-analytics/:forwarder_id': {
    allowed: userPermission.billing.read,
    protected: true,
    exact: true,
    label: 'Forwarder Analytics',
    primary: true,
    path: '/billing/forwarder-analytics/:forwarder_id',
    component: ForwarderAnalytics
  },







  // '/wallet': {
  //   protected: true,
  //   exact: true,
  //   label: 'Wallet',
  //   primary: true,
  //   path: '/wallet',
  //   component: ShipperTransactions
  // },
  // '/mailbox': {
  //   protected: true,
  //   exact: true,
  //   label: 'Mailbox',
  //   primary: true,
  //   path: '/mailbox',
  //   component: ShipperChat
  // },
  '/settings/account': {
    allowed: [],
    protected: true,
    exact: true,
    label: 'General Account Settings',
    primary: false,
    path: '/settings/account',
    component: ShipperSettings
  },
  // '/settings/company-info': {
  //   allowed: userPermission.company_settings.read,
  //   protected: true,
  //   exact: true,
  //   label: 'Company Info',
  //   primary: false,
  //   path: '/settings/company-info',
  //   component: ShipperSettings
  // },
  // '/settings/shipping-info': {
  //   protected: true,
  //   exact: true,
  //   label: 'Shipping Info',
  //   primary: false,
  //   path: '/settings/shipping-info',
  //   component: ShipperSettings
  // },
  // '/settings/subscription': {
  //   protected: true,
  //   exact: true,
  //   label: 'Subscription',
  //   primary: true,
  //   path: '/settings/subscription',
  //   component: ShipperSettings
  // },
  // '/settings/privacy': {
  //   protected: true,
  //   exact: true,
  //   label: 'Privacy',
  //   primary: false,
  //   path: '/settings/privacy',
  //   component: ShipperSettings
  // },
  '/settings/security': {
    allowed: userPermission.security_account_settings.read,
    protected: true,
    exact: true,
    label: 'Security',
    primary: false,
    path: '/settings/security',
    component: ShipperSettings
  },
  // '/settings/referrals': {
  //   protected: true,
  //   exact: true,
  //   label: 'Referrals',
  //   primary: false,
  //   path: '/settings/referrals',
  //   component: ShipperSettings
  // },
  '/settings/notifications': {
    allowed: userPermission.notifications_settings.read,
    protected: true,
    exact: true,
    label: 'Notifications',
    primary: false,
    path: '/settings/notifications',
    component: ShipperSettings
  },
  '/settings/preferences': {
    allowed: userPermission.preferences_settings.read,
    protected: true,
    exact: true,
    label: 'Global Preferences',
    primary: false,
    path: '/settings/preferences',
    component: ShipperSettings
  },
  '/settings': {
    allowed: [],
    protected: true,
    exact: true,
    label: 'Settings',
    primary: true,
    path: '/settings',
    component: ShipperSettings
  },
  // '/freight': {
  //   protected: true,
  //   exact: true,
  //   label: 'Freight',
  //   primary: true,
  //   path: '/freight',
  //   component: FreightHome
  // },
  // '/freight/quote': {
  //   protected: true,
  //   exact: true,
  //   label: 'Freight',
  //   primary: true,
  //   path: '/freight/quote',
  //   component: FreightHome
  // },
  //Extra Pages
  // '/profile': {
  //   protected: true,
  //   exact: true,
  //   label: 'Profile',
  //   primary: true,
  //   path: '/profile',
  //   component: ShipperProfile
  // },
  // '/products': {
  //   protected: true,
  //   exact: true,
  //   label: 'Products',
  //   primary: true,
  //   path: '/products',
  //   component: ShipperProducts
  // },
  // '/customers': {
  //   protected: true,
  //   exact: true,
  //   label: 'Customers',
  //   primary: true,
  //   path: '/customers',
  //   component: ShipperCustomers
  // },
  // '/chats': {
  //   protected: true,
  //   exact: true,
  //   label: 'Chats',
  //   primary: true,
  //   path: '/chats',
  //   component: ShipperChat
  // },
  // '/calendar': {
  //   protected: true,
  //   exact: true,
  //   label: 'Calendar',
  //   primary: true,
  //   path: '/calendar',
  //   component: ShipperCalendar
  // }
};

export default RouteMaps;
