import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  Button,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Divider
} from '@material-ui/core';
import AdminTeam from 'service/AdminTeam';
import Style from "./TeamMemberRegistration.module.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { style } from '@mui/system';
import DatePicker from 'components/DatePicker';
import { notify } from 'sites/shipper/components/Toast/toast';
import { useDispatch } from 'react-redux';
import StyledInput from 'components/StyledInput';
import PhoneNumberInput from 'components/PhoneNumberInput';
import CitySelect from 'components/CountrySelect/CitySelect';
import CountrySelectNew from 'components/CountrySelect/CountrySelectNew';
import StateSelect from 'components/CountrySelect/StateSelect';
import LoaderComponent from 'sites/shipper/components/LoaderComponent';
import StyledSelect from 'components/StyledSelect';
import { DOBErrorMsg, isValidDateOfBirth } from 'util/Utils';
import CustomPasswordCheckList from 'components/PasswordCheckList'


const TeamMemberRegistartion = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const { token } = useParams();
  const [DOBError, setDOBError] = useState("")

  const [expiredToken, setExpiredToken] = useState(false)
  const [isValidPassword, setIsValidPassword] = useState(false)

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [data, setData] = useState({
    token: token,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    phoneNumberCode: '',
    password: '',
    dateOfBirth: '',
    jobTitle: "",
    gender: "",
    // personal_suite: '',
    // personal_street: '',
    // personal_city: '',
    // personal_state: '',
    // personal_country: '',
    // personal_postal_code: ''
  });

  const handleValue = (e) => {
    const { name, value } = e.target;
    if (name === "dateOfBirth") {
      setDOBError("")
      if (!isValidDateOfBirth(value)) {
        setDOBError(DOBErrorMsg)
      }
    }

    setData({
      ...data,
      [name]: value
    });
  };

  const [error, setError] = useState("")

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    AdminTeam.isTokenExpired(token).then(result => {
      if (!result.success) {
        setExpiredToken(true)
      } else {
        setExpiredToken(false)
      }
      setLoading(false)
    })
  }, [token])



  const handleSubmit = () => {
    // console.log(data)
    if (
      data.dateOfBirth === "" ||
      data.firstName === "" ||
      data.lastName === "" ||
      data.password === "" ||
      data.jobTitle === "" ||
      data.gender === "" ||
      // data.personal_city === "" ||
      // data.personal_country === "" ||
      // data.personal_postal_code === "" ||
      // data.personal_state === "" ||
      // data.personal_street === "" ||
      // data.personal_suite === "" ||
      data.phoneNumber === "" ||
      data.phoneNumberCode === ""
    ) {
      setError("All Fields are required")
      return
    }
    setLoading(true)
    AdminTeam.registerTeam(data).then(response => {
      if (response.error) {
        setError(response.error)
      } else {
        // window.alert(response.message)
        notify(dispatch, "success", response.message)
        history.push("/login")
      }
      setLoading(false)
    })
  };

  // console.log(data)



  const handleCountry = (e, opt) => {
    if (!opt) {
      return;
    }
    console.log(opt);
    setData({
      ...data,
      personal_country: opt,
      personal_state: null,
      personal_city: null
    });
  };

  const handleState = (e, opt) => {
    if (!opt) {
      return;
    }
    setData({
      ...data,
      personal_state: opt,
      personal_city: null
    });
  };

  const handleCity = (e, opt) => {
    if (!opt) {
      return;
    }
    setData({
      ...data,
      personal_city: opt
    });
  };

  const [passwordStrengthError, setPasswordStrengthError] = useState();
  // const checkPasswordStrength = () => {
  //   if (isValidPassword(data.password)) {
  //     setPasswordStrengthError('');
  //   } else {
  //     setPasswordStrengthError(
  //       'Password must be at least 8 characters long, contain at least one digit, one lowercase and one uppercase letter'
  //     );
  //   }
  //   return;
  // };



  useEffect(() => {
    setError("")
    setPasswordStrengthError("")
  }, [data])



  return (
    <>
      {loading && <LoaderComponent loading={loading} />}
      {expiredToken ? <>
        <div className={`${Style.container}`} style={{ backgroundImage: 'url(assets/authentication/team_register_back.png)' }}>
          <div className='d-flex justify-content-center align-items-center' style={{ flex: "1" }}>
            <div className='d-flex flex-column align-items-center c-gap-1 rounded bg-white p-4' style={{ width: "max-content" }}>
              <span className={Style.form_legend}>Invalid or expired Token</span>
              {/* <a href='/login'>Click Here to login</a></div> */}
              <img src="assets\authentication\invalid_token.svg" alt="Invalid Token" style={{ maxWidth: '320px' }} />
              <div className={Style.form_actions_container}>
                <Button
                  className={`${Style.signin_btn} btn-primary`}
                  href='/login'
                >
                  Login
                </Button>
              </div>
            </div>
          </div>
        </div>
      </> : <>

        <div className={Style.container} style={{ backgroundImage: 'url(assets/authentication/team_register_back.png)' }}>
          <div className={Style.main}>

            <div className='d-flex justify-content-center'>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
                <div className={Style.left_header}>Team Member <br /> Register Form</div>

                <div className={Style.trust_text}>
                  Experience why over 2 million customers choose
                  Cuboid POS
                </div>
                <div className='d-flex flex-column'>
                  <span className={Style.trust_text}>Do you prefer talking to a representative?</span>
                  <span className={Style.call_text}>Call us at 800 1301 448</span>
                </div>
              </div>
            </div>

            <div>
              <div className={Style.form_container}>

                <div className={Style.form}>

                  <span className={Style.form_legend}>Team Member Register Form</span>

                  <div className={Style.grid_container}>
                    <StyledInput margin="0" label='First Name*' id='first-name' name='firstName' value={data.firstName} onChange={handleValue} />
                    <StyledInput margin="0" label='Last Name*' id='last-name' name='lastName' value={data.lastName} onChange={handleValue} />

                    <PhoneNumberInput
                      country={data.phoneNumberCode}
                      number={data.phoneNumber}
                      handleCountry={(val) => setData({ ...data, phoneNumberCode: val })}
                      handleNumber={(val) => setData({ ...data, phoneNumber: val })}
                    />


                    <StyledInput
                      margin="0"
                      label="Job Title"
                      id="job-title"
                      name="jobTitle"
                      value={data.jobTitle}
                      onChange={handleValue}
                    />

                    <StyledInput
                      margin="0"
                      type='date'
                      label='Date of Birth*'
                      id='d-o-b'
                      name='dateOfBirth'
                      value={data.dateOfBirth || ''}
                      onChange={handleValue}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        placeholder: data.dateOfBirth ? undefined : 'Date of Birth',
                      }}
                      error={DOBError}
                    />

                    <StyledSelect
                      label="Select Gender*"
                      name="gender"
                      margin="0"
                      value={data.gender}
                      onChange={(e) => setData({ ...data, gender: e.target.value })}
                      size="small"
                      options={[
                        { value: 'Male', label: 'Male' },
                        { value: 'Female', label: 'Female' },
                        { value: 'Other', label: 'Other' }
                      ]}
                    />

                  </div>

                  {/* <span className={Style.sub_legend}>Personal Address</span> */}

                  {/* <div className={Style.grid_container}>
                    <StyledInput margin="0" label='Suite' id='personal-suite' name="personal_suite" value={data.personal_suite} onChange={handleValue} />
                    <StyledInput margin="0" id='personal-street' label='Street*' name="personal_street" value={data.personal_street} onChange={handleValue} />
                    <CountrySelectNew
                      name="country"
                      value={data.personal_country}
                      useDefault
                      showError={false}
                      isClearable={false}
                      isMulti={false}
                      onChange={handleCountry}
                    />
                    <StateSelect
                      name="state"
                      country={data.personal_country._id}
                      value={data.personal_state}
                      showError={false}
                      handleChange={handleState}
                    />
                    <CitySelect
                      name="personal_city"
                      value={data.personal_city}
                      state={data?.personal_state?._id}
                      showError={false}
                      handleChange={handleCity}
                    />
                   
                    <StyledInput margin="0" label='Postal Code' id='personal_postal_code' name="personal_postal_code" value={data.personal_postal_code} onChange={handleValue} />
                  </div>*/}

                  <FormControl variant="outlined" size="small">
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                      name="password"
                      value={data.password}
                      onChange={handleValue}
                      autoComplete="password"
                      // onBlur={checkPasswordStrength}
                      className={`${Style.input}`}
                      id="outlined-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>

                  {/* {passwordStrengthError && <span className='text-danger font-size-xs'>{passwordStrengthError}</span>} */}
                  {data.password &&
                    <div className='mt-1'>
                      <CustomPasswordCheckList
                        password={data.password}
                        setIsValidPassword={setIsValidPassword}
                      />
                    </div>}
                  {error && <span className='text-danger font-size-xs'>{error}</span>}
                  <Button
                    className={`btn-primary`}
                    onClick={handleSubmit}
                    disabled={
                      data.dateOfBirth === "" ||
                      data.firstName === "" ||
                      data.lastName === "" ||
                      data.password === "" ||
                      data.jobTitle === "" ||
                      data.gender === "" ||
                      data.phoneNumber === "" ||
                      data.phoneNumberCode === "" ||
                      // passwordStrengthError ||
                      !isValidPassword ||
                      DOBError
                    }
                  >
                    Register
                  </Button>
                </div>

                <Divider />

                <div className={Style.caption}>
                  By registering, you agree to the processing of your personal data by Salesforce as described in the Privacy Statement.
                </div>
              </div>
            </div>
          </div>
        </div>
      </>}
    </>
  );
};

export default TeamMemberRegistartion;
