import React, { useEffect, useState } from 'react';
import {
  Grid,
  Container,
  LinearProgress,
  Card,
  Button,
  List,
  ListItem
} from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import Alert from '@material-ui/lab/Alert';

import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';
import PublishTwoToneIcon from '@material-ui/icons/PublishTwoTone';
import CheckIcon from '@material-ui/icons/Check';
import Style from './ImageUploader.module.css';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import classNames from 'classnames';
import { MAX_FILE_SIZE } from 'util/awsUploader';

const ImageUploader = ({ data, setData, name, label, imageClassName = "" }) => {
  const [error, setError] = useState("")
  const showPreview = (file) => {
    if (typeof file === 'string') {
      return file;
    } else {
      return URL.createObjectURL(file);
    }
  };
  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps
  } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      if (data[name]) {
        return;
      }
      setError("")

      if (acceptedFiles[0].size > MAX_FILE_SIZE) {
        setError("Please select file less than 2MB")
        return
      }

      setData((previousData) => ({
        ...previousData,
        [name]: acceptedFiles[0]
      }));
    }
  });



  const handleDelete = (toDeleteFile) => {
    setData((previousData) => ({
      ...previousData,
      [name]: ''
    }));
  };


  const imageClass = classNames(imageClassName)


  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'start',
          gap: '1rem',
          flexWrap: 'wrap'
        }}>
        {/* {thumbs.length > 0 && <>{thumbs}</>} */}

        {data[name] && (
          <div className={Style.preview_container}>
            <img
              src={showPreview(data[name])}
              className={imageClass}
              style={{ borderRadius: '10px' }}
              alt=""
            />
            <IconButton
              onClick={() => handleDelete(data[name])}
              className={Style.file_delte_icon}
              size="small">
              <DeleteIcon style={{ color: '#fff', fontSize: '15px' }} />
            </IconButton>
          </div>
        )}

        {!data[name] && (
          <div className="dropzone">
            <div
              {...getRootProps({
                className: 'dropzone-upload-wrapper'
              })}>
              <input {...getInputProps()} />
              <div
                className={`${imageClass} dropzone-inner-wrapper p-4`}
                style={{ background: 'white' }}>
                {isDragAccept && (
                  <div>
                    <div className="d-55 hover-scale-lg icon-blob icon-blob-animated btn-icon text-success mx-auto">
                      <svg
                        className="d-55 opacity-2"
                        viewBox="0 0 600 600"
                        xmlns="http://www.w3.org/2000/svg">
                        <g transform="translate(300,300)">
                          <path
                            d="M170.4,-137.2C213.2,-82.3,234.8,-11.9,223.6,56.7C212.4,125.2,168.5,191.9,104.3,226.6C40.2,261.3,-44.1,264,-104,229.8C-163.9,195.7,-199.4,124.6,-216.2,49.8C-233,-25.1,-231,-103.9,-191.9,-158C-152.7,-212.1,-76.4,-241.6,-6.3,-236.6C63.8,-231.6,127.7,-192.2,170.4,-137.2Z"
                            fill="currentColor"
                          />
                        </g>
                      </svg>
                      <div className="blob-icon-wrapper">
                        <CheckIcon className="d-20" />
                      </div>
                    </div>
                  </div>
                )}
                {isDragReject && (
                  <div>
                    <div className="d-55 hover-scale-lg icon-blob icon-blob-animated btn-icon text-danger mx-auto">
                      <svg
                        className="d-55 opacity-2"
                        viewBox="0 0 600 600"
                        xmlns="http://www.w3.org/2000/svg">
                        <g transform="translate(300,300)">
                          <path
                            d="M169,-144C206.7,-87.5,216.5,-18,196.9,35.7C177.3,89.4,128.3,127.1,75.2,150.7C22,174.2,-35.4,183.5,-79.7,163.1C-124,142.7,-155.1,92.6,-164.1,40.9C-173.1,-10.7,-160.1,-64,-129,-118.9C-98,-173.8,-49,-230.4,8.3,-237.1C65.7,-243.7,131.3,-200.4,169,-144Z"
                            fill="currentColor"
                          />
                        </g>
                      </svg>
                      <div className="blob-icon-wrapper">
                        <CloseTwoToneIcon className="d-20" />
                      </div>
                    </div>
                  </div>
                )}
                {!isDragActive && !data[name] && (
                  <div className={Style.drag_zone_text_container}>
                    {/* <span className={Style.upload_text}>Upload</span> */}
                    <span
                      style={{
                        color: 'var(--primary)',
                        textAlign: 'center',
                        fontWeight: '500'
                      }}>
                      {label}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}


      </div>

      {error &&
        <span className='text-danger mt-1'>{error}</span>
      }
    </>
  );
};

export default ImageUploader;
