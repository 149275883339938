import AccountCmpyLinkType from "model/enum/AccountCmpyLinkType";


export const AccountCompanyShortName = Object.freeze({
  ROOT: "ROT",
  SUPERADMIN: "SAD",
  ADMIN: "ADM",
  POWER: "POW",
  MEMBER: "MEM",
});



export const userRoleArray = Object.keys(AccountCmpyLinkType)
  .filter(
    (key) =>
      AccountCmpyLinkType[key] !== AccountCmpyLinkType.ROOT
  )
  .map((key) => {
    return {
      label: AccountCmpyLinkType[key],
      value: AccountCompanyShortName[key]
    };
  });


export const userAccessControlMatrix = {
  [AccountCmpyLinkType.ROOT]: [AccountCmpyLinkType.ROOT, AccountCmpyLinkType.SUPERADMIN, AccountCmpyLinkType.ADMIN, AccountCmpyLinkType.POWER, AccountCmpyLinkType.MEMBER],
  [AccountCmpyLinkType.SUPERADMIN]: [AccountCmpyLinkType.SUPERADMIN, AccountCmpyLinkType.ADMIN, AccountCmpyLinkType.POWER, AccountCmpyLinkType.MEMBER],
  [AccountCmpyLinkType.ADMIN]: [AccountCmpyLinkType.ADMIN, AccountCmpyLinkType.POWER, AccountCmpyLinkType.MEMBER],
  [AccountCmpyLinkType.POWER]: [AccountCmpyLinkType.POWER, AccountCmpyLinkType.MEMBER],
  [AccountCmpyLinkType.MEMBER]: [AccountCmpyLinkType.MEMBER]
}




export const allowedUerRoleArray = (role) => {
  const allowedRoles = userAccessControlMatrix[role];
  const array = userRoleArray.filter(item => allowedRoles.includes(item.label))
  return array
}

export const userPermission = {
  dashboard: {
    read: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    earnings_overview_card: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN
    ],
    shipper_growth: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ],
    shipment_overview: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER
    ],
    top_countries: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER
    ],
    shipper: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    forwarder: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    transaction: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ]
  },
  shipper: {
    read: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    edit: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ]
  },
  shipper_managment: {
    read: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ],
    individual: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ],
    company: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ],
    shipments: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ],
    transactions: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ],
    team: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ]
  },
  forwarder: {
    read: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    edit: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ]
  },
  forwarder_managment: {
    read: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ],
    individual: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ],
    company: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ],
    shipments: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ],
    transactions: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ],
    ratings: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ],
    team: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ]
  },
  shipments: {
    edit: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    read: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    delete: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ]
  },
  shipment_details: {
    read: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    overview: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    payment: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    attachment: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    activity: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    notes: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    add_on: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    delivery: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    resource_center: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    tracking: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ]
  },
  articles: {
    read: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ]
  },
  articles_blog: {
    read: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ],
    create: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ],
    edit: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ],
    delete: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ],
  },
  articles_category: {
    read: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ],
    create: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ],
    edit: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ],
    delete: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ],
  },
  user_metrics: {
    read: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ],
    edit: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ],
    quoteSearchHistory: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ]
  },
  pending_users: {
    read: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN
    ],
    download: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN
    ],
    sendRegistrationLink: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN
    ],
    delete: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN
    ]
  },
  subscribers: {
    read: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    download: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER
    ],
    create: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    delete: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER
    ],
    edit: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER
    ],
  },
  career: {
    read: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ],
    edit: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ],
    create: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ],
    delete: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ],
  },
  document: {
    read: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ],
    create: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ],
    edit: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ],
    delete: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ]
  },
  team: {
    read: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ],
    edit: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ],
    create: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ],
    delete: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN
    ],
  },
  billing: {
    read: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
  },
  billing_payments: {
    read: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
  },
  billing_payout: {
    read: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    release_payout: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN
    ]
  },
  billing_settings: {
    read: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ]
  },
  billing_settings_payout: {
    read: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    history: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER
    ],
    create: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN
    ]
  },
  billing_settings_platform_fee_and_taxes: {
    read: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    history: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER
    ],
    create: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN
    ]
  },
  billing_settings_refferal: {
    read: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    history: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER
    ],
    create: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN
    ]
  },
  company_settings: {
    edit: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN
    ],
    read: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN
    ]
  },
  security_account_settings: {
    edit: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    read: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    delete: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN
    ],
    create: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ]
  },
  security_privacy_settings: {
    edit: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    read: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    delete: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    create: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ]
  },
  security_cookies_settings: {
    edit: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    read: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    delete: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    create: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ]
  },
  notifications_settings: {
    edit: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    read: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    delete: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    create: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ]
  },
  preferences_settings: {
    edit: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    read: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    delete: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    create: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ]
  },
  shipment_request: {
    edit: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    read: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    delete: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    create: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ]
  },
  carrier: {
    edit: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    read: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    delete: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    create: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ]
  },
  rate_sheet: {
    edit: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER
    ],
    read: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER
    ],
    delete: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER
    ],
    create: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER
    ]
  },
  service_management: {
    edit: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN
    ],
    read: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN
    ],
    delete: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN
    ],
    create: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN
    ]
  },
  source_management: {
    edit: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN
    ],
    read: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN
    ],
    delete: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN
    ],
    create: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN
    ]
  },
  tag_management: {
    edit: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN
    ],
    read: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN
    ],
    delete: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN
    ],
    create: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN
    ]
  },
  ticket_system: {
    edit: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    read: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    delete: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ],
    create: [
      AccountCmpyLinkType.ROOT,
      AccountCmpyLinkType.SUPERADMIN,
      AccountCmpyLinkType.ADMIN,
      AccountCmpyLinkType.POWER,
      AccountCmpyLinkType.MEMBER
    ]
  },
}



