import ModalSimple from 'components/ModalSimple'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Style from "./ViewAllPrices.module.css"
import RateSheetService from 'service/RateSheetService'
import { Button, Divider, MenuItem, Tooltip } from "@material-ui/core";
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import CachedIcon from '@mui/icons-material/Cached';
import SmallPopupWrapper from "components/Common/SmallPopupWrapper/SmallPopupWrapper";
import TableWithSearchBar from 'components/Tables/TableWithSearchBar'
import LoaderComponent from 'sites/shipper/components/LoaderComponent'
import Preview from 'sites/shipper/pages/Chat/ChatViewPane/OfferChat/Preview/Preview'
import ModalPopupWrapper from 'components/Common/ModalPopup/ModalPopup'
import ConfirmDialog from '../../rate_sheet/ConfirmDialog/ConfirmDialog'
import { notify } from 'sites/shipper/components/Toast/toast'
import ViewBreakdown from '../../rate_sheet/ViewBreakdown/ViewBreakdown'
import moment from 'moment'
import { transitTimeCalculator } from 'util/RateSheet/rateSheet'
import { CARGO_CATEGORIES, fetchContainerTypes, TRANSPORT_MODES } from 'util/constants'
const ViewAllPrices = ({
    open,
    handleClose,
    data,
    heading,
    run,
    setRun,
    loadType
}) => {


    const dispatch = useDispatch()
    const auth = useSelector((state) => state.session.auth)
    const routeInfo = useSelector((state) => state.rateSheet.route_info)
    const [isOpenEdit, setIsOpenEdit] = useState(false);

    const toggleBreakdownEdit = (bool) => {
        setIsOpenEdit(bool)
    }


    // useEffect(() => {
    //     if (!routeInfo._id) {
    //         history.replace("/rate-sheet")
    //     }
    // }, [routeInfo])

    const [rateSheets, setRateSheets] = useState([])
    // const [run, setRun] = useState(true)
    const [loading, setLoading] = useState(false)
    const [searchKeyWord, setSearchKeyWord] = useState("")

    useEffect(() => {
        setLoading(true)
        const data_to_send = {
            admin_id: auth.accountId,
            load_type: data?.load_type,
            route_id: routeInfo._id,
            carrier_id: data?.carrier_id?._id,
            container_type: data?.container_type,
            searchKeyWord: searchKeyWord
        }
        RateSheetService.fetchRateSheetByShippingLine(data_to_send, auth.token).then(res => {
            if (res.error) {
                console.log(res.error)
            } else {
                setRateSheets(res)
            }
            setLoading(false)
        })
    }, [run, searchKeyWord])

    // useEffect(() => {
    //     setRefresh(refresh)
    // }, [run])

    const [openMoreOptions, setOpenMoreOptions] = useState(false);
    const [moreOptionClicked, setMoreOptionClicked] = useState(null)
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [previewRateSheet, setPreviewRateSheet] = useState([])


    const togglePreview = (data) => {
        if (!isPreviewOpen) {
            setPreviewRateSheet(data)
        }
        setIsPreviewOpen((state) => !state);
    };



    const handleDeletePrice = () => {
        setLoading(true)
        const data_to_send = {
            admin_id: auth.accountId,
            price_id: moreOptionClicked._id,
            transportMode: routeInfo?.transportMode,
            origin: routeInfo?.origin,
            destination: routeInfo?.destination
        }
        RateSheetService.deleteRateSheetPriceInfo(data_to_send, auth.token).then(res => {
            if (res.error) {
                notify(dispatch, "error", res.error)
            } else {
                notify(dispatch, "success", res.message)
                setRun(!run)
                // setRefresh(!refresh)
            }
            setLoading(false)
        })
    }


    const TableHeading = [
        "S No.",
        "Vessel",
        "Price",
        "Effective Date",
        "Expiry Date",
        "Status",
        "Port to Port Transit Time",
        'Action'
    ]

    const Keys = [
        "index",
        "carrier_vessels",
        "price",
        "effective_date",
        "expiry_date",
        "status",
        "transit_time",
        "_id"
    ]


    const TableData = rateSheets?.map((item, index) => Keys.map(Key => {
        if (Key === "index") {
            return index + 1
        }
        if (Key === "carrier_vessels") {
            return item?.carrier_vessels?.name
        }
        if (Key === '_id') {
            return <>
                <div className="position-relative">
                    <center>
                        <Tooltip title="More" arrow>
                            <Button
                                variant="text"
                                className="btn-outline-primary d-flex align-items-center justify-content-center d-30 mr-2 p-0 rounded-pill shadow-none"
                                onClick={(e) => {
                                    setOpenMoreOptions(e.currentTarget);
                                    setMoreOptionClicked(item)
                                }}>
                                <MoreVertTwoToneIcon style={{ fontSize: '18px' }} />
                            </Button>
                        </Tooltip>
                    </center>
                    {moreOptionClicked?._id === item?._id &&
                        <SmallPopupWrapper open={openMoreOptions} onClose={() => setOpenMoreOptions(null)}>
                            <MenuItem onClick={() => { setOpenMoreOptions(null); togglePreview(item.load.priceBreakdown) }}>View</MenuItem>
                            <MenuItem onClick={() => { setOpenMoreOptions(null); toggleBreakdownEdit(true) }}>Edit</MenuItem>
                            <MenuItem onClick={() => setConfirmDialogOpen(true)} >Remove</MenuItem>
                        </SmallPopupWrapper>
                    }
                </div>

            </>
        }
        if (Key === "price") {
            if (routeInfo?.transportMode === TRANSPORT_MODES.AIR.value) {
                if (item?.price === item?.maxPrice) {
                    return <>{`$${item?.price?.toLocaleString()}`}</>
                } else {
                    return <>{`$${item?.price?.toLocaleString()} - $${item?.maxPrice?.toLocaleString()}`}</>
                }
            } else {
                return <>{`$${item?.price?.toLocaleString()}`}</>
            }
        }
        if (Key === "effective_date" || Key === "expiry_date") {
            return item[Key] !== "NA" ? moment(item[Key]).format('LL') : "NA"
        }
        if (Key === "status") {
            let style = "m-0 px-3 py-0 h-auto text-success border-1 border-success rounded badge badge-neutral-success"
            if (item.status === "Upcoming") {
                style = "m-0 px-3 py-0 h-auto text-warning border-1 border-warning rounded badge badge-neutral-warning"
            }
            return (<p className={style}>{item.status}</p>)
        }
        if (Key === "transit_time") {
            return transitTimeCalculator(item.transit_time.port_to_port)
        }
        else {
            return item[Key] || "";
        }
    }))

    const renderContainerName = () => {
        let containerType = data?.container_type
        if (loadType === CARGO_CATEGORIES.RORO.value) {
            const allcontainerTypes = fetchContainerTypes(TRANSPORT_MODES.SEA, CARGO_CATEGORIES.RORO)
            containerType = allcontainerTypes.filter(itm => itm.value === data?.container_type)[0]?.label
        }
        return containerType
    }

    return (
        <>
            {/* {loading && <LoaderComponent loading={loading} />} */}
            <ModalSimple
                modalTitle={heading}
                open={(open && !isOpenEdit) ? true : false}
                maxWidth={"xxl"}
                fullWidth={true}
                handleClose={handleClose}
                actionButtonText={"Confirm"}
            >
                <div className={Style.mainContainer}>








                    <div className={Style.routeDetailContainer}>
                        <div>
                            <span className="font-size-md font-weight-bold">Route Details</span>
                        </div>
                        <div className={Style.routeInnerContainer}>
                            <div className={Style.routeflexContainer}>
                                <div className={Style.labelContainer}><p className={Style.labelHeading}>Origin State :</p> <p className={Style.labelDescription}>{routeInfo?.origin?.state?.name}</p></div>
                                <div className={Style.labelContainer}><p className={Style.labelHeading}>Origin Port Name :</p><p className={Style.labelDescription}> {routeInfo?.origin?.port?.code}</p></div>
                                <div className={Style.labelContainer}><p className={Style.labelHeading}>Origin City : </p><p className={Style.labelDescription}>{routeInfo?.origin?.city?.name}</p></div>
                                <div className={Style.labelContainer}><p className={Style.labelHeading}>Origin Country Code :</p> <p className={Style.labelDescription}>{routeInfo?.origin?.country?.name}</p></div>
                            </div>
                            <div className={Style.routeflexContainer}>
                                <div className={Style.labelContainer}><p className={Style.labelHeading}>Destination State :</p> <p className={Style.labelDescription}>{routeInfo?.destination?.state?.name}</p></div>
                                <div className={Style.labelContainer}><p className={Style.labelHeading}>Destination Port Name :</p><p className={Style.labelDescription}> {routeInfo?.destination?.port?.code}</p></div>
                                <div className={Style.labelContainer}><p className={Style.labelHeading}>Destination City : </p><p className={Style.labelDescription}>{routeInfo?.destination?.city?.name}</p></div>
                                <div className={Style.labelContainer}><p className={Style.labelHeading}>Destination Country Code :</p> <p className={Style.labelDescription}>{routeInfo?.destination?.country?.name}</p></div>
                            </div>
                        </div>
                        <div className={Style.routeInnerContainer}>
                            <div className={Style.routeflexContainer}>
                                <div className={Style.labelContainer}><p className={Style.labelHeading}>Shipping Line :</p><p className={Style.labelDescription}> {data?.carrier_id?.fullName}</p></div>
                                <div className={Style.labelContainer}><p className={Style.labelHeading}>Transport Mode :</p><p className={Style.labelDescription}> {routeInfo?.transportMode}</p></div>
                            </div>
                            <div className={Style.routeflexContainer}>
                                <div className={Style.labelContainer}><p className={Style.labelHeading}>Load Type:</p><p className={Style.labelDescription}> {data?.load_type}</p></div>
                                <div className={Style.labelContainer}><p className={Style.labelHeading}>Container Name :</p><p className={Style.labelDescription}> {renderContainerName()}</p></div>
                            </div>
                        </div>
                    </div>




                    <TableWithSearchBar
                        animatedSearchbar={{
                            show: true,
                            value: searchKeyWord,
                            onChange: (value) => setSearchKeyWord(value),
                            setLoading: setLoading
                        }}
                        loading={loading}
                        TableHeading={TableHeading}
                        TableBody={TableData}
                        TableGrid={"1fr 1.5fr 1fr  1fr 1fr 1fr 1.5fr 1fr"}
                        actionButtons={[
                            <Button
                                variant="text"
                                onClick={() => setRun(!run)}
                                className={`btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill`}
                            >
                                <CachedIcon className="w-50" />
                            </Button>,
                        ]}
                    />















                </div>
            </ModalSimple>


            {/* {isOpenEdit && */}
                <ViewBreakdown
                    data={routeInfo}
                    run={run}
                    editData={moreOptionClicked}
                    setRun={setRun}
                    data_id={routeInfo?._id}
                    open={isOpenEdit}
                    method={"edit"}
                    handleClose={() => {toggleBreakdownEdit(false);setMoreOptionClicked(null)}}
                />
            {/* } */}



            <ModalPopupWrapper
                maxWidth={'lg'}
                noStyle
                open={(isPreviewOpen && !isOpenEdit) ? true : false}
            >
                {previewRateSheet.length > 0 &&
                    <Preview
                        handleEdit={() => toggleBreakdownEdit(true)}
                        data={previewRateSheet}
                        open={isPreviewOpen}
                        loadType={loadType}
                        togglePreview={togglePreview}
                        transportMode={routeInfo?.transportMode}
                    />
                }
            </ModalPopupWrapper>




            <ConfirmDialog
                confirmDialogOpen={confirmDialogOpen}
                setConfirmDialogOpen={setConfirmDialogOpen}
                confirmDialogFunction={handleDeletePrice}
                question={"Are you sure you want to delete this price?"}
            />
        </>
    )
}

export default ViewAllPrices