import { IconButton } from '@material-ui/core'
import BreakdownTable from 'components/breakdown_table/BreakdownTable'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import Style from "./Preview.module.css";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Edit } from '@material-ui/icons';

const Preview = ({ handleEdit, open, data, togglePreview, canEdit = true, transportMode,loadType }) => {
    return (
        <>
            {open &&
                <div className={Style.container}>

                    <div style={{ display: 'flex', height: '100%' }}>

                        {/* for sidebar  */}
                        {/* <div style={{ width: '73px' }}></div> */}

                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                            {/* for header  */}
                            {/* <div style={{ height: '74px' }}></div> */}

                            <div className={Style.inner_container}>
                                <div className={Style.main}>
                                    <PerfectScrollbar
                                        options={{ suppressScrollX: true, wheelPropagation: false }}
                                    >
                                        <div>
                                            {data.map((item, index) => (
                                                <BreakdownTable data={item} key={index} transportMode={transportMode} loadType={loadType} />
                                            ))}

                                        </div>

                                    </PerfectScrollbar>

                                    {/* close previe btn  */}
                                    <IconButton
                                        className={Style.close_btn}
                                        onClick={() => togglePreview()}
                                    >
                                        <CloseIcon style={{ color: '#000', fontSize: '20px' }} />
                                    </IconButton>
                                    {/* edit offer btn  */}
                                    {canEdit &&
                                        <IconButton
                                            className={Style.edit_btn}
                                            onClick={handleEdit}
                                        >
                                            <Edit style={{ color: '#000', fontSize: '20px' }} />
                                        </IconButton>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Preview