import React, { useState, useEffect } from 'react';
import ConstantValuesService from 'service/ConstantValuesService';
import { notify } from 'sites/shipper/components/Toast/toast';
import { useDispatch, useSelector } from 'react-redux';
import Style from './CreateCarriers.module.css';
import ConfirmDialog from '../../shipment_rates/rate_sheet/ConfirmDialog/ConfirmDialog';
import { upload_to_s3 } from 'util/awsUploader';
import ModalSimple from 'components/ModalSimple';
import StyledInput from 'components/StyledInput';
import StyledSelect from 'components/StyledSelect';
import ImageUploader from '../../Blogs/ImageUploader/ImageUploader';
import LoaderComponent from 'sites/shipper/components/LoaderComponent';
import { TRANSPORT_MODES } from 'util/constants';

const CreateCarriers = ({
  open,
  run,
  setRun,
  onClose,
  edit = false,
  editData,
  handleEdit,
  transportMode = ""
}) => {
  const auth = useSelector((state) => state.session.auth);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [data, setData] = useState({
    short_name: '',
    full_name: '',
    logo: '',
    logoName: '',
    transport_mode: transportMode,
    carrier_code: '',
    scac: '',
    iataPrefixCode: "",
    iataCode: "",
    icaoCode: "",
    trackingUrl: "",
    carrierUrl: "",
  });

  const [error, setError] = useState();

  useEffect(() => {
    if (edit) {
      setData({
        ...data,
        short_name: editData.shortName,
        transport_mode: editData.transportMode,
        full_name: editData.fullName,
        logo: editData.logo.url,
        logoName: editData.logo.name,
        carrier_code: editData.carrierCode,
        scac: editData.scac,
        iataPrefixCode: editData.iataPrefixCode,
        iataCode: editData.iataCode,
        icaoCode: editData.icaoCode,
        trackingUrl: editData.trackingUrl,
        carrierUrl: editData.carrierUrl,
      });
    } else {
      setData({
        ...data,
        short_name: '',
        full_name: '',
        transport_mode: transportMode,
        logo: '',
        carrier_code: '',
        scac: '',
        iataPrefixCode: "",
        iataCode: "",
        icaoCode: "",
        trackingUrl: "",
        carrierUrl: "",
      });
    }
  }, [edit, open]);

  const handleSubmit = async () => {
    if (
      !data.short_name ||
      !data.full_name ||
      !data.logo ||
      !data.transport_mode ||
      (data.transport_mode === TRANSPORT_MODES.SEA.value && !data.carrier_code) ||
      (data.transport_mode === TRANSPORT_MODES.SEA.value && !data.scac) ||
      (data.transport_mode === TRANSPORT_MODES.AIR.value && !data.iataPrefixCode) ||
      (data.transport_mode === TRANSPORT_MODES.AIR.value && !data.iataCode) ||
      (data.transport_mode === TRANSPORT_MODES.AIR.value && !data.icaoCode)
    ) {
      setError('All fields are requires');
      return;
    }

    setLoading(true);
    const logoUrl =
      typeof data.logo === 'string'
        ? data.logo
        : await upload_to_s3(data.logo, 'shipping-line-logo', auth.token);

    const logoName = typeof data.logo === 'string' ? data.logoName : data.logo.name;
    const data_to_send = {
      short_name: data.short_name,
      full_name: data.full_name,
      logoUrl: logoUrl,
      logoName: logoName,
      transport_mode: data.transport_mode,
      carrier_code: data.carrier_code,
      scac: data.scac,
      iataPrefixCode: data.iataPrefixCode,
      iataCode: data.iataCode,
      icaoCode: data.icaoCode,
      accountId: auth.accountId,
      trackingUrl: data.trackingUrl,
      carrierUrl: data.carrierUrl,
    };
    if (edit) {
      ConstantValuesService.editCarrier(
        editData._id,
        data_to_send,
        auth.token
      ).then((res) => {
        if (res.error) {
          setError(res.error);
        } else {
          notify(dispatch, 'success', res.message);
          setRun(!run);
          onClose();
        }
        setLoading(false);
      });
    } else {
      ConstantValuesService.createCarrier(data_to_send, auth.token).then(
        (res) => {
          if (res.error) {
            setError(res.error);
          } else {
            notify(dispatch, 'success', res.message);
            setRun(!run);
            onClose();
          }
          setLoading(false);
        }
      );
    }
  };

  const transportModeList = [
    {
      value: 'AIR',
      label: 'AIR'
    },
    {
      value: 'SEA',
      label: 'SEA'
    }
  ];
  const handleTranportMode = (e) => {
    setData({
      ...data,
      transport_mode: e.target.value
    });
  };

  const [confirmSubmit, setConfirmSubmit] = useState(false);

  return (
    <>
      {loading && <LoaderComponent loading={loading} />}
      <ModalSimple
        modalTitle={'Add Carrier'}
        open={open}
        action={() => setConfirmSubmit(true)}
        modalDescription={''}
        maxWidth={'xs'}
        fullWidth={true}
        handleClose={() => {
          onClose();
          handleEdit(null);
        }}
        actionButtonText={edit ? 'Save' : 'Create'}>
        <div className={Style.container}>
          <StyledInput
            margin="0"
            label="Enter Short Name"
            value={data.short_name}
            onChange={(e) => setData({ ...data, short_name: e.target.value })}
          />
          <StyledInput
            margin="0"
            label="Enter Full Name"
            value={data.full_name}
            onChange={(e) => setData({ ...data, full_name: e.target.value })}
          />
          {data.transport_mode === TRANSPORT_MODES.SEA.value &&
            <StyledInput
              margin="0"
              label="Enter Carrier Code"
              value={data.carrier_code}
              onChange={(e) => setData({ ...data, carrier_code: e.target.value })}
            />}
          {data.transport_mode === TRANSPORT_MODES.SEA.value &&
            <StyledInput
              margin="0"
              label="Enter SCAC"
              value={data.scac}
              onChange={(e) => setData({ ...data, scac: e.target.value })}
            />}

          {data.transport_mode === TRANSPORT_MODES.AIR.value &&
            <StyledInput
              margin="0"
              label="Enter IATA Prefix Code"
              value={data.iataPrefixCode}
              onChange={(e) => setData({ ...data, iataPrefixCode: e.target.value })}
            />}

          {data.transport_mode === TRANSPORT_MODES.AIR.value &&
            <StyledInput
              margin="0"
              label="Enter IATA Code"
              value={data.iataCode}
              onChange={(e) => setData({ ...data, iataCode: e.target.value })}
            />}

          {data.transport_mode === TRANSPORT_MODES.AIR.value &&
            <StyledInput
              margin="0"
              label="Enter ICAO"
              value={data.icaoCode}
              onChange={(e) => setData({ ...data, icaoCode: e.target.value })}
            />}

          {data.transport_mode === TRANSPORT_MODES.AIR.value &&
            <StyledInput
              margin="0"
              label="Enter Carrier Url"
              value={data.carrierUrl}
              onChange={(e) => setData({ ...data, carrierUrl: e.target.value })}
            />}

          {data.transport_mode === TRANSPORT_MODES.AIR.value &&
            <StyledInput
              margin="0"
              label="Enter Tracking Url"
              value={data.trackingUrl}
              onChange={(e) => setData({ ...data, trackingUrl: e.target.value })}
            />}
          {/* <StyledSelect
            label="Select Transport Mode"
            value={data.transport_mode}
            onChange={handleTranportMode}
            size="small"
            margin="0"
            options={transportModeList}
          /> */}
          <ImageUploader
            data={data}
            setData={setData}
            name="logo"
            label="upload image"
            imageClassName={Style.imageStyle}
          />
          {error && (
            <>
              <span className='text-danger'>{error}</span>
            </>
          )}
        </div>
      </ModalSimple>

      <ConfirmDialog
        confirmDialogFunction={handleSubmit}
        confirmDialogOpen={confirmSubmit}
        setConfirmDialogOpen={setConfirmSubmit}
        question={
          edit
            ? `Are you sure to edit this carrier`
            : `Are you sure to save this carrier`
        }
      />
    </>
  );
};

export default CreateCarriers;
