import ModalSimple from 'components/ModalSimple';
import StyledInput from 'components/StyledInput';
import React, { useEffect, useState } from 'react';
import ImageUploader from '../../ImageUploader/ImageUploader';
import { useDispatch, useSelector } from 'react-redux';
import { BlogService } from 'service';
import { upload_to_s3 } from 'util/awsUploader';
import { notify } from 'sites/shipper/components/Toast/toast';
import LoaderComponent from 'sites/shipper/components/LoaderComponent';
import Style from "./AddCategory.module.css"
import ConfirmDialog from 'sites/shipper/pages/shipment_rates/rate_sheet/ConfirmDialog/ConfirmDialog';
const AddCategory = ({
  open,
  setOpen,
  // handleClose,
  setRun,
  run,
  categoryData,
  setCategoryData
}) => {
  const [details, setDetails] = useState({
    _id: "",
    name: '',
    image: '',
    imageName: '',
    description: '',
    slug: ''
  });

  const [error, setError] = useState('');
  const [slugerror, setSlugError] = useState('');

  const dispatch = useDispatch();

  const auth = useSelector((state) => state.session.auth);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setError('');
    setSlugError('');
  };

  const resetDetails = () => {
    setDetails({
      ...details,
      name: '',
      image: '',
      imageName: '',
      description: '',
      slug: ''
    });
  }

  useEffect(() => {
    if (categoryData) {
      setDetails({
        ...details,
        _id: categoryData._id,
        description: categoryData.description,
        name: categoryData.name,
        slug: categoryData.slug,
        image: categoryData.image.url,
        imageName: categoryData.image.name,
      });
    } else {
      resetDetails()
    }
  }, [categoryData]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'slug') {
      setSlugError('');
      let newSlug = value.toLowerCase(); // Convert input to lowercase
      newSlug = newSlug.replace(/\s+/g, '-'); // Replace spaces with hyphens
      newSlug = newSlug.replace(/[^\w\s-]/g, '-'); // Replace special characters with hyphens

      setDetails((prev) => {
        return {
          ...prev,
          [name]: newSlug
        };
      });
    } else {
      setDetails((prev) => {
        return {
          ...prev,
          [name]: value
        };
      });
    }

  };

  const addCategory = async () => {
    try {
      setLoading(true);
      const data_to_send = {
        ...details,
        id: auth.accountId,
        image: {
          name: details.image.name,
          url: await upload_to_s3(details.image, `blog-category`, auth.token)
        }
      };

      BlogService.createCategory(data_to_send, auth.token).then((res) => {
        if (res.error) {
          setError(res.error);
        } else {
          notify(dispatch, 'success', res.message);
          setRun(!run);
          resetDetails()
          handleClose();
        }
        setLoading(false);
      });
    } catch (e) {
      console.log(e);
      setLoading(false);
      notify(dispatch, 'error', 'Unable to send data');
    }
  };
  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const editCategory = async () => {
    try {
      setLoading(true);

      const data_to_send = {
        ...details,
        id: auth.accountId,
        image: {
          name: typeof details.image === 'string' ? details.imageName : details.image.name,
          url: typeof details.image === 'string' ? details.image : await upload_to_s3(details.image, `blog-category`, auth.token)
        }
      };

      BlogService.editBlogCategoryDetails(data_to_send, auth.token).then(
        (res) => {
          if (res.error) {
            setError(res.error);
          } else {
            notify(dispatch, 'success', res.message);
            setRun(!run);
            setCategoryData(null);
            resetDetails()
            handleClose();
          }
          setLoading(false);
        }
      );
    } catch (e) {
      console.log(e);
      setLoading(false);
      notify(dispatch, 'error', 'Unable to edit data');
    }
  };

  const check_slug_availability = () => {
    if (!details.slug) {
      return;
    }
    setSlugError("");
    BlogService.checkCategorySlugAvailability(
      { slug: details.slug, id: details._id ? details._id : null },
      auth.token
    ).then((res) => {
      if (res.error) {
        setSlugError(res.error);
      } else {
        setSlugError("");
      }
    });
  };

  return (
    <div>
      {loading && <LoaderComponent loading={loading} />}

      <ModalSimple
        modalTitle={'Add Category'}
        open={open}
        action={() => setConfirmSubmit(true)}
        modalDescription={''}
        maxWidth={'xs'}
        fullWidth={true}
        disabled={slugerror ? true : false}
        handleClose={() => { handleClose(); resetDetails() }}
        actionButtonText={categoryData ? 'Edit' : 'Create'}>
        <StyledInput
          name="name"
          label="Name"
          value={details.name}
          onChange={handleChange}
        />

        <StyledInput
          name="description"
          label="Description"
          value={details.description}
          onChange={handleChange}
        />

        <div>
          <StyledInput
            name="slug"
            label="Slug"
            value={details.slug}
            onChange={handleChange}
            onBlur={check_slug_availability}
          />
          <span style={{ color: 'red', fontSize: "12px" }}>{slugerror}</span>
        </div>

        <ImageUploader
          data={details}
          setData={setDetails}
          name="image"
          label="upload image"
          imageClassName={Style.imageStyle}
        />
        {error && <span style={{ color: 'red', fontSize: "12px" }}>{error}</span>}
      </ModalSimple>

      <ConfirmDialog
        confirmDialogFunction={categoryData ? editCategory : addCategory}
        confirmDialogOpen={confirmSubmit}
        setConfirmDialogOpen={setConfirmSubmit}
        question={`Are you sure to save this category`}
      />

    </div>
  );
};

export default AddCategory;
