import React, { useEffect, useState } from 'react';
import {
  Button,
  Divider,
  MenuItem,
  Tooltip,
  ButtonGroup
} from '@material-ui/core';
import CachedIcon from '@mui/icons-material/Cached';
import TableWithSearchBar from 'components/Tables/TableWithSearchBar';
import GridOnTwoToneIcon from '@material-ui/icons/GridOnTwoTone';
import clsx from 'clsx';
import LoaderComponent from 'sites/shipper/components/LoaderComponent';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PageContent from 'sites/shipper/components/layout/PageContent';
import { CarrierVesselsService } from 'service';
import { notify } from 'sites/shipper/components/Toast/toast';
import CreateCarrierVessels from './CreateCarrierVessels/CreateCarrierVessels';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import SmallPopupWrapper from 'components/Common/SmallPopupWrapper/SmallPopupWrapper';
import ConfirmDialog from '../../shipment_rates/rate_sheet/ConfirmDialog/ConfirmDialog';
import SectionHeading from 'components/Common/Heading/SectionHeading/SectionHeading';

/* eslint-disable */
const ViewCarriers = ({ tab, carrier_id }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.session.auth);
  const transportLineInfo = useSelector(
    (state) => state.transportUnitNames.transport_lines_info
  );
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [run, setRun] = useState(false);

  const [searchKeyWord, setSearchKeyWord] = useState('');

  useEffect(() => {
    if (!transportLineInfo || transportLineInfo.id !== id) {
      history.replace('/transport-lines');
    }
  }, [transportLineInfo]);

  const [details, setDetails] = useState({
    name: '',
    short_name: '',
    number: ''
  });

  const checkTransportationUnitType = () => {
    let transportationUnitType = '';

    if (transportLineInfo?.transport_mode === 'AIR') {
      transportationUnitType = 'Air-craft';
    }

    if (transportLineInfo?.transport_mode === 'SEA') {
      transportationUnitType = 'Vessel';
    }

    return transportationUnitType;
  };




  const [CarrierVessels, setCarrierVessels] = useState([]);


  const [carrierVesselsCount, setCarrierVesselsCount] = useState(0);

  const searchParams = new URLSearchParams(location.search);
  const pageValue = searchParams.get('page') ? searchParams.get('page') : 1;
  const [page, setpage] = useState(parseInt(pageValue));
  const itemsPerPage = 15;

  const [check, setCheck] = useState(true);

  // useEffect(() => {
  //   if (loading) return;

  //   const element = document.getElementById('top');
  //   element.scrollIntoView({ behavior: 'smooth' });
  // }, [check]);

  function handlePageChange(event, value) {
    setpage(value);
    setCheck(!check);
    history.push(`/carriers/${carrier_id}&page=${value}`);
  }

  useEffect(() => {
    setLoading(true);
    CarrierVesselsService.fetchCarrierVesselsData(
      id,
      auth.token,
      searchKeyWord,
      page
    ).then((res) => {
      if (res.error) {
        console.log(res.error);
      } else {
        setCarrierVessels(res.data);
        setCarrierVesselsCount(res?.carrierVesselsCount);
      }
      setLoading(false);
    });
  }, [run, searchKeyWord, page]);
  const [error, setError] = useState();

  const [openMoreOptions, setOpenMoreOptions] = useState(false);
  const [moreOptionClicked, setMoreOptionClicked] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const handleCreateCarrierVessel = () => {
    const data_to_send = {
      carrier_id: id,
      carrier_name: transportLineInfo?.fullName,
      type: checkTransportationUnitType(),
      name: details.name,
      short_name: details.short_name,
      number: details.number,
      accountId: auth.accountId
    };
    CarrierVesselsService.createCarrierVessel(data_to_send, auth.token).then(
      (res) => {
        if (res.error) {
          setError(res.error);
        } else {
          notify(dispatch, 'success', res.message);
          setOpen(false);
          setDetails({
            name: '',
            short_name: '',
            number: ''
          });
          setRun(!run);
        }
      }
    );
  };

  const handleEditCarrierVessel = () => {
    const data_to_send = {
      id: moreOptionClicked._id,
      carrier_name: transportLineInfo?.fullName,
      name: details.name,
      short_name: details.short_name,
      number: details.number,
      accountId: auth.accountId
    };
    CarrierVesselsService.updateCarrierVessel(data_to_send, auth.token).then(
      (res) => {
        if (res.error) {
          setError(res.error);
        } else {
          notify(dispatch, 'success', res.message);
          setOpen(false);
          setDetails({
            name: '',
            short_name: '',
            number: ''
          });

          setMoreOptionClicked(null);
          setRun(!run);
        }
      }
    );
  };

  const handleDeleteCarrierVessel = () => {
    if (!moreOptionClicked._id) {
      return;
    }
    CarrierVesselsService.deleteCarrierVessel(
      {
        carrierVesselId: moreOptionClicked._id,
        carrierName: transportLineInfo?.fullName,
        vesselName: moreOptionClicked.name
      },
      auth.token
    ).then((res) => {
      if (res.error) {
        notify(dispatch, 'error', res.message);
      } else {
        notify(dispatch, 'success', res.message);
        setMoreOptionClicked(null);
        setOpenMoreOptions(null);
        setRun(!run);
      }
    });
  };

  const TableHeading = [
    'S.No.',
    'Name',
    'Short Name',
    'Number',
    'Last Updated By',

    'Action'
  ];

  const Keys = [
    'index',
    'name',
    'short_name',
    'number',
    'last_updated_by',
    '_id'
  ];

  const TableData = CarrierVessels?.map((item, index) =>
    Keys.map((Key) => {
      if (Key === 'index') {
        return index + 1;
      }
      if (Key === '_id') {
        return (
          <>
            <div className="position-relative">
              <center>
                <Tooltip title="More" arrow>
                  <Button
                    variant="text"
                    className="btn-outline-primary d-flex align-items-center justify-content-center d-30 p-0 rounded-pill shadow-none"
                    onClick={(e) => {
                      setOpenMoreOptions(e.currentTarget);
                      setMoreOptionClicked(item);
                    }}>
                    <MoreVertTwoToneIcon style={{ fontSize: '18px' }} />
                  </Button>
                </Tooltip>
              </center>
              {moreOptionClicked?._id === item?._id && (
                <SmallPopupWrapper
                  open={openMoreOptions}
                  onClose={() => {
                    setMoreOptionClicked(null);
                    setOpenMoreOptions(null);
                  }}>
                  <MenuItem
                    onClick={() => {
                      setOpen(true);
                      setOpenMoreOptions(null);
                    }}>
                    Edit
                  </MenuItem>
                  {/* <MenuItem onClick={() => { toggleRightDrawer(true); setOpenMoreOptions(null); }}>Edit Route</MenuItem> */}
                  <MenuItem
                    onClick={() => {
                      setOpenMoreOptions(null);

                      setConfirmDialogOpen(true);
                    }}>
                    Delete
                  </MenuItem>
                </SmallPopupWrapper>
              )}
            </div>
          </>
        );
      } else {
        return item[Key] || '';
      }
    })
  );
  const [open, setOpen] = useState(false);
  return (
    <div>
      {/* {loading && <LoaderComponent loading={loading} />} */}
      {/* <PageContent> */}
      <div className='d-flex flex-column pt-4' style={{ background: "#fff", borderRadius: "6px" }}>
        <SectionHeading title={"Carrier Vessels"} classes={"pl-4 ml-2"} />
        <TableWithSearchBar
          animatedSearchbar={{
            show: true,
            value: searchKeyWord,
            onChange: (value) => setSearchKeyWord(value),
            setLoading: setLoading
          }}
          loading={loading}
          TableHeading={TableHeading}
          TableBody={TableData}
          TableGrid={'repeat(6,1fr)'}
          actionButtons={[
            <Button
              variant="text"
              onClick={() => setRun(!run)}
              className={`btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill`}>
              <CachedIcon className="w-50" />
            </Button>,
            <Button
              className={`btn-primary`}
              onClick={() => {
                setOpen(true);
              }}>
              Add {checkTransportationUnitType()}
            </Button>
          ]}

          pagination={{
            count: Math.ceil(carrierVesselsCount / itemsPerPage),
            page: page,
            onChange: handlePageChange
          }}
        />
      </div>
      {/* </PageContent> */}

      <CreateCarrierVessels
        editData={moreOptionClicked}
        setEditData={setMoreOptionClicked}
        error={error}
        data={details}
        setData={setDetails}
        open={open}
        run={run}
        // edit={false}
        setRun={setRun}
        onClose={() => setOpen(false)}
        handleEdit={handleEditCarrierVessel}
        handleAdd={handleCreateCarrierVessel}
        checkTransportationUnitType={checkTransportationUnitType}
      />

      <ConfirmDialog
        confirmDialogOpen={confirmDialogOpen}
        setConfirmDialogOpen={setConfirmDialogOpen}
        confirmDialogFunction={handleDeleteCarrierVessel}
        question={'Are you sure you want to delete?'}
      />
    </div>
  );
};

export default ViewCarriers;
