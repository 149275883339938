import React, { useEffect, useState } from 'react'
import Style from "./MakeOffer.module.css";
import ViewBreakdown from '../../shipment_rates/rate_sheet/ViewBreakdown/ViewBreakdown';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { ShipmentRequestService } from 'service';
import BreakdownTable from 'components/breakdown_table/BreakdownTable';
import LeadDetails from '../../Chat/ChatViewPane/OfferChat/LeadDetails/LeadDetails';
import { Button, Divider, MenuItem, Tooltip } from "@material-ui/core";
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import SmallPopupWrapper from "components/Common/SmallPopupWrapper/SmallPopupWrapper";
import TableWithSearchBar from 'components/Tables/TableWithSearchBar'
import LoaderComponent from 'sites/shipper/components/LoaderComponent'
import Preview from 'sites/shipper/pages/Chat/ChatViewPane/OfferChat/Preview/Preview'
import ModalPopupWrapper from 'components/Common/ModalPopup/ModalPopup'
import ConfirmDialog from '../../shipment_rates/rate_sheet/ConfirmDialog/ConfirmDialog';
import { transitTimeCalculator } from 'util/RateSheet/rateSheet';
import moment from 'moment';
import { notify } from 'sites/shipper/components/Toast/toast';
import { PageTitle } from 'sites/shipper/components/layout';
import PageContent from '../../../components/layout/PageContent/PageContent';
import CustomChip from 'sites/shipper/components/CustomChip/CustomChip';
import { CARGO_TYPES, TRANSPORT_MODES } from 'util/constants';
/* eslint-disable */


const MakeOffer = () => {
    const dispatch = useDispatch()
    const [isOpen, setIsOpen] = useState(false);
    const auth = useSelector((state) => state.session.auth)
    const { id } = useParams()
    // console.log(id)
    const history = useHistory()

    const [loading, setLoading] = useState(false)
    const [make, setMake] = useState(true)
    const [viewData, setViewData] = useState()
    const [proposals, setProposals] = useState([])

    const [run, setRun] = useState(true)
    const init = async () => {
        setLoading(true)
        const data_to_send = {
            shipment_request: id,
            admin_id: auth.accountId
        }
        ShipmentRequestService.fetchProposals(data_to_send, auth.token).then(res => {
            if (res.error) {
                console.log(res.error)
            } else {
                setProposals(res)
            }
            setLoading(false)
        })
    }





    // console.log(make)
    useEffect(() => {
        init()
    }, [id, run])

    const pageData = useSelector((state) => state.makeOfferDetailsReducer)
    // console.log(pageData)
    const data = {
        "origin": {
            "state": pageData?.origin?.state,
            "port": pageData?.origin?.port,
            "city": pageData?.origin?.city,
            "country": pageData?.origin?.country
        },
        "destination": {
            "state": pageData?.destination?.state,
            "port": pageData?.destination?.port,
            "city": pageData?.destination?.city,
            "country": pageData?.destination?.country
        },
    }





    const TableHeading = [
        "S.No",
        "Carrier",
        "Vessel",
        "Price",
        "Effective Date",
        "Expiry Date",
        "Status",
        "Transit Time(Est.)",
        'Action'
    ]

    const Keys = [
        "index",
        "carrier",
        "carrier_vessel",
        "price",
        "effective_date",
        "expiry_date",
        "status",
        "transit_time",
        "_id"
    ]

    const [openMoreOptions, setOpenMoreOptions] = useState(false);
    const [moreOptionClicked, setMoreOptionClicked] = useState(null)
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [previewRateSheet, setPreviewRateSheet] = useState([])


    const togglePreview = (data) => {
        if (!isPreviewOpen) {
            setPreviewRateSheet(data)
        }
        setIsPreviewOpen((state) => !state);
    };
    const handleDeleteProposal = () => {
        setLoading(true)
        const data_to_send = {
            admin_id: auth.accountId,
            proposal_id: moreOptionClicked._id,
            shipmentRequestId: id
        }
        ShipmentRequestService.deleteProposal(data_to_send, auth.token).then(res => {
            if (res.error) {
                notify(dispatch, "error", res.error)
            } else {
                notify(dispatch, "success", res.message)
                setRun(!run)
            }
            setLoading(false)
        })
    }

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [confirmDialogOpen1, setConfirmDialogOpen1] = useState(false);
    const [confirmDialogOpen2, setConfirmDialogOpen2] = useState(false);

    const proposalStatus = (status) => {
        if (status === "ACT") {
            return (
                <CustomChip color={"success"} variant={"filled"} name={"Sent"} rounded />
            )
        }
        // <p className="m-0 font-size-xs h-10 font-weight-light text-success badge-neutral-success badge border-1">{SENT}</p>
        if (status === "PEN") {
            return (
                <CustomChip color={"warning"} variant={"filled"} name={"Pending"} rounded />
            )
        }
        if (status === "ACC") {
            return (
                <CustomChip color={"info"} variant={"filled"} name={"ACCEPTED"} rounded />
            )
        }
    }


    const TableData = proposals?.map((item, index) => Keys.map(Key => {
        if (Key === "index") {
            return index + 1
        }
        if (Key === '_id') {
            return <>
                <div className="position-relative">
                    <center>
                        <Tooltip title="More" arrow>
                            <Button
                                variant="text"
                                className="btn-outline-primary d-flex align-items-center justify-content-center d-30 mr-2 p-0 rounded-pill shadow-none"
                                onClick={(e) => {
                                    setOpenMoreOptions(e.currentTarget);
                                    setMoreOptionClicked(item)
                                }}>
                                <MoreVertTwoToneIcon style={{ fontSize: '18px' }} />
                            </Button>
                        </Tooltip>
                    </center>
                    {moreOptionClicked?._id === item?._id &&
                        <SmallPopupWrapper open={openMoreOptions} onClose={() => setOpenMoreOptions(null)}>
                            <MenuItem onClick={() => { setOpenMoreOptions(null); togglePreview(item.load.priceBreakdown) }}>View</MenuItem>
                            <MenuItem onClick={() => { setOpenMoreOptions(null); setConfirmDialogOpen2(true) }}>Add to Rate Sheet</MenuItem>
                            <MenuItem onClick={() => setConfirmDialogOpen(true)} >Remove</MenuItem>
                        </SmallPopupWrapper>
                    }
                </div>

            </>
        }
        if (Key === "price") {
            return <>{`$${item?.price?.value?.toLocaleString()}`}</>
        }
        if (Key === "effective_date" || Key === "expiry_date") {
            return item[Key] !== "NA" ? moment(item[Key]).format('LL') : "NA"
        }
        if (Key === "transit_time") {
            return transitTimeCalculator(item.transit_time.estimate)
        }
        if (Key === "status") {
            return proposalStatus(item.status)
        }
        else {
            return item[Key] || "";
        }
    }))











    const handleSendProposals = () => {
        // window.alert("TATA BYE BEY")
        setLoading(true)
        const data_to_send = {
            admin_id: auth.accountId,
            shipment_request_id: id
        }
        ShipmentRequestService.sendProposal(data_to_send, auth.token).then(res => {
            if (res.error) {
                notify(dispatch, "error", res.error)
            } else {
                notify(dispatch, "success", res.message)
                setRun(!run)
            }
            setLoading(false)
        })
    }


    const getContainerType = (item) => {
        if (item?.transport_mode === TRANSPORT_MODES.SEA.value) {
            return item?.load?.cargoDetails?.containerType?.value
        }
        if (item?.transport_mode === TRANSPORT_MODES.AIR.value) {
            return CARGO_TYPES.AIR_ST.value
        }
    }

    const handleAddTorateSheet = () => {
        setLoading(true)
        const data_to_send = {
            admin_id: auth.accountId,
            origin: pageData.origin,
            destination: pageData.destination,
            transport_mode: pageData?.transport_mode,
            carrier: moreOptionClicked?.carrier_id,
            load_type: pageData?.load_type,
            // container_type: pageData?.load?.cargoDetails?.containerType?.value,
            container_type: getContainerType(pageData),
            price_detail: moreOptionClicked.load,
            carrier_vessel: moreOptionClicked?.carrier_vessel_id,
            tag: moreOptionClicked.tag,
            schedule_dates: moreOptionClicked.schedule_dates,
            source_id: moreOptionClicked.source_id,
            price: moreOptionClicked.price
        }
        ShipmentRequestService.addToRateSheet(data_to_send, auth.token).then(res => {
            if (res.error) {
                notify(dispatch, "error", res.error)
            } else {
                notify(dispatch, "success", res.message)
            }
            setLoading(false)
        })
    }

    return (
        <>

            <PageTitle goBack={() => history.go(-1)} titleHeading={"Make Offer"} />
            <PageContent>
                <div className={Style.container}>
                    <div className={Style.lead_details}>
                        <LeadDetails pageData={pageData} />
                    </div>

                    {make &&

                        <TableWithSearchBar
                            loading={loading}
                            tableMainHeading={"All Proposals"}
                            TableHeading={TableHeading}
                            TableBody={TableData}
                            TableGrid={"1fr 1.5fr 1.5fr  1fr 1fr 1fr 1fr 1fr 1fr"}
                            actionButtons={[
                                <Button
                                    onClick={() => setIsOpen(true)}
                                    className="btn-primary"
                                >
                                    Add Proposal
                                </Button>,
                                <Button
                                    disabled={proposals.filter(item => item.status === "PEN").length === 0 ? true : false}
                                    onClick={() => setConfirmDialogOpen1(true)}
                                    className="btn-primary"
                                >
                                    Send
                                </Button>
                            ]}
                        />
                    }




                    {make &&
                        <ViewBreakdown
                            pageData={pageData}
                            open={isOpen}
                            load_type={pageData?.load_type}
                            data={data}
                            data_id={id}
                            isMakeOffer={true}
                            handleClose={() => { setIsOpen(false); setRun(!run) }}
                        />
                    }


                    {!make &&
                        <>
                            <div className={Style.lead_details}>
                                <h3>Proposed Quote</h3><br />
                                {viewData && viewData.map((d, index) => (
                                    <BreakdownTable data={d} key={index} />
                                ))}
                            </div>
                        </>
                    }
                </div>

            </PageContent>
            <ModalPopupWrapper
                maxWidth={'lg'}
                noStyle
                open={isPreviewOpen}
            >
                {previewRateSheet.length > 0 &&
                    <Preview
                        handleEdit={() => toggleBreakdownEdit(true)}
                        data={previewRateSheet}
                        open={isPreviewOpen}
                        togglePreview={togglePreview}
                        canEdit={false}
                        transportMode={pageData?.transport_mode}
                        loadType={pageData?.load_type}
                    />
                }
            </ModalPopupWrapper>


            <ConfirmDialog
                heading='Delete Proposal'
                confirmDialogOpen={confirmDialogOpen}
                setConfirmDialogOpen={setConfirmDialogOpen}
                confirmDialogFunction={handleDeleteProposal}
                question={"Are you sure you want to delete this proposal?"}
            />
            <ConfirmDialog
                heading='Send Proposals'
                confirmDialogOpen={confirmDialogOpen1}
                setConfirmDialogOpen={setConfirmDialogOpen1}
                confirmDialogFunction={handleSendProposals}
                question={"Are you sure you want to send all the proposal?"}
            />

            <ConfirmDialog
                heading='Add to Rate Sheet'
                confirmDialogOpen={confirmDialogOpen2}
                setConfirmDialogOpen={setConfirmDialogOpen2}
                confirmDialogFunction={handleAddTorateSheet}
                question={"Are you sure you want to add this proposal to the rate sheet?"}
            />
        </>
    )
}
/* eslint-disable */
export default MakeOffer