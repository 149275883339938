import StyledInput from 'components/StyledInput';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BlogService } from 'service';
import { notify } from 'sites/shipper/components/Toast/toast';
import ImageUploader from '../../ImageUploader/ImageUploader';
import { upload_to_s3 } from 'util/awsUploader';
import TinyMceEditor from '../../inputDraft/TinyMceEditor';
import { Button, MenuItem, Select, TextField, Chip } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';

import { Autocomplete } from '@material-ui/lab';

import { InputLabel, OutlinedInput, FormControl } from '@material-ui/core';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import Style from './CreateBlog.module.css';
import StyledSelect from '../../../../../../components/StyledSelect';
import LoaderComponent from 'sites/shipper/components/LoaderComponent';
import { Stack } from '@mui/material';
import ConfirmDialog from 'sites/shipper/pages/shipment_rates/rate_sheet/ConfirmDialog/ConfirmDialog';
const CreateBlog = () => {
  const [details, setDetails] = useState({
    title: '',
    content: '',
    thumbnail_image: '',
    thumbnail_image_name: '',
    hero_image: '',
    hero_image_name: '',
    category_id: [],
    meta_description: '',
    meta_title: '',
    keywords: [],
    slug: '',
    is_featured: false,
    status: ''
  });

  const [newDescription, setNewDescription] = useState('');

  // console.log('CreateBlogDetails', details);

  const [categories, setCategories] = useState([]);

  const [error, setError] = useState('');
  const [msg, setMsg] = useState('');

  const dispatch = useDispatch();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const slug = searchParams.get('slug');

  useEffect(() => {
    if (slug) {
      const data_to_send = {
        userId: auth.accountId,
        slug
      };
      BlogService.fetchBlogDetails(data_to_send, auth.token).then((res) => {
        if (res.error) {
          notify(dispatch, 'error', res.error);
        } else {
          setDetails(res?.data);
        }
      });
    }
  }, [slug]);

  const history = useHistory();

  const auth = useSelector((state) => state.session.auth);

  useEffect(() => {
    const data = {
      query: '',
      id: auth.accountId
    };
    BlogService.fetchCategories(data, auth.token).then((res) => {
      if (res.error) {
        notify(dispatch, 'error', res.error);
      } else {
        setCategories(res?.data);
      }
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'slug') {
      setError('');
      setMsg('');
      let newSlug = value.toLowerCase(); // Convert input to lowercase
      newSlug = newSlug.replace(/\s+/g, '-'); // Replace spaces with hyphens
      newSlug = newSlug.replace(/[^\w\s-]/g, '-'); // Replace special characters with hyphens

      setDetails((prev) => {
        return {
          ...prev,
          [name]: newSlug
        };
      });
    } else {
      setDetails((prev) => {
        return {
          ...prev,
          [name]: value
        };
      });
    }
  };

  const handleDescriptionChange = (value) => {
    // temporary hold for changes to description. Only saved once sumbitted
    setNewDescription(value);
    // setDetails({
    //   ...details,
    //   content: value
    // });
    // setDetails((prev) => {
    //   return {
    //     ...prev,
    //     content: value
    //   };
    // });
  };

  const [loading, setloading] = useState(false);

  const handleCreatePost = async () => {
    try {
      setloading(true);
      const data_to_send = {
        ...details,
        content: newDescription,
        userId: auth.accountId,
        thumbnail_image : {
          name: details.thumbnail_image.name,
          url:await upload_to_s3(details.thumbnail_image, `blog`,auth.token)
        },
        hero_image : {
          name: details.hero_image.name,
          url:await upload_to_s3(details.hero_image, `blog`,auth.token)
        }
      };

      BlogService.createBlog(data_to_send, auth.token).then((res) => {
        if (res.error) {
          notify(dispatch, 'error', res.error);
        } else {
          notify(dispatch, 'success', res.message);
          history.push('/articles');
        }
        setloading(false);
      });
    } catch (e) {
      setloading(false);
      console.log(e);
      notify(dispatch, 'error', e);
    }
  };

  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const handleEditPost = async () => {
    try {
      setloading(true);
      const data_to_send = {
        ...details,
        content: newDescription,
        userId: auth.accountId,
        hero_image : {
          name: typeof details.hero_image === 'string' ? details.hero_image_name : details.hero_image.name,
          url: typeof details.hero_image === 'string' ? details.hero_image : await upload_to_s3(details.hero_image, `blog`,auth.token),
        },
        thumbnail_image : {
          name: typeof details.thumbnail_image === 'string' ? details.thumbnail_image_name : details.thumbnail_image.name,
          url: typeof details.thumbnail_image === 'string' ? details.thumbnail_image : await upload_to_s3(details.thumbnail_image, `blog`,auth.token),
        }
      };

      BlogService.editBlogDetails(data_to_send, auth.token).then((res) => {
        if (res.error) {
          notify(dispatch, 'error', res.error);
        } else {
          notify(dispatch, 'success', res.message);
          history.push('/articles');
        }
        setloading(false);
      });
    } catch (e) {
      console.log(e);
      setloading(false);
      notify(dispatch, 'error', e);
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };

  const handleSelectChange = (event) => {
    const { value, name } = event.target;

    setDetails((prev) => {
      return {
        ...prev,
        [name]: typeof value === 'string' ? value.split(',') : value
      };
    });
  };

  const getCategoryName = (categoryId) => {
    const selectedCategory = categories.find(
      (category) => category._id === categoryId
    );
    return selectedCategory ? selectedCategory.name : '';
  };

  const handleAutocompleteChange = (event, value) => {
    setDetails((prev) => ({
      ...prev,
      keywords: value || [] // Ensure it's an array
    }));
  };

  const handleInputChange = (event, value) => {
    if (value && !details.keywords.includes(value)) {
      setDetails((prev) => ({
        ...prev,
        keywords: [...prev.keywords, value] // Use the previous array
      }));
    }
  };

  const check_slug_availability = () => {
    if (!details.slug) {
      return;
    }

    BlogService.checkBlogSlugAvailability(
      { slug: details.slug, id: details.id ? details.id : null },
      auth.token
    ).then((res) => {
      if (res.error) {
        setError(res.error);
      } else {
        setMsg(res.message);
      }
    });
  };

  // console.log(details);

  return (
    <>
      {loading && <LoaderComponent loading={loading} />}
      <div className={Style.main}>
        <div className={Style.main_container}>
          <div className={Style.container}>
            <span className="font-size-lg font-weight-bold">
              Title & Content
            </span>
            <StyledInput
              name="title"
              label="Title"
              value={details.title}
              margin="0"
              onChange={handleChange}
            />

            <div>
              <label>Content: </label>
              <TinyMceEditor
                onInputChange={handleDescriptionChange}
                initialValue={details.content}
              />
            </div>
          </div>

          <div className={Style.container}>
            <span className="font-size-lg font-weight-bold">
              SEO Configuration
            </span>
            <StyledInput
              name="meta_title"
              label="Meta Title"
              value={details.meta_title}
              margin="0"
              onChange={handleChange}
            />

            <StyledInput
              name="meta_description"
              label="Meta Description"
              value={details.meta_description}
              margin="0"
              onChange={handleChange}
            />

            <div>
              <StyledInput
                name="slug"
                label="Slug"
                value={details.slug}
                onChange={handleChange}
                onBlur={check_slug_availability}
                margin="0"
              />
              {error && (
                <span style={{ color: 'red', fontSize: '12px' }}>{error}</span>
              )}
            </div>
            <FormControl size="small">
              <Autocomplete
                label="Keywords"
                name="keywords"
                size="small"
                multiple
                value={[...details.keywords]}
                options={details.keywords}
                freeSolo
                // style={{ height: '40px' }}
                getOptionLabel={(option) => option}
                onChange={handleAutocompleteChange}
                // onInputChange={handleInputChange}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Keywords" />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      sx={{ fontSize: '15px' }}
                      key={index}
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </FormControl>
          </div>

          <div className={Style.container}>
            <span className="font-size-lg font-weight-bold">Images</span>
            <div className={Style.imageContainer}>
              <div>
                <label className="font-size-sm mb-1">
                  Upload Thumbnail Image{' '}
                </label>
                <ImageUploader
                  data={details}
                  setData={setDetails}
                  name="thumbnail_image"
                  label="Upload Image"
                  width="200px"
                  height="200px"
                  imageClassName={Style.thumbNailImage}
                />
              </div>
              <div>
                <label className="font-size-sm mb-1">Upload Hero Image</label>
                <ImageUploader
                  data={details}
                  setData={setDetails}
                  name="hero_image"
                  label="Upload Image"
                  width="100%"
                  imageClassName={Style.heroImage}
                  height="200px"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={Style.main_container}>
          <div className={Style.container}>
            <span className="font-size-lg font-weight-bold">
              Additional Information
            </span>
            <FormControl size="small" variant="outlined">
              <InputLabel>Select Blog Category</InputLabel>
              <Select
                multiple
                variant="outlined"
                name="category_id"
                value={details.category_id}
                onChange={handleSelectChange}
                input={<OutlinedInput label="Select Blog Category" />}
                renderValue={(selected) => (
                  <Stack gap={1} direction="row" flexWrap="wrap">
                    {selected.map((value) => (
                      <Chip
                        size="small"
                        sx={{ fontSize: '12px' }}
                        key={value}
                        label={getCategoryName(value)}
                        onDelete={() =>
                          setDetails((prev) => {
                            return {
                              ...prev,
                              category_id: details.category_id.filter(
                                (item) => item !== value
                              )
                            };
                          })
                        }
                        deleteIcon={
                          <CancelIcon
                            onMouseDown={(event) => event.stopPropagation()}
                          />
                        }
                      />
                    ))}
                  </Stack>
                )}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null
                }}>
                {categories?.length > 0 &&
                  categories.map(({ name, _id }) => (
                    <MenuItem
                      key={name}
                      value={_id}
                      sx={{ justifyContent: 'space-between' }}>
                      {details.category_id.includes(_id) ? (
                        <CheckIcon color="info" />
                      ) : null}

                      {name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <StyledSelect
              label="Select Status"
              name="status"
              margin="0"
              value={details.status}
              onChange={(e) =>
                setDetails({ ...details, status: e.target.value })
              }
              size="small"
              options={[
                { value: 'Published', label: 'Published' },
                { value: 'Draft', label: 'Draft' }
              ]}
            />

            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <label>Article is featured or not : </label>
              <input
                type="checkbox"
                value={details?.is_featured}
                checked={details?.is_featured}
                onChange={(e) =>
                  setDetails((prev) => {
                    return {
                      ...prev,
                      is_featured: !details?.is_featured
                    };
                  })
                }
              />
            </div>
            <div style={{ display: 'flex', gap: '10px' }}>
              {/* {!slug ? ( */}
              <div>
                <Button
                  variant="contained"
                  disabled={error ? true : false}
                  className="btn-primary shadow-none py-2"
                  onClick={() => setConfirmSubmit(true)}>
                  Save
                </Button>
              </div>
              {/* ) : (
                <div>
                  <Button
                    variant="contained"
                    disabled={error ? true : false}
                    className="btn-primary shadow-none py-2"
                    onClick={handleEditPost}>
                    Save
                  </Button>
                </div>
              )} */}
              <div>
                <Button
                  variant="contained"
                  className="btn-primary shadow-none py-2"
                  onClick={() => history.push('/articles')}>
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConfirmDialog
        confirmDialogFunction={!slug ? handleCreatePost : handleEditPost}
        confirmDialogOpen={confirmSubmit}
        setConfirmDialogOpen={setConfirmSubmit}
        question={`Are you sure to save`}
      />
    </>
  );
};

export default CreateBlog;
