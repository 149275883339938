import React, { useEffect, useState } from 'react';
import CreateCarriers from '../CreateTransportLines/CreateCarriers';
import ConstantValuesService from 'service/ConstantValuesService';
import { useDispatch, useSelector } from 'react-redux';
import { notify } from 'sites/shipper/components/Toast/toast';
import ConfirmDialog from '../../shipment_rates/rate_sheet/ConfirmDialog/ConfirmDialog';
import PageContent from 'sites/shipper/components/layout/PageContent';
import TableWithSearchBar from 'components/Tables/TableWithSearchBar';
import {
    Button,
    MenuItem,
    Tooltip,
} from '@material-ui/core';
import CachedIcon from '@mui/icons-material/Cached';
import { saveTranportLinesInfo } from 'actions';
import { useHistory } from 'react-router-dom';

import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import SmallPopupWrapper from 'components/Common/SmallPopupWrapper/SmallPopupWrapper';
import SkeletonComponent from 'sites/shipper/components/Skeleton/Skeleton';
import PageTitle from 'sites/shipper/components/layout/PageTitle/PageTitle';
import { TRANSPORT_MODES } from 'util/constants';

/* eslint-disable */
const SeaCarriers = () => {
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.session.auth);
    const [shippingLineArray, setShippingLineArray] = useState([]);
    const [run, setRun] = useState(true);
    const [loading, setLoading] = useState(false);

    const [search, setSearch] = useState('');

    const history = useHistory();

    const searchParams = new URLSearchParams(location.search);

    const [carrierCount, setCarrierCount] = useState(0);

    const pageValue = searchParams.get('page') ? searchParams.get('page') : 1;
    const [page, setpage] = useState(parseInt(pageValue));
    const itemsPerPage = 15;

    const [check, setCheck] = useState(true);

    // useEffect(() => {
    //   if (loading) return;

    //   const element = document.getElementById('top');
    //   element.scrollIntoView({ behavior: 'smooth' });
    // }, [check]);

    function handlePageChange(event, value) {
        setpage(value);
        setCheck(!check);
        history.push(`/carriers?tab=${TRANSPORT_MODES.SEA.value.toLowerCase()}page=${value}`);
    }

    useEffect(() => {
        setLoading(true);
        ConstantValuesService.fetchCarrier(TRANSPORT_MODES.SEA.value, search, auth.token, page).then(
            (res) => {
                if (res.error) {
                    console.log(res.error);
                } else {
                    setShippingLineArray(res?.data);
                    setCarrierCount(res?.carrierCount);
                }
                setLoading(false);
            }
        );
    }, [run, search, page]);

    const [moreOptionClicked, setMoreOptionClicked] = useState(null);

    const handleDeleteCarrier = () => {
        if (!moreOptionClicked) {
            return
        }
        const data_to_send = {
            carrierId: moreOptionClicked._id,
            carrierName: moreOptionClicked.fullName
        }
        ConstantValuesService.deleteCarrier(
            data_to_send,
            auth.token
        ).then((res) => {
            if (res.error) {
                notify(dispatch, 'error', res.error);
            } else {
                notify(dispatch, 'success', res.message);
                setRun(!run);
            }
        });
    };

    const deleteClick = () => {
        setConfirmSubmit(true);
    };

    const [edit, setEdit] = useState({
        edit: false,
        editData: null
    });

    const handleEdit = (data) => {
        if (data) {
            setEdit({
                ...edit,
                edit: true,
                editData: data
            });
            setOpen(true);
        } else {
            setEdit({
                ...edit,
                edit: false,
                editData: null
            });
            setOpen(false);
        }
    };

    const [confirmSubmit, setConfirmSubmit] = useState(false);

    const [open, setOpen] = useState(false);

    const [allow, setAllow] = useState(true);

    const [openMoreOptions, setOpenMoreOptions] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    const TableHeading = [
        'S.No.',
        'Logo',
        'Full Name',
        'Short Name',
        // 'Transport Mode',
        'Carrier Code',
        'SCAC',
        'Action'
    ];

    const Keys = [
        'index',
        'logo',
        'fullName',
        'shortName',
        // 'transportMode',
        'carrierCode',
        'scac',
        '_id'
    ];

    const TableData = shippingLineArray?.map((item, index) =>
        Keys.map((Key) => {
            if (Key === 'index') {
                return index + 1;
            }

            if (Key === 'logo') {
                return (
                    <img
                        style={{ objectFit: 'contain' }}
                        src={item[Key]?.url}
                        alt={item[Key]?.name}
                        width={50}
                        height={50}
                    />
                );
            }

            if (Key === '_id') {
                return (
                    <>
                        <div className="position-relative">
                            <center>
                                <Tooltip title="More" arrow>
                                    <Button
                                        variant="text"
                                        className="btn-outline-primary d-flex align-items-center justify-content-center d-30 p-0 rounded-pill shadow-none"
                                        onClick={(e) => {
                                            setOpenMoreOptions(e.currentTarget);
                                            setMoreOptionClicked(item);
                                        }}>
                                        <MoreVertTwoToneIcon style={{ fontSize: '18px' }} />
                                    </Button>
                                </Tooltip>
                            </center>
                            {moreOptionClicked?._id === item?._id && (
                                <SmallPopupWrapper
                                    open={openMoreOptions}
                                    onClose={() => {
                                        setMoreOptionClicked(null);
                                        setOpenMoreOptions(null);
                                    }}>
                                    <MenuItem
                                        onClick={() => {
                                            const data_to_save = {
                                                id: item._id,
                                                fullName: item?.fullName,
                                                shortName: item?.shortName,
                                                transportMode: item?.transportMode,
                                                logo: item?.logo,
                                                carrierCode: item?.carrierCode,
                                                scac: item?.scac
                                            };
                                            dispatch(saveTranportLinesInfo(data_to_save));
                                            history.push(`/carriers/${item._id}`);
                                            setOpenMoreOptions(null);
                                        }}>
                                        View
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            handleEdit(item);
                                            setOpen(true);
                                            setOpenMoreOptions(null);
                                        }}>
                                        Edit
                                    </MenuItem>
                                    {/* <MenuItem onClick={() => { toggleRightDrawer(true); setOpenMoreOptions(null); }}>Edit Route</MenuItem> */}
                                    <MenuItem
                                        onClick={() => {
                                            setOpenMoreOptions(null);
                                            deleteClick();
                                            // setConfirmDialogOpen(true);
                                        }}>
                                        Delete
                                    </MenuItem>
                                </SmallPopupWrapper>
                            )}
                        </div>
                    </>
                );
            } else {
                return item[Key] || '';
            }
        })
    );

    return (
        <>

            {/* {shippingLineArray.length > 0 ? ( */}

            <TableWithSearchBar
                animatedSearchbar={{
                    show: true,
                    value: search,
                    onChange: (value) => setSearch(value),
                    setLoading: setLoading
                }}
                loading={loading}
                TableHeading={TableHeading}
                TableBody={TableData}
                TableGrid={'repeat(8,1fr)'}
                actionButtons={[
                    <Button
                        variant="text"
                        onClick={() => setRun(!run)}
                        className={`btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill`}>
                        <CachedIcon className="w-50" />
                    </Button>,
                    <Button
                        className={`btn-primary`}
                        onClick={() => {
                            setOpen(true);
                        }}>
                        Add Carrier
                    </Button>
                ]}
                pagination={{
                    count: Math.ceil(carrierCount / itemsPerPage),
                    page: page,
                    onChange: handlePageChange
                }}
            />
            <ConfirmDialog
                confirmDialogFunction={handleDeleteCarrier}
                confirmDialogOpen={confirmSubmit}
                setConfirmDialogOpen={setConfirmSubmit}
                question={`Are you sure to delete this transport line`}
            />

            {/* {edit.edit ? ( */}
            <CreateCarriers
                open={open}
                run={run}
                edit={edit.edit}
                editData={edit.editData}
                setRun={setRun}
                onClose={() => {
                    setOpen(false);
                    setEdit({
                        edit: false,
                        editData: null
                    });
                }}
                handleEdit={handleEdit}
                transportMode={TRANSPORT_MODES.SEA.value}
            />

        </>
    );
};
/* eslint-enable */
export default SeaCarriers;
