import Style from "./ViewBreakdown.module.css";
import React, { useEffect, useState } from 'react'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import EastIcon from '@mui/icons-material/East';
import TopBar from "./TopBar/TopBar";
import Preview from "./Preview/Preview";
import { IconButton } from "@mui/material";
import { Button, Divider, Tooltip } from "@material-ui/core";
import Summary from "./Summary/Summary";
// import { CreateShipment, ForwarderService } from "service";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ImportantNote from "./ImportantNote/ImportantNote";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { notify } from "sites/shipper/components/Toast/toast";
import { useDispatch } from 'react-redux';
import RateSheetService from "service/RateSheetService";
import { ForwarderService, ShipmentRequestService } from "service";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import LoaderComponent from "sites/shipper/components/LoaderComponent";
import moment from "moment";
import { chargeDetails, findHighestPriceItem, findLowestPriceItem, initialPriceBreakDown, sectionName } from "../../RateSheetContent";
import { loadDisplay, renderShipmentAddress } from "util/Shipment/shipmentLoads";
import PageContent from 'sites/shipper/components/layout/PageContent';
import PageTitle from "sites/shipper/components/layout/PageTitle/PageTitle";
import MetaDetails from "./Summary/MetaDetails";
import SummaryPop from "./Summary/SummaryPop";
import { transitTimeCalculator } from "util/RateSheet/rateSheet";
import { CARGO_CATEGORIES, TRANSPORT_MODES, TRANSPORT_MODES_LIST } from "util/constants";
import { numberToLocaleString } from "util/Shipment/shipment";
// import { West } from '@mui/icons-material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/* eslint-disable */

const ViewBreakdown = ({
    pageData,
    run,
    setRun,
    method,
    open,
    handleClose,
    data,
    data_id,
    isMakeOffer,
    chatEditOffer = false,
    editData,
    handleEditOfferCard,
    load_type = null,
    route_id = ""
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const [additionalData, setAdditionalData] = useState({
        loadType: null,
        containerType: null,
        carrier_id: null,
        carrier_vessels: null,
        tag: [],
        arrival_date: null,
        departureDate: null,
        source_id: null
    })
    const handleAdditionalData = (name, value) => {
        // const { name, value } = e.target
        setError("")
        if (name === "carrier_id") {
            setAdditionalData({
                ...additionalData,
                carrier_id: value,
                carrier_vessels: null
            })
        } else {
            setAdditionalData({
                ...additionalData,
                [name]: value
            })
        }
    }

    // console.log("dmhbvjh", additionalData)

    const [breakdownData, setBreakdownData] = useState({
        transit_time: {
            estimate: {
                min: null,
                max: null,
                unit: "Days"
            },
            port_to_port: {
                min: null,
                max: null,
                unit: "Days"
            },
            are_both_equal: false
        },
        effective_date: null,
        expiry_date: null,
        price_breakdown: [],
        // price_breakdown: initialPriceBreakDown(isMakeOffer ? pageData?.transport_mode : data.transportMode, pageData,additionalData),
        minAmount: {
            value: 0,
            currency: "usd"
        },
        maxAmount: {
            value: 0,
            currency: "usd"
        }
    })


    // this function resets the rate sheet to default 
    const handleReset = () => {
        setBreakdownData({
            ...breakdownData,
            price_breakdown: []
            // price_breakdown: initialPriceBreakDown(isMakeOffer ? pageData?.transport_mode : data.transportMode, pageData,additionalData)
        })
    }

    const auth = useSelector((state) => state.session.auth)


    const [createPriceResponse, setCreatePriceResponse] = useState({
        isNewPrice: false,
        msg: ""
    })
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")




































    // create new rate sheet


    const createRateSheet = () => {
        setLoading(true)
        const dataToSend = {
            route_id: route_id,
            load_type: additionalData.loadType,
            carrier: additionalData.carrier_id,
            container_type: additionalData.containerType,
            carrier_vessels: additionalData.carrier_vessels,
            rate_sheet_data: breakdownData,
            tag: additionalData.tag,
            arrival_date: additionalData.arrival_date,
            departureDate: additionalData.departureDate,
            source_id: additionalData.source_id,
            origin: data?.origin,
            destination: data?.destination,
            transportMode: data.transportMode
        }
        RateSheetService.saveRateSheetPriceInfo(auth.accountId, dataToSend, auth.token).then(data => {
            if (data.message) {
                notify(dispatch, "success", data.message)
                history.replace(`/rate-sheet/${route_id}`)
            } else {
                notify(dispatch, "error", data.error)
            }
            setLoading(false)
        })
    }



















    // // this handles the submit of whole rate sheet


    const handleSubmit = () => {
        setLoading(true)
        if (isMakeOffer && pageData) {
            if (
                !additionalData.carrier_id ||
                !breakdownData.effective_date ||
                !breakdownData.transit_time.estimate.min ||
                !breakdownData.transit_time.estimate.max ||
                !breakdownData.transit_time.port_to_port.min ||
                !breakdownData.transit_time.port_to_port.max
            ) {
                setError("Please fill in all the required fields marked with *")
                setLoading(false)
                return
            }
            const dataToSend = {
                carrier: additionalData.carrier_id,
                carrier_vessels: additionalData.carrier_vessels,
                shipper_id: pageData.shipper._id,
                created_by: auth.accountId,
                shipment_request_id: data_id,
                charges: breakdownData,
                tag: additionalData.tag,
                arrival_date: additionalData.arrival_date,
                departureDate: additionalData.departureDate,
                source_id: additionalData.source_id,
                origin: data?.origin,
                destination: data?.destination,
                transportMode: pageData?.transport_mode
            }
            ShipmentRequestService.addProposal(dataToSend, auth.token).then(data => {
                if (data.message) {
                    notify(dispatch, "success", data.message)
                    // history.replace('/shipment-request')
                    handleClose()
                } else {
                    notify(dispatch, "error", data.error)
                }
                setLoading(false)
            })
        }
        else {
            if (
                !additionalData.loadType ||
                !additionalData.carrier_id ||
                !additionalData.containerType ||
                // !additionalData.type ||
                !breakdownData.effective_date ||
                !breakdownData.transit_time.estimate.min ||
                !breakdownData.transit_time.estimate.max ||
                !breakdownData.transit_time.port_to_port.min ||
                !breakdownData.transit_time.port_to_port.max
            ) {
                setError("Please fill in all the required fields marked with *")
                setLoading(false)
                return
            }
            if (method === "edit") {
                const data_to_edit = {
                    admin_id: auth.accountId,
                    price_id: editData._id,
                    // price_breakdown: breakdownData.price_breakdown
                    load_type: additionalData.loadType,
                    carrier: additionalData.carrier_id,
                    container_type: additionalData.containerType,
                    carrier_vessels: additionalData.carrier_vessels,
                    rate_sheet_data: breakdownData,
                    tag: additionalData.tag,
                    arrival_date: additionalData.arrival_date,
                    departureDate: additionalData.departureDate,
                    source_id: additionalData.source_id,
                    origin: data?.origin,
                    destination: data?.destination,
                    transportMode: data.transportMode
                }
                RateSheetService.editRateSheetPriceInfo(data_to_edit, auth.token).then(data => {
                    if (data.message) {
                        // window.alert(data.message)
                        notify(dispatch, "success", data.message)
                        handleReset();
                        handleClose();
                        setRun(!run);
                    } else {
                        notify(dispatch, "error", data.error)
                        // window.alert(data.error)
                    }
                    setLoading(false)
                })
            } else {
                createRateSheet()
            }
        }
    }


    // console.log("dfjbkj", editData)
    // console.log("dfjbkj", additionalData)

    React.useEffect(() => {

        // console.log("abcs", editData)
        if (method === "edit" && editData) {
            setAdditionalData({
                ...additionalData,
                loadType: editData?.load_type,
                containerType: editData?.container_type,
                carrier_id: editData?.carrier_id?._id,
                carrier_vessels: editData?.carrier_vessels?._id,
                tag: editData?.tag,
                arrival_date: editData?.schedule_dates?.arrival_date,
                departureDate: editData?.schedule_dates?.departureDate,
                source_id: editData?.source_id,
            })

            setBreakdownData({
                ...breakdownData,
                transit_time: {
                    ...breakdownData.transit_time,
                    estimate: {
                        min: editData?.transit_time?.estimate?.min,
                        max: editData?.transit_time?.estimate?.max,
                        unit: editData?.transit_time?.estimate?.unit
                    },
                    port_to_port: {
                        min: editData?.transit_time?.port_to_port?.min,
                        max: editData?.transit_time?.port_to_port?.max,
                        unit: editData?.transit_time?.port_to_port?.unit
                    },
                    are_both_equal: editData?.transit_time?.are_both_equal ? editData?.transit_time?.are_both_equal : false
                },
                effective_date: editData?.load?.effectiveDate ? moment(editData?.load?.effectiveDate).format("YYYY-MM-DD") : null,
                expiry_date: editData?.load?.expiryDate ? moment(editData?.load?.expiryDate).format("YYYY-MM-DD") : null,
                price_breakdown: editData?.load?.priceBreakdown
            })
        }
        // console.log(data)
        // console.log(data.load.fcl['20FT'][(data.load.fcl['20FT']).length-1])
        // if (method === "edit" && data !== undefined) {
        //     if (data === undefined) {
        //         return
        //     }
        //     let type
        //     if (containerType === "20FT") {
        //         type = data.load.fcl['20FT'][(data.load.fcl['20FT']).length - 1]
        //     }
        //     if (containerType === "40FT") {
        //         type = data.load.fcl['40FT'][(data.load.fcl['40FT']).length - 1]
        //     }
        //     if (containerType === "40FT HC") {
        //         type = data.load.fcl['40FT HC'][(data.load.fcl['40FT HC']).length - 1]
        //     }
        //     if (containerType === "45FT HC") {
        //         type = data.load.fcl['45FT HC'][(data.load.fcl['45FT HC']).length - 1]
        //     }
        //     if (!type) {
        //         return
        //     }
        //     console.log(type);
        //     // setBreakdownData(type)
        //     setBreakdownData({
        //         ...breakdownData,
        //         effective_date: type?.effective_date?.split("T")[0],
        //         expiry_date: type?.expiry_date?.split("T")[0],
        //         price_breakdown: type?.price_breakdown
        //     })
        // }

    }, [editData])






    //   this adds a table section to preview 
    const handleAddSection = (title) => {

        const obj = { title: title, data: [] }

        setBreakdownData((previousData) => ({
            ...previousData,
            price_breakdown: [...previousData.price_breakdown, obj]
        }))
    }






    useEffect(() => {
        if (pageData?.rate_sheet) {
            setBreakdownData({
                ...breakdownData,
                price_breakdown: pageData?.rate_sheet
            })
        }
    }, [pageData])




    useEffect(() => {
        if (isMakeOffer) {

            let containerType = pageData?.load?.cargoDetails?.containerType?.value
            if (pageData?.load?.cargoType?.value === CARGO_CATEGORIES.RORO.value) {
                containerType = pageData?.load?.cargoDetails?.cargoType?.value
            }
            // console.log("kdjfhbvj", containerType)
            setAdditionalData({
                ...additionalData,
                loadType: pageData?.load?.cargoType?.value,
                containerType: containerType,
                carrier_id: null,
                carrier_vessels: null,
                tag: [],
                arrival_date: null,
                departureDate: null,
                source_id: null
            })
            setBreakdownData({
                ...breakdownData,
                price_breakdown: initialPriceBreakDown(pageData?.transport_mode, pageData, {
                    loadType: pageData?.load?.cargoType?.value,
                    containerType: containerType
                }),
                transit_time: {
                    estimate: {
                        min: null,
                        max: null,
                        unit: "Days"
                    },
                    port_to_port: {
                        min: null,
                        max: null,
                        unit: "Days"
                    },
                    are_both_equal: false
                },
                effective_date: null,
                expiry_date: null,
                minAmount: {
                    value: 0,
                    currency: "usd"
                },
                maxAmount: {
                    value: 0,
                    currency: "usd"
                }
            })
        }
    }, [pageData, open])































    function checkTransitTimes(transitTime) {
        const result = {
            portToPortMin: false,
            portToPortMax: false,
            estimateMin: false,
        };

        const portToPortMin = transitTime?.port_to_port?.min;
        const portToPortMax = transitTime?.port_to_port?.max;
        const estimateMin = transitTime?.estimate?.min;
        const estimateMax = transitTime?.estimate?.max;



        if (estimateMin > estimateMax) {
            result.estimateMin = true;
        }
        if (portToPortMin > portToPortMax) {
            result.portToPortMin = true;
        }
        if (estimateMin < portToPortMin) {
            result.portToPortMin = true;
        }
        if (estimateMax < portToPortMax) {
            result.portToPortMax = true;
        }

        return result;
    }

    const transitTimeValidation = checkTransitTimes(breakdownData?.transit_time);
    const [confirmOfferDialogOpen, setConfirmOfferDialogOpen] = useState(false);



    const [summaryPopUp, setSummaryPopUp] = useState(false)
    const [summaryData, setSummaryData] = useState(null)


    const [transportationLineArray, setTransportationLineArray] = useState([])
    const [transportUnitNameArray, setTransportUnitNameArray] = useState([])

    // isMakeOffer ? Style.normal_container :

    const [isSubmitDisable, setIsSubmitDisable] = useState([])










    const calculateTotal = () => {
        if (breakdownData?.price_breakdown.length > 0) {
            let minSum = 0;
            let maxSum = 0;
            const transportMode = isMakeOffer ? pageData?.transport_mode : data.transportMode
            breakdownData?.price_breakdown.map(data => {
                let minArray = data.data
                let maxArray = data.data
                if (data.title === sectionName["Shipment Charges"]) {
                    if (transportMode === TRANSPORT_MODES.AIR.label) {
                        minArray = findLowestPriceItem(data.data)
                        maxArray = findHighestPriceItem(data.data)
                    }
                    if (transportMode === TRANSPORT_MODES.SEA.label && additionalData?.loadType === CARGO_CATEGORIES.RORO.label) {
                        minArray = findLowestPriceItem(data.data)
                        maxArray = findHighestPriceItem(data.data)
                    }
                }

                minArray.map(amount_data => minSum += Number(amount_data.amount))
                maxArray.map(amount_data => maxSum += Number(amount_data.amount))
            })
            // setTotal(sum)
            setBreakdownData({
                ...breakdownData,
                minAmount: { ...breakdownData.minAmount, value: minSum },
                maxAmount: { ...breakdownData.maxAmount, value: maxSum }
            })
        }
    }

    useEffect(() => {
        calculateTotal()
    }, [breakdownData?.price_breakdown])



    const renderTotalPrice = () => {
        let sum = 0;
        breakdownData?.price_breakdown.map(data => {
            let array = data.data
            array.map(amount_data => sum += Number(amount_data.amount))
        })

        const transportMode = isMakeOffer ? pageData?.transport_mode : data.transportMode
        if (transportMode === TRANSPORT_MODES.AIR.label) {
            return (
                <div className="text-white font-size-lg m-0">
                    {/* <p className="m-0 mb-1">
                        Total - ${numberToLocaleString(sum)}
                    </p> font-size-md*/}
                    <p className=" m-0">
                        Total (Starting from) - ${numberToLocaleString(breakdownData?.minAmount?.value)}
                    </p>
                </div>
            )
        }
        if (transportMode === TRANSPORT_MODES.SEA.label && additionalData?.loadType === CARGO_CATEGORIES.RORO.label) {
            return (
                <div className="text-white font-size-lg m-0">
                    {/* <p className="m-0 mb-1">
                        Total - ${numberToLocaleString(sum)}
                    </p> font-size-md */}
                    <p className="m-0">
                        Total (Starting from) - ${numberToLocaleString(breakdownData?.minAmount?.value)}
                    </p>
                </div>
            )
        }
        return (
            <div className="text-white font-size-lg">
                <p className="m-0">
                    Total - ${numberToLocaleString(sum)}
                </p>
            </div>
        )
    }


    const renderTransportMode = () => {
        const transportMode = isMakeOffer ? pageData?.transport_mode : data.transportMode
        const icon = TRANSPORT_MODES_LIST.filter((x) => x.value === transportMode)[0]?.icon
        return (<FontAwesomeIcon
            className="mb-1 mt-1  d-24"
            icon={['fas', `${icon}`]}
        />)
    }


    return (
        <>
            {loading && <LoaderComponent loading={loading} />}
            {open &&
                <div className={`${Style.popup_container}`}>
                    <div className={Style.overflow_container}>

                        <div className={Style.container}>
                            <PageTitle
                                goBack={() => {
                                    if (handleClose) {
                                        handleClose()
                                    } else {
                                        history.go(-1)
                                        // history.replace(`/rate-sheet/${route_id}`)
                                    }
                                }}
                                titleHeading={
                                    <div className={Style.nav_and_detail}>
                                        {/* className={Style.detail_text} */}
                                        {renderTransportMode()}
                                        <Tooltip title={renderShipmentAddress(data?.origin)}>
                                            <span className="text-truncate w-30">
                                                {renderShipmentAddress(data?.origin)}
                                            </span>
                                        </Tooltip>
                                        {/* <span>{data?.origin?.state?.name}, {data?.origin?.city?.name}, {data?.origin?.port?.code}, {data?.origin?.country?.code}</span> */}
                                        <EastIcon sx={{ fontSize: '20px' }} />
                                        <Tooltip title={renderShipmentAddress(data?.destination)}>
                                            <span className="text-truncate w-30">
                                                {renderShipmentAddress(data?.destination)}
                                            </span>
                                        </Tooltip>
                                        {/* <span>{data?.destination?.state?.name}, {data?.destination?.city?.name}, {data?.destination?.port?.code}, {data?.destination?.country?.code}</span> */}
                                        {/* {isMakeOffer && <>
                                            <Divider orientation="vertical" flexItem />
                                            {loadDisplay(pageData?.load)}
                                        </>
                                        } */}
                                    </div>
                                }
                            />
                            <PageContent>

                                {/* <div className={Style.nav_and_detail}>


                                    <IconButton
                                        onClick={() => {
                                            if (handleClose) {
                                                handleClose()
                                            } else {
                                                history.replace(`/rate-sheet/${route_id}`)
                                            }
                                        }}
                                        className={Style.back_btn}>
                                        <KeyboardBackspaceIcon sx={{ fontSize: '15px' }} />
                                    </IconButton>

                                    <span className={Style.detail_text}>{data?.origin?.state?.name}, {data?.origin?.city?.name}, {data?.origin?.port?.code}, {data?.origin?.country?.code}</span>
                                    <EastIcon sx={{ fontSize: '20px' }} />
                                    <span className={Style.detail_text}>{data?.destination?.state?.name}, {data?.destination?.city?.name}, {data?.destination?.port?.code}, {data?.destination?.country?.code}</span>
                                    {isMakeOffer && <>
                                        <Divider orientation="vertical" flexItem />
                                        {loadDisplay(pageData?.load)}
                                    </>
                                    }
                                </div> */}
                                {/* <span className={Style.detail_text}>{load_type}</span> */}

                                {/* top bar for title  */}
                                {/* <div className={`${Style.main} ${Style.top_bar_container}`}>
                                    <TopBar breakdownData={breakdownData} setBreakdownData={setBreakdownData} handleAddSection={handleAddSection}
                                        handleReset={handleReset}
                                    />
                                </div> */}
                                {/* <ImportantNote note="Important Note: The quotation you are about to send is a custom quote tailored specifically for this shipment and shipper. It will not be included in your standard rates unless manually specified." /> */}


                                <div className={Style.main}>

                                    {/* preview  */}
                                    <div className="d-flex flex-column c-gap-2 w-100">
                                        <Preview
                                            isSubmitDisable={isSubmitDisable}
                                            additionalData={additionalData}
                                            setIsSubmitDisable={setIsSubmitDisable}
                                            transportMode={isMakeOffer ? pageData?.transport_mode : data.transportMode}
                                            pageData={pageData}
                                            method={method}
                                            breakdownData={breakdownData}
                                            setBreakdownData={setBreakdownData}
                                            handleAddSection={handleAddSection}
                                            handleReset={handleReset}

                                        />
                                        <MetaDetails
                                            handleAdditionalData={handleAdditionalData}
                                            additionalData={additionalData}
                                        />
                                    </div>

                                    {/* summary  */}
                                    <Summary
                                        chatEditOffer={chatEditOffer}
                                        method={method}
                                        handleSubmit={handleSubmit}
                                        additionalData={additionalData}
                                        setAdditionalData={setAdditionalData}
                                        handleAdditionalData={handleAdditionalData}
                                        data={data}
                                        breakdownData={breakdownData}
                                        setBreakdownData={setBreakdownData}
                                        isMakeOffer={isMakeOffer}
                                        error={error}
                                        setError={setError}
                                        isSubmitDisable={isSubmitDisable}
                                        transportationLineArray={transportationLineArray}
                                        setTransportationLineArray={setTransportationLineArray}
                                        transportUnitNameArray={transportUnitNameArray}
                                        setTransportUnitNameArray={setTransportUnitNameArray}
                                        checkTransitTimes={checkTransitTimes}
                                        transportMode={isMakeOffer ? pageData?.transport_mode : data.transportMode}
                                        handleClose={() => {
                                            if (handleClose) {
                                                handleClose()
                                            } else {
                                                history.replace(`/rate-sheet/${route_id}`)
                                            }
                                        }}
                                    />

                                </div>

                                <div className={`${Style.footer} bg-serious-blue `}>
                                    <div>
                                        {renderTotalPrice()}
                                    </div>
                                    <div className={`d-flex flex-column c-gap-half ${Style.footerBtnContainer}`}>
                                        {error && <p className='m-0 mr-4 text-danger font-size-xs'>{error}</p>}

                                        <div className="d-flex c-gap-half">
                                            <Button
                                                className={`btn-warning text-light ${Style.cancelButton}`}
                                                // startIcon={<West />}
                                                size="small"
                                                onClick={() => {
                                                    if (handleClose) {
                                                        handleClose()
                                                    } else {
                                                        history.replace(`/rate-sheet/${route_id}`)
                                                    }
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            {!isMakeOffer
                                                ?
                                                <>
                                                    <Button
                                                        onClick={() => {
                                                            if (method === "edit") {
                                                                setConfirmDialogOpen(true)
                                                            } else {
                                                                if (
                                                                    !additionalData.loadType ||
                                                                    !additionalData.carrier_id ||
                                                                    !additionalData.containerType ||
                                                                    // !additionalData.type ||
                                                                    !breakdownData.effective_date ||
                                                                    !breakdownData.transit_time.estimate.min ||
                                                                    !breakdownData.transit_time.estimate.max ||
                                                                    !breakdownData.transit_time.port_to_port.min ||
                                                                    !breakdownData.transit_time.port_to_port.max
                                                                ) {
                                                                    setError("Please fill in all the required fields marked with *")
                                                                    // notify(dispatch, "error", "Please Select Required Fields")
                                                                    return
                                                                }
                                                                setSummaryPopUp(true)

                                                                const data_to_set = {
                                                                    estimate_transit_time: transitTimeCalculator(breakdownData?.transit_time.estimate),
                                                                    port_to_port_transit_time: transitTimeCalculator(breakdownData?.transit_time.port_to_port),
                                                                    transport_line: transportationLineArray.filter(item => item.value === additionalData?.carrier_id)[0]?.label,
                                                                    effective_date: breakdownData?.effective_date,
                                                                    expiry_date: breakdownData?.expiry_date,
                                                                    transport_unit_name: transportUnitNameArray?.filter(item => item.value === additionalData?.carrier_vessels)[0]?.label,
                                                                    amount: breakdownData?.minAmount,
                                                                }
                                                                setSummaryData(data_to_set)
                                                            }
                                                        }}
                                                        disabled={
                                                            (
                                                                !additionalData.carrier_id ||
                                                                !breakdownData.effective_date ||
                                                                !breakdownData.transit_time.estimate.min ||
                                                                !breakdownData.transit_time.estimate.max ||
                                                                !breakdownData.transit_time.port_to_port.min ||
                                                                !breakdownData.transit_time.port_to_port.max ||
                                                                transitTimeValidation.portToPortMin ||
                                                                transitTimeValidation.portToPortMax ||
                                                                transitTimeValidation.estimateMin ||
                                                                (breakdownData.expiry_date !== null && breakdownData.expiry_date < breakdownData.effective_date) ||
                                                                (isSubmitDisable.length > 0 && isSubmitDisable.includes(true))
                                                            ) ? true : false}
                                                        // style={{ textTransform: 'none', backgroundColor: '#4431bb', color: 'white' }}
                                                        className={`btn-success text-light ${Style.submitButton} `}
                                                    >
                                                        {method === "edit" ? "Update" : "Create"}
                                                    </Button>
                                                </>
                                                :
                                                // make offer button 
                                                <>
                                                    <Button
                                                        onClick={() => setConfirmOfferDialogOpen(true)}
                                                        disabled={
                                                            !additionalData.carrier_id ||
                                                            !breakdownData.effective_date ||
                                                            !breakdownData.transit_time.estimate.min ||
                                                            !breakdownData.transit_time.estimate.max ||
                                                            !breakdownData.transit_time.port_to_port.min ||
                                                            !breakdownData.transit_time.port_to_port.max ||
                                                            transitTimeValidation.portToPortMin ||
                                                            transitTimeValidation.portToPortMax ||
                                                            transitTimeValidation.estimateMin ||
                                                            (breakdownData.expiry_date !== null && breakdownData.expiry_date < breakdownData.effective_date) ||
                                                            (isSubmitDisable.length > 0 && isSubmitDisable.includes(true))
                                                        }
                                                        className={`btn-success text-light ${Style.submitButton}`}
                                                    // style={{ textTransform: 'none', backgroundColor: '#4431bb', color: 'white' }}
                                                    >
                                                        Create
                                                        {/* Make Offer */}
                                                    </Button>
                                                </>
                                            }

                                        </div>
                                    </div>
                                </div>

                            </PageContent>
                        </div>
                    </div>
                    <ConfirmDialog
                        confirmDialogOpen={confirmDialogOpen}
                        setConfirmDialogOpen={setConfirmDialogOpen}
                        confirmDialogFunction={handleSubmit}
                        question={method === "edit" ? "Are you sure you want to update this rate-sheet?" : "Are you sure you want to add this rate-sheet?"}
                    />
                    <ConfirmDialog
                        confirmDialogOpen={confirmOfferDialogOpen}
                        setConfirmDialogOpen={setConfirmOfferDialogOpen}
                        confirmDialogFunction={handleSubmit}
                        question={"Are you sure you want to make this offer?"}
                    />
                    {summaryPopUp &&
                        <SummaryPop
                            open={summaryPopUp}
                            handleSubmit={handleSubmit}
                            handleClose={() => setSummaryPopUp(false)}
                            data={summaryData}
                        />
                    }

                </div>
            }





        </>

    )
}
/* eslint-enable */
export default ViewBreakdown