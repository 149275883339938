import React, { useState } from 'react'
import StyledSelect from 'components/StyledSelect'
import { getSectionName } from '../../RateSheetContent'
import ModalSimple from 'components/ModalSimple'

const AddSection = ({ open, setOpen, handleSubmit, breakdownData }) => {
    const [title, setTitle] = useState("")
    return (
        <div>
            <ModalSimple
                modalTitle={"Select Section Name"}
                open={open}
                actionButtonText='Add'
                action={() => { setOpen(false); handleSubmit(title) }}
                handleClose={() => {
                    setOpen(false)
                }}
                fullWidth
                disabled={!title}
                maxWidth="sm"
            >
                <StyledSelect
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    options={getSectionName(breakdownData.price_breakdown)}
                    label={"Section Name"}
                />
            </ModalSimple>
        </div>
    )
}

export default AddSection