import { getBaseUrl } from 'config/config';
// import { HttpMethods, HttpService } from './index';
import { Token } from 'aws-sdk';
import { HttpMethods, httpService } from './global';

export default class ServiceManagementService {

    static async createService(admin_id, data, token) {
        const url = `admin/shipment-service/create?admin_id=${admin_id}`;
         return httpService(HttpMethods.POST, url, data, {
           token,
           type: 'service_management',
           method: 'create',
           platform: 'Admin'
         })
           .then((res) => {
             return res;
           })
           .catch((err) => console.log(err));
      
    }

    static async editService(admin_id, serviceId, data, token) {
        const url = `admin/shipment-service/edit?admin_id=${admin_id}&serviceId=${serviceId}`;

         return httpService(HttpMethods.POST, url, data, {
           token,
           type: 'service_management',
           method: 'edit',
           platform: 'Admin'
         })
           .then((res) => {
             return res;
           })
           .catch((err) => console.log(err));
       
    }

    static async deleteService(data, token) {
        const url = `admin/shipment-service/delete`;

          return httpService(HttpMethods.DELETE, url, data, {
            token,
            type: 'service_management',
            method: 'delete',
            platform: 'Admin'
          })
            .then((res) => {
              return res;
            })
            .catch((err) => console.log(err));
       
    }

    static async fetchService(admin_id, serviceId, token,page,search) {
        const url = `admin/shipment-service/fetch?admin_id=${admin_id}&serviceId=${serviceId}&page=${page}&search=${search}`;

          return httpService(HttpMethods.GET, url, null, {
            token,
            type: 'service_management',
            method: 'read',
            platform: 'Admin'
          })
            .then((res) => {
              return res;
            })
            .catch((err) => console.log(err));
       
    }


    static async createServicePrice(admin_id, data, token) {
        const url = `admin/shipment-service/create-price?admin_id=${admin_id}`;

         return httpService(HttpMethods.POST, url, data, {
           token,
           type: 'service_management',
           method: 'create',
           platform: 'Admin'
         })
           .then((res) => {
             return res;
           })
           .catch((err) => console.log(err));
      
    }

    static async fetchServicePrice(admin_id, service_id, token,page) {
        const url = `admin/shipment-service/fetch-price?admin_id=${admin_id}&service_id=${service_id}&page=${page}`;

         return httpService(HttpMethods.GET, url, null, {
           token,
           type: 'service_management',
           method: 'read',
           platform: 'Admin'
         })
           .then((res) => {
             return res;
           })
           .catch((err) => console.log(err));
       
    }

    static async deleteServicePrice(data, token) {
        const url = `admin/shipment-service/delete-price`;

          return httpService(HttpMethods.DELETE, url, data, {
            token,
            type: 'service_management',
            method: 'delete',
            platform: 'Admin'
          })
            .then((res) => {
              return res;
            })
            .catch((err) => console.log(err));
       
    }

    static async editServicePrice(admin_id, shipmentPriceId, data, token) {
        const url = `admin/shipment-service/edit-price?admin_id=${admin_id}&shipmentPriceId=${shipmentPriceId}`;

         return httpService(HttpMethods.POST, url, data, {
           token,
           type: 'service_management',
           method: 'edit',
           platform: 'Admin'
         })
           .then((res) => {
             return res;
           })
           .catch((err) => console.log(err));
       
    }

}
