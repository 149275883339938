import { getBaseUrl } from 'config/config';
import { HttpMethods, HttpService } from './index';
import { httpService } from './global';

export default class UserMetricsService {


    static async fetchAllAccounts(data, token) {
        const url = `admin/fetch-account-meta`;

        return httpService(HttpMethods.POST, url, data, {
            token,
            type: 'user_metrics',
            method: 'read',
            platform: 'Admin'
        })
            .then((res) => {
                return res;
            })
            .catch((err) => console.log(err));
    }




    static async updateAccountMetaShipper(data, token) {
        const url = `admin/s/update-account-meta`;

        return httpService(HttpMethods.POST, url, data, {
            token,
            type: 'user_metrics',
            method: 'edit',
            platform: 'Admin'
        })
            .then((res) => {
                return res;
            })
            .catch((err) => console.log(err));
    }

    static async fetchAccountDataShipper(id, token) {
        const url = `admin/s/fetch-account-meta/${id}`;

        return httpService(HttpMethods.GET, url, null, {
            token,
            type: 'user_metrics',
            method: 'read',
            platform: 'Admin'
        })
            .then((res) => {
                return res;
            })
            .catch((err) => console.log(err));
    }


    static async updateAccountMetaForwarder(data, token) {
        const url = `admin/f/update-account-meta`;

        return httpService(HttpMethods.POST, url, data, {
            token,
            type: 'user_metrics',
            method: 'edit',
            platform: 'Admin'
        })
            .then((res) => {
                return res;
            })
            .catch((err) => console.log(err));
    }

    static async fetchAccountDataForwarder(id, token) {
        const url = `admin/f/fetch-account-meta/${id}`;

        return httpService(HttpMethods.GET, url, null, {
            token,
            type: 'user_metrics',
            method: 'read',
            platform: 'Admin'
        })
            .then((res) => {
                return res;
            })
            .catch((err) => console.log(err));
    }


    static async fetchQuoteSearchHistory(data, token) {
        const url = `admin/quote-search-history/fetch`;

        return httpService(HttpMethods.POST, url, data, {
            token,
            type: 'user_metrics',
            method: 'quoteSearchHistory',
            platform: 'Admin'
        })
            .then((res) => {
                return res;
            })
            .catch((err) => console.log(err));
    }

    static async deleteQuoteSearchHistory(data, token) {
        const url = `admin/quote-search-history/delete`;

        return httpService(HttpMethods.POST, url, data, {
            token,
            type: 'user_metrics',
            method: 'quoteSearchHistory',
            platform: 'Admin'
        })
            .then((res) => {
                return res;
            })
            .catch((err) => console.log(err));
    }




}