import { Divider } from "@material-ui/core";
import Style from "./Summary.module.css";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { DatePicker, Input, Select, AutoComplete } from "antd";
import ConfirmDialog from "../../ConfirmDialog/ConfirmDialog";
import SectionSubHeading from "components/Common/Heading/SectionSubHeading/SectionSubHeading";
import ConstantValuesService from "service/ConstantValuesService";
import { CARGO_CATEGORIES, TRANSPORT_MODES, TRANSPORT_MODES_LIST, fetchCargoCategories, fetchContainerTypes } from "util/constants";
import SummaryPop from "./SummaryPop";
import { transitTimeCalculator } from "util/RateSheet/rateSheet";
import { CarrierVesselsService, SourceManagmentService, TagManagmentService } from 'service';
import { useSelector } from "react-redux";
import { SubSystemType } from "util/subSystemType";
import { findHighestPriceItem, findLowestPriceItem, initialPriceBreakDown, sectionName } from "../../../RateSheetContent";
import CarrierLoadTypeSelect from "./CarrierLoadTypeSelect";
/* eslint-disable */

const Summary = ({
    chatEditOffer,
    method,
    additionalData,
    setAdditionalData,
    handleAdditionalData,
    data,
    breakdownData,
    setBreakdownData,
    handleSubmit,
    isMakeOffer = false,
    error,
    setError,
    isSubmitDisable,
    transportMode,
    handleClose,
    checkTransitTimes,
    transportationLineArray,
    setTransportationLineArray,
    transportUnitNameArray,
    setTransportUnitNameArray
}) => {

    const auth = useSelector(state => state.session.auth)

    // const [total, setTotal] = useState(0);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [confirmOfferDialogOpen, setConfirmOfferDialogOpen] = useState(false);


    const calculateSubTotal = (data, title) => {
        let sum = 0;
        let array = data

        // if (transportMode === TRANSPORT_MODES.AIR.label && title === sectionName["Shipment Charges"]) {
        //     array = findLowestPriceItem(data)
        // }
        if (title === sectionName["Shipment Charges"]) {
            if (transportMode === TRANSPORT_MODES.AIR.label) {
                array = findLowestPriceItem(data)
            }
            if (transportMode === TRANSPORT_MODES.SEA.label && additionalData?.loadType === CARGO_CATEGORIES.RORO.label) {
                array = findLowestPriceItem(data)
            }
        }
        array.map(amount_data => sum += Number(amount_data.amount));
        return sum
    }




    // this handles the date changes 
    const handleDateChange = (e, date_type) => {
        const date = e;
        setError("")

        if (new Date(date.target.value) < new Date().setHours(0, 0, 0, 0)) {
            return
        }

        setBreakdownData((previousData) => ({
            ...previousData,
            [date_type]: date.target.value
        }))
    }
    const handleTransitTimeChange = (newValue, key1, key2) => {
        setError("")
        if (newValue < 0) {
            return
        }
        setBreakdownData((prevData) => ({
            ...prevData,
            transit_time: {
                ...prevData.transit_time,
                [key1]: {
                    ...prevData.transit_time[key1],
                    [key2]: newValue,
                },
            },
        }));
    };

    useEffect(() => {
        if (breakdownData?.transit_time?.are_both_equal) {
            handleTransitTimeChange(
                breakdownData?.transit_time?.estimate?.max,
                "port_to_port",
                "max"
            )
            handleTransitTimeChange(
                breakdownData?.transit_time?.estimate?.min,
                "port_to_port",
                "min"
            )
        }
    }, [breakdownData.transit_time.are_both_equal])



    // const [tags, setTags] = useState([])
    // const [sources, setSources] = useState([])

    const pageData = useSelector((state) => state.makeOfferDetailsReducer)

    // useEffect(() => {
    //     const data_to_send = {
    //         type: SubSystemType.RATE_SHEET,
    //         is_active: true
    //     }
    //     TagManagmentService.fetchTag(data_to_send, auth.token, "rate_sheet", "create").then(res => {
    //         if (res.error) {
    //             console.log(res.error)
    //         } else {
    //             const array = []
    //             res.data.map((item) => {
    //                 array.push({
    //                     label: item.name,
    //                     value: item._id
    //                 })
    //             })
    //             setTags(array)
    //         }
    //     })
    // }, [])

    // useEffect(() => {
    //     const data_to_send = {
    //         type: SubSystemType.RATE_SHEET,
    //         is_active: true
    //     }
    //     SourceManagmentService.fetchSource(data_to_send, auth.token, "rate_sheet", "create").then(res => {
    //         if (res.error) {
    //             console.log(res.error)
    //         } else {
    //             const array = []
    //             res.data.map((item) => {
    //                 array.push({
    //                     label: item.name,
    //                     value: item._id
    //                 })
    //             })
    //             setSources(array)
    //         }
    //     })
    // }, [])





















    const [summaryPopUp, setSummaryPopUp] = useState(false)
    const [summaryData, setSummaryData] = useState(null)




    // const getPanelValue = (searchText) =>
    //     !searchText ? [] : [mockVal(searchText), mockVal(searchText, 2), mockVal(searchText, 3)];



    // Example usage in your component
    const transitTimeValidation = checkTransitTimes(breakdownData?.transit_time);


    const renderPriceLabel = () => {
        let label = "Subtotal"
        if (transportMode === TRANSPORT_MODES.AIR.label) {
            label = "Starting With"
        }
        if (transportMode === TRANSPORT_MODES.SEA.label && additionalData?.loadType === CARGO_CATEGORIES.RORO.label) {
            label = "Starting With"
        }
        return label
    }



    useEffect(() => {
        ConstantValuesService.fetchCarrier(transportMode, "", auth.token, "").then(res => {
            if (res.error) {
                console.log(res.error)
            } else {
                const array = []
                res.length > 0 && res.map((item) => {
                    array.push({
                        value: item._id,
                        label: item.fullName
                    })
                })
                setTransportationLineArray(array)
            }
        })
    }, [])


    useEffect(() => {
        if (!additionalData.carrier_id) {
            return
        }
        CarrierVesselsService.fetchCarrierVesselsData(
            additionalData.carrier_id,
            auth.token,
            '',
            ''
        ).then((res) => {
            if (res.error) {
                console.log(res.error);
            } else {
                const array = res.data.map((item) => {
                    return {
                        label: item.name,
                        value: item._id
                    };
                });
                // console.log(array);
                setTransportUnitNameArray(array);
            }
        });
    }, [additionalData.carrier_id]);





    return (
        <>


            <div className={Style.container}>
                <span className={Style.heading}>Summary</span>

                <div className={Style.main}>
                    <CarrierLoadTypeSelect
                        breakdownData={breakdownData}
                        setBreakdownData={setBreakdownData}
                        additionalData={additionalData}
                        setAdditionalData={setAdditionalData}
                        isMakeOffer={isMakeOffer}
                        transportMode={transportMode}
                        pageData={pageData}
                        handleClose={handleClose}
                    // transportUnitNameArray={transportUnitNameArray}
                    // setTransportUnitNameArray={setTransportUnitNameArray}
                    // transportationLineArray={transportationLineArray}
                    // setTransportationLineArray={setTransportationLineArray}
                    />
                </div>
                <div className={Style.main}>


                    <div className="d-flex flex-column c-gap-1">
                        <span className={Style.detail_text_total}>Transport Details</span>
                        <Divider />

                    </div>

                    <div className={Style.flex_col}>
                        <span className={Style.detail_text}>Select Carrier*</span>
                        <Select
                            options={transportationLineArray}
                            // disabled={method === "edit"}
                              allowClear={true}
                            value={additionalData?.carrier_id}
                            onChange={(value) => { handleAdditionalData("carrier_id", value) }}
                        />
                    </div>
                    {additionalData?.carrier_id &&
                        <div className={Style.flex_col}>
                            <label htmlFor="carrier_vessels" className={Style.detail_text}>Select Vessel</label>

                            <AutoComplete
                                value={transportUnitNameArray?.filter(item => item.value === additionalData?.carrier_vessels)[0]?.label || ""}
                                options={transportUnitNameArray}
                                onSelect={(value) => handleAdditionalData("carrier_vessels", value)}
                                placeholder="Enter your text"
                                filterOption={(inputValue, option) =>
                                    option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                                allowClear
                                autoFocus
                                onClear={() => handleAdditionalData("carrier_vessels", "")}
                            />

                        </div>
                    }

                    <div className={Style.flex_col}>
                        <label htmlFor="estimate-time" className={Style.detail_text}>Estimate Transit Time (in days)*</label>
                        <div className="d-flex c-gap-1">
                            <div>
                                <label htmlFor="estimate-time" className={Style.detail_text}>Min</label>
                                <Input
                                    style={{
                                        borderColor: transitTimeValidation.estimateMin ? "red" : "#d9d9d9"
                                    }}
                                    onWheel={(e) => e.target.blur()}
                                    id="estimate-time"
                                    name="estimate_time"
                                    type="number"
                                    min={0}
                                    value={breakdownData?.transit_time?.estimate?.min}
                                    required
                                    onChange={(e) =>
                                        handleTransitTimeChange(
                                            e.target.value === "" ? null : parseFloat(e.target.value),
                                            "estimate",
                                            "min"
                                        )
                                    }
                                />
                            </div>
                            <div>
                                <label htmlFor="estimate-time" className={Style.detail_text}>Max</label>
                                <Input
                                    id="estimate-time"
                                    name="estimate_time"
                                    onWheel={(e) => e.target.blur()}
                                    type="number"
                                    value={breakdownData?.transit_time?.estimate?.max}
                                    required
                                    min={0}
                                    onChange={(e) =>
                                        handleTransitTimeChange(
                                            e.target.value === "" ? null : parseFloat(e.target.value),
                                            "estimate",
                                            "max"
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <div className={Style.flex_col}>
                        <label htmlFor="p-to-p-time" className={Style.detail_text}>Port to Port Transit Time (in days)*</label>
                        <div className="d-flex c-gap-1">
                            <div>
                                <label htmlFor="p-to-p-time" className={Style.detail_text}>Min</label>
                                <Input
                                    style={{
                                        borderColor: transitTimeValidation.portToPortMin ? "red" : "#d9d9d9"
                                    }}
                                    id="p-to-p-time"
                                    onWheel={(e) => e.target.blur()}
                                    name="p_to_p_time"
                                    disabled={breakdownData?.transit_time?.are_both_equal}
                                    type="number"
                                    min={0}
                                    value={breakdownData?.transit_time?.port_to_port?.min}
                                    required
                                    onChange={(e) =>
                                        handleTransitTimeChange(
                                            e.target.value === "" ? null : parseFloat(e.target.value),
                                            "port_to_port",
                                            "min"
                                        )
                                    }
                                />
                            </div>
                            <div>
                                <label htmlFor="p-to-p-time" className={Style.detail_text}>Max</label>
                                <Input
                                    style={{
                                        borderColor: transitTimeValidation.portToPortMax ? "red" : "#d9d9d9"
                                    }}
                                    disabled={breakdownData?.transit_time?.are_both_equal}
                                    id="p-to-p-time"
                                    name="p_to_p_time"
                                    onWheel={(e) => e.target.blur()}
                                    type="number"
                                    min={0}
                                    value={breakdownData?.transit_time?.port_to_port?.max}
                                    required
                                    onChange={(e) =>
                                        handleTransitTimeChange(
                                            e.target.value === "" ? null : parseFloat(e.target.value),
                                            "port_to_port",
                                            "max"
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>


                    <label className={`d-flex align-items-center ${Style.detail_text}`} >
                        <input
                            type="checkbox"
                            checked={breakdownData?.transit_time?.are_both_equal}
                            name={"are_both_equal"}
                            onChange={(e) => {
                                // if (e.target.checked) {
                                //     handleTransitTimeChange(
                                //         breakdownData?.transit_time?.estimate?.max,
                                //         "port_to_port",
                                //         "max"
                                //     )
                                //     handleTransitTimeChange(
                                //         breakdownData?.transit_time?.estimate?.min,
                                //         "port_to_port",
                                //         "min"
                                //     )
                                // }
                                setBreakdownData({
                                    ...breakdownData,
                                    transit_time: {
                                        ...breakdownData.transit_time,
                                        are_both_equal: e.target.checked
                                    }
                                })
                            }}
                        />
                        <span className="ml-1">Is port to port time same as estimate time</span>
                    </label>





                    <div className={Style.flex_col}>
                        <span className={Style.detail_text}>Effective Date*</span>
                        <Input
                            type="date"
                            disabled={method === "edit"}
                            min={new Date().toISOString().split("T")[0]}
                            onChange={(e) => {
                                handleDateChange(e, "effective_date");
                                setAdditionalData({
                                    ...additionalData,
                                    departureDate: null,
                                    arrival_date: null
                                })
                            }}
                            value={breakdownData.effective_date}
                            name="effective_date"
                        />
                    </div>


                    <div className={Style.flex_col}>
                        <span className={Style.detail_text}>Expiry Date</span>
                        <Input
                            type="date"
                            // disabled={method === "edit"}
                            min={breakdownData.effective_date || new Date().toISOString().split("T")[0]}
                            value={breakdownData?.expiry_date}
                            name="expiry_date"
                            onChange={(e) => { handleDateChange(e, "expiry_date") }}
                        />
                    </div>








                    <div className={Style.flex_col}>
                        <span className={Style.detail_text}>Scheduled Departure Date</span>
                        <Input
                            type="date"
                            onChange={(e) => {
                                if ((breakdownData.effective_date !== null) && (new Date(e.target.value) < new Date(breakdownData.effective_date).setHours(0, 0, 0, 0))) {
                                    return
                                }
                                // console.log(e.target.value)
                                setAdditionalData({
                                    ...additionalData,
                                    departureDate: e.target.value,
                                    arrival_date: null
                                })
                            }}
                            min={new Date().toISOString().split("T")[0]}
                            value={additionalData?.departureDate}
                            name="departureDate"
                        />
                    </div>


                    <div className={Style.flex_col}>
                        <span className={Style.detail_text}>Scheduled Arrival Date</span>
                        <Input
                            type="date"
                            value={additionalData?.arrival_date}
                            name="arrival_date"
                            min={additionalData?.departureDate || new Date().toISOString().split("T")[0]}
                            onChange={(e) => {
                                if ((additionalData?.departureDate !== null) && (new Date(e.target.value) < new Date(additionalData?.departureDate).setHours(0, 0, 0, 0))) {
                                    return
                                }
                                setAdditionalData({
                                    ...additionalData,
                                    arrival_date: e.target.value,
                                })
                            }}
                        />
                    </div>






                    {/* 
                    <div className={Style.flex_col}>
                        <span className={Style.detail_text}>Select Tags</span>
                        <Select
                            options={tags}
                            mode="multiple"
                            placeholder="Select Tag"
                            defaultValue={additionalData?.tag}
                            onChange={(value) => {
                                handleAdditionalData("tag", value)
                            }}
                        />
                    </div>
                    <div className={Style.flex_col}>
                        <span className={Style.detail_text}>Select Source</span>
                        <Select
                            options={sources}
                            value={additionalData?.source_id}
                            // disabled={method === "edit"}
                            onChange={(value) => handleAdditionalData("source_id", value)}
                        />
                    </div> */}

                    {/* 

                    {breakdownData?.price_breakdown?.map((data, idx) => {
                        return (
                            <div key={idx} className={Style.flex_justify_between}>
                                <span className={Style.detail_text}>{data.title}</span>
                                <span className={Style.detail_text_bold}>{calculateSubTotal(data.data, data.title)}</span>
                            </div>
                        )
                    })} */}

                    {/* 
                    <div className={Style.flex_justify_between}>
                        <span className={Style.detail_text_total}>{renderPriceLabel()}:</span>
                        <span className={Style.detail_text_total}>${breakdownData?.minAmount?.value}</span>
                    </div> */}



                    {/* if its for MakeOffer then show only make offer button  */}
                    {/* {!chatEditOffer && */}
                    {/* <>
                        {!isMakeOffer
                            ?
                            <>
                                
                               
                                {error && <span style={{ color: "red", fontSize: "12px" }}>{error}</span>}
                                <div className={Style.add_form}>
                                    <Button
                                        onClick={() => {
                                            if (method === "edit") {
                                                setConfirmDialogOpen(true)
                                            } else {
                                                if (
                                                    !additionalData.loadType ||
                                                    !additionalData.carrier_id ||
                                                    !additionalData.containerType ||
                                                    // !additionalData.type ||
                                                    !breakdownData.effective_date ||
                                                    !breakdownData.transit_time.estimate.min ||
                                                    !breakdownData.transit_time.estimate.max ||
                                                    !breakdownData.transit_time.port_to_port.min ||
                                                    !breakdownData.transit_time.port_to_port.max
                                                ) {
                                                    setError("Please fill in all the required fields marked with *")
                                                    // notify(dispatch, "error", "Please Select Required Fields")
                                                    return
                                                }
                                                setSummaryPopUp(true)

                                                const data_to_set = {
                                                    estimate_transit_time: transitTimeCalculator(breakdownData?.transit_time.estimate),
                                                    port_to_port_transit_time: transitTimeCalculator(breakdownData?.transit_time.port_to_port),
                                                    transport_line: transportationLineArray.filter(item => item.value === additionalData?.carrier_id)[0]?.label,
                                                    effective_date: breakdownData?.effective_date,
                                                    expiry_date: breakdownData?.expiry_date,
                                                    transport_unit_name: transportUnitNameArray?.filter(item => item.value === additionalData?.carrier_vessels)[0]?.label,
                                                    amount: breakdownData?.minAmount,
                                                }
                                                setSummaryData(data_to_set)
                                            }
                                        }}
                                        disabled={
                                            (
                                                transitTimeValidation.portToPortMin ||
                                                transitTimeValidation.portToPortMax ||
                                                transitTimeValidation.estimateMin ||
                                                (breakdownData.expiry_date !== null && breakdownData.expiry_date < breakdownData.effective_date) ||
                                                (isSubmitDisable.length > 0 && isSubmitDisable.includes(true))
                                            ) ? true : false}
                                        style={{ textTransform: 'none', backgroundColor: '#4431bb', color: 'white' }}
                                    >
                                        {method === "edit" ? "Update" : "Add Now"}
                                    </Button>
                                </div>
                            </>
                            :
                            // make offer button 
                            <>
                                {error && <span style={{ color: "red", fontSize: "12px" }}>{error}</span>}
                                <Button
                                    onClick={() => setConfirmOfferDialogOpen(true)}
                                    disabled={
                                        (
                                            transitTimeValidation.portToPortMin ||
                                            transitTimeValidation.portToPortMax ||
                                            transitTimeValidation.estimateMin ||
                                            (breakdownData.expiry_date !== null && breakdownData.expiry_date < breakdownData.effective_date) ||
                                            (isSubmitDisable.length > 0 && isSubmitDisable.includes(true))
                                        ) ? true : false}
                                    style={{ textTransform: 'none', backgroundColor: '#4431bb', color: 'white' }}
                                >
                                    Make Offer
                                </Button>
                            </>
                        }

                    </> */}
                    {/* } */}


                    {/* {chatEditOffer &&

                    <>
                        <Button
                            onClick={() => setConfirmOfferDialogOpen(true)}
                            style={{ textTransform: 'none', backgroundColor: '#4431bb', color: 'white' }}
                        >
                            Send Offer
                        </Button>

                    </>
                } */}

                </div>

                {/* edit and add rate sheet dialog  */}
                <ConfirmDialog
                    confirmDialogOpen={confirmDialogOpen}
                    setConfirmDialogOpen={setConfirmDialogOpen}
                    confirmDialogFunction={handleSubmit}
                    question={method === "edit" ? "Are you sure you want to update this rate-sheet?" : "Are you sure you want to add this rate-sheet?"}
                />

                {/* make offer confirmation dialog  */}
                <ConfirmDialog
                    confirmDialogOpen={confirmOfferDialogOpen}
                    setConfirmDialogOpen={setConfirmOfferDialogOpen}
                    confirmDialogFunction={handleSubmit}
                    question={"Are you sure you want to make this offer?"}
                />


                {/* create rate sheet summary */}
                {summaryPopUp &&
                    <SummaryPop
                        open={summaryPopUp}
                        handleSubmit={handleSubmit}
                        handleClose={() => setSummaryPopUp(false)}
                        data={summaryData}
                    />
                }

            </div>
        </>
    )
}






/* eslint-disable */
export default Summary