import React, { useEffect, useState } from 'react';
import { Button, MenuItem, Tooltip } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BlogService, CareerService } from 'service';
import { notify } from 'sites/shipper/components/Toast/toast';
import { Empty } from 'antd';

import LoaderComponent from 'sites/shipper/components/LoaderComponent';
import AddCareer from './AddCareer/AddCareer';
import CareerTableWrapper from './CareerTable/CareerTableWrapper/CareerTableWrapper';
import CareerTableRow from './CareerTable/CareerTableRow/CareerTableRow';
import { Pagination } from '@mui/material';
import ConfirmDialog from '../../shipment_rates/rate_sheet/ConfirmDialog/ConfirmDialog';
import SkeletonComponent from 'sites/shipper/components/Skeleton/Skeleton';
import CachedIcon from '@mui/icons-material/Cached';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import SmallPopupWrapper from 'components/Common/SmallPopupWrapper/SmallPopupWrapper';
import TableWithSearchBar from 'components/Tables/TableWithSearchBar';
/* eslint-disable */
const Career = () => {
  const [open, setOpen] = useState(false);

  const [run, setRun] = useState(false);
  const [careers, setCareers] = useState([]);

  const [careerData, setCareerData] = useState(null);

  const [careerCount, setCareerCount] = useState(0);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState('');

  const auth = useSelector((state) => state.session.auth);

  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  const pageValue = searchParams.get('page') ? searchParams.get('page') : 1;
  const [page, setpage] = useState(parseInt(pageValue));
  const itemsPerPage = 15;
  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const [check, setCheck] = useState(true);

  useEffect(() => {
    if (loading) return;

    const element = document.getElementById('top');
    element.scrollIntoView({ behavior: 'smooth' });
  }, [check]);

  function handlePageChange(event, value) {
    setpage(value);
    setCheck(!check);
    history.push(`/career?page=${value}`);
  }

  useEffect(() => {
    setLoading(true);

    const data = {
      userId: auth.accountId,
      query: search
    };
    CareerService.fetchCareers(data, auth.token, page).then((res) => {
      if (res.error) {
        notify(dispatch, 'error', res.error);
        setLoading(false);
      } else {
        setCareers(res?.data);
        setCareerCount(res?.careerCount);
        setLoading(false);
      }
    });
  }, [run, search, page]);

  const [moreOptionClicked, setMoreOptionClicked] = useState(null);

  const handleRemoveCareer = async () => {
    setLoading(true);
    const data_to_send = {
      userId: auth.accountId,
      careerId: moreOptionClicked._id,
      careerName:moreOptionClicked.title
    };

    CareerService.removeCareer(data_to_send, auth.token).then((res) => {
      if (res.error) {
        notify(dispatch, 'error', res.error);
      } else {
        notify(dispatch, 'success', res.message);
        setRun(!run);
      }
      setLoading(false);
    });
  };

  const tableCellStyle = {
    padding: '12px',
    border: '1px solid #ddd',
    textAlign: 'left'
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////////
  const [openMoreOptions, setOpenMoreOptions] = useState(false);

  const TableHeading = [
    'S.No.',
    'Title',
    'Description',
    'Job Type',
    'Job Location',
    'Apply Link',

    'Slug',
    'Action'
  ];

  const Keys = [
    'index',
    'title',
    'description',
    'jobType',
    'jobLocation',
    'applyLink',
     'slug',
    '_id'
  ];

  const TableData = careers?.map((item, index) =>
    Keys.map((Key) => {
      if (Key === 'index') {
        return index + 1;
      }
      if (Key === '_id') {
        return (
          <>
            <div className="position-relative">
              <center>
                <Tooltip title="More" arrow>
                  <Button
                    variant="text"
                    className="btn-outline-primary d-flex align-items-center justify-content-center d-30 p-0 rounded-pill shadow-none"
                    onClick={(e) => {
                      setOpenMoreOptions(e.currentTarget);
                      setMoreOptionClicked(item);
                    }}>
                    <MoreVertTwoToneIcon style={{ fontSize: '18px' }} />
                  </Button>
                </Tooltip>
              </center>
              {moreOptionClicked?._id === item?._id && (
                <SmallPopupWrapper
                  open={openMoreOptions}
                  onClose={() => {
                    setMoreOptionClicked(null);
                    setOpenMoreOptions(null);
                  }}>
                  <MenuItem
                    onClick={() => {
                      setOpen(true);
                     setCareerData(moreOptionClicked);
                      setOpenMoreOptions(null);
                    }}>
                    Edit
                  </MenuItem>

                  {
                    <MenuItem
                      onClick={() => {
                        setConfirmSubmit(true);
                        setOpenMoreOptions(null);
                      }}>
                      Delete
                    </MenuItem>
                  }
                </SmallPopupWrapper>
              )}
            </div>
          </>
        );
      }

 else {
        return item[Key] || '';
      }
    })
  );

  return (
    <div id="top">
      {/* {loading && <LoaderComponent loading={loading} />} */}
      <AddCareer
        open={open}
        setOpen={setOpen}
        run={run}
        setRun={setRun}
        handleClose={() => setOpen(false)}
        careerData={careerData}
        setCareerData={setCareerData}
      />

      <TableWithSearchBar
        animatedSearchbar={{
          show: true,
          value: search,
          onChange: (value) => setSearch(value),
          setLoading: setLoading
        }}
        loading={loading}
        TableHeading={TableHeading}
        TableBody={TableData}
        TableGrid={'0.1fr repeat(7,1fr)'}
        actionButtons={[
          <Button
            variant="text"
            onClick={() => setRun(!run)}
            className={`btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill`}>
            <CachedIcon className="w-50" />
          </Button>,
          <Button
            onClick={() => {
              setOpen(true);
              setCareerData(null);
            }}
            variant="text"
            className={`btn-primary shadow-none py-2`}>
            Add Career
          </Button>
        ]}
        pagination={{
          count: Math.ceil(careerCount / itemsPerPage),
          page: page,
          onChange: handlePageChange
        }}
      />
      <ConfirmDialog
        confirmDialogFunction={handleRemoveCareer}
        confirmDialogOpen={confirmSubmit}
        setConfirmDialogOpen={setConfirmSubmit}
        question={`Are you sure to delete`}
      />
    </div>
  );
};
/* eslint-enable */

export default Career;
