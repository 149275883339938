import { Button, MenuItem, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BlogService } from 'service';
import { notify } from 'sites/shipper/components/Toast/toast';
import BlogTableWrapper from './BlogTable/BlogTableWrapper/BlogTableWrapper';
import BlogTableRow from './BlogTable/BlogTableRow/BlogTableRow';
import { Empty } from 'antd';
import LoaderComponent from 'sites/shipper/components/LoaderComponent';
import { Pagination } from '@mui/material';
import ConfirmDialog from '../../shipment_rates/rate_sheet/ConfirmDialog/ConfirmDialog';
import SkeletonComponent from 'sites/shipper/components/Skeleton/Skeleton';
import TableWithSearchBar from 'components/Tables/TableWithSearchBar';
import CachedIcon from '@mui/icons-material/Cached';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import SmallPopupWrapper from 'components/Common/SmallPopupWrapper/SmallPopupWrapper';

/* eslint-disable */
const Blog = ({ tab }) => {
  const [blogs, setBlogs] = useState([]);
  const [blogCount, setBlogCount] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const [run, setRun] = useState(false);

  const auth = useSelector((state) => state.session.auth);

  const [search, setSearch] = useState('');

  const searchParams = new URLSearchParams(location.search);

  const pageValue = searchParams.get('page') ? searchParams.get('page') : 1;
  const [page, setpage] = useState(parseInt(pageValue));
  const itemsPerPage = 15;

  const [check, setCheck] = useState(true);

  useEffect(() => {
    if (loading) return;

    const element = document.getElementById('top');
    element.scrollIntoView({ behavior: 'smooth' });
  }, [check]);

  function handlePageChange(event, value) {
    setpage(value);
    setCheck(!check);
    history.push(`/articles?tab=${tab}&page=${value}`);
  }

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);

    const data = {
      query: search,
      id: auth.accountId,
      page: page
    };
    BlogService.fetchBlogs(data, auth.token).then((res) => {
      if (res.error) {
        notify(dispatch, 'error', res.error);
      } else {
        setBlogs(res?.data);
        setBlogCount(res?.blogCount);
      }
      setLoading(false);
    });
  }, [run, search, page]);

  const [moreOptionClicked, setMoreOptionClicked] = useState(null);
  const handleRemoveBlog = async () => {
    if (!moreOptionClicked) {
      return
    }
    setLoading(true);
    const data_to_send = {
      blogId: moreOptionClicked?._id,
      blogName: moreOptionClicked?.title
    };

    BlogService.removeBlog(data_to_send, auth.token).then((res) => {
      if (res.error) {
        notify(dispatch, 'error', res.error);
      } else {
        notify(dispatch, 'success', res.message);
        setRun(!run);
      }
      setLoading(false);
    });
  };

  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const tableCellStyle = {
    padding: '12px',
    border: '1px solid #ddd',
    textAlign: 'left'
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////////
  const [openMoreOptions, setOpenMoreOptions] = useState(false);

  const TableHeading = [
    'S.No.',
    'Hero Image',
    'Title',
    'Is Featured',
    'Created By',
    'Updated By',
    'Status',
    'Action'
  ];

  const Keys = [
    'index',
    'heroImage',
    'title',
    'isFeatured',
    'createdBy',
    'updatedBy',
    'status',
    '_id'
  ];

  const TableData = blogs?.map((item, index) =>
    Keys.map((Key) => {
      if (Key === 'index') {
        return index + 1;
      }
      if (Key === '_id') {
        return (
          <>
            <div className="position-relative">
              <center>
                <Tooltip title="More" arrow>
                  <Button
                    variant="text"
                    className="btn-outline-primary d-flex align-items-center justify-content-center d-30 p-0 rounded-pill shadow-none"
                    onClick={(e) => {
                      setOpenMoreOptions(e.currentTarget);
                      setMoreOptionClicked(item);
                    }}>
                    <MoreVertTwoToneIcon style={{ fontSize: '18px' }} />
                  </Button>
                </Tooltip>
              </center>
              {moreOptionClicked?._id === item?._id && (
                <SmallPopupWrapper
                  open={openMoreOptions}
                  onClose={() => {
                    setMoreOptionClicked(null);
                    setOpenMoreOptions(null);
                  }}>
                  <MenuItem
                    onClick={() => {
                      history.push(
                        `/blog-create?slug=${moreOptionClicked?.slug}`
                      );
                      setOpenMoreOptions(null);
                    }}>
                    Edit
                  </MenuItem>

                  {
                    <MenuItem
                      onClick={() => {
                        setConfirmSubmit(true);
                        setOpenMoreOptions(null);
                      }}>
                      Delete
                    </MenuItem>
                  }
                </SmallPopupWrapper>
              )}
            </div>
          </>
        );
      }

      if (Key === 'isFeatured') {
        return item[Key] ? 'Yes' : 'No';
      }


      if (Key === 'heroImage') {
        return (
          <>
            {item?.heroImage?.url ? (
              <img
                style={{
                  height: '50px',
                  width: '50px',
                  objectFit: 'cover'
                }}
                src={item?.heroImage?.url}
                alt={`Hero Image for ${item?.title}`}
              />
            ) : (
              'no image'
            )}
          </>
        );
      } else {
        return item[Key] || '';
      }
    })
  );

  return (
    <div id="top">
      <TableWithSearchBar
        animatedSearchbar={{
          show: true,
          value: search,
          onChange: (value) => setSearch(value),
          setLoading: setLoading
        }}
        loading={loading}
        TableHeading={TableHeading}
        TableBody={TableData}
        TableGrid={'0.1fr repeat(7,1fr)'}
        actionButtons={[
          <Button
            variant="text"
            onClick={() => setRun(!run)}
            className={`btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill`}>
            <CachedIcon className="w-50" />
          </Button>,
          <Button
            onClick={() => history.push('/blog-create')}
            variant="text"
            className={`btn-primary shadow-none py-2`}>
            Create Blog
          </Button>
        ]}
        pagination={{
          count: Math.ceil(blogCount / itemsPerPage),
          page: page,
          onChange: handlePageChange
        }}
      />
      <ConfirmDialog
        confirmDialogFunction={handleRemoveBlog}
        confirmDialogOpen={confirmSubmit}
        setConfirmDialogOpen={setConfirmSubmit}
        question={`Are you sure to delete`}
      />

    </div>
  );
};
/* eslint-enable */
export default Blog;
