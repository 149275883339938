import { AutoComplete, Select } from 'antd'
import ModalSimple from 'components/ModalSimple'
import React from 'react'
import ConstantValuesService from 'service/ConstantValuesService'
import { CARGO_CATEGORIES, fetchCargoCategories, fetchContainerTypes, TRANSPORT_MODES_LIST } from 'util/constants'
import { initialPriceBreakDown } from '../../../RateSheetContent'
import { CarrierVesselsService } from 'service'
import Style from "./Summary.module.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import StyledSelect from 'components/StyledSelect'
import { Button, Divider } from '@material-ui/core'
import { CARGO_TYPES } from '../../../../../../../util/constants'
import { getCargoWeightRange } from 'util/Shipment/shipmentLoads'
/* eslint-disable */
const CarrierLoadTypeSelect = ({
    additionalData,
    setAdditionalData,
    setBreakdownData,
    breakdownData,
    transportMode,
    isMakeOffer,
    pageData,
    handleClose,
    // transportUnitNameArray,
    // setTransportUnitNameArray,
    // transportationLineArray,
    // setTransportationLineArray
}) => {

    const auth = useSelector(state => state.session.auth)



    const [carrierLoadDetails, setCarrierLoadDetails] = useState({
        loadType: additionalData.loadType,
        containerType: additionalData.containerType,
        // carrier_id: additionalData.carrier_id,
        // carrier_vessels: additionalData.carrier_vessels,
    })

    // useEffect(() => {
    //     ConstantValuesService.fetchCarrier(transportMode, "", auth.token, "").then(res => {
    //         if (res.error) {
    //             console.log(res.error)
    //         } else {
    //             const array = []
    //             res.length > 0 && res.map((item) => {
    //                 array.push({
    //                     value: item._id,
    //                     label: item.fullName
    //                 })
    //             })
    //             setTransportationLineArray(array)
    //         }
    //     })
    // }, [])


    // useEffect(() => {
    //     if (!carrierLoadDetails.carrier_id) {
    //         return
    //     }
    //     CarrierVesselsService.fetchCarrierVesselsData(
    //         carrierLoadDetails.carrier_id,
    //         auth.token,
    //         '',
    //         ''
    //     ).then((res) => {
    //         if (res.error) {
    //             console.log(res.error);
    //         } else {
    //             const array = res.data.map((item) => {
    //                 return {
    //                     label: item.name,
    //                     value: item._id
    //                 };
    //             });
    //             // console.log(array);
    //             setTransportUnitNameArray(array);
    //         }
    //     });
    // }, [carrierLoadDetails.carrier_id]);

    const [selectCarrierLoadPopup, setSelectCarrierLoadPopup] = useState(!Boolean(additionalData?.loadType))

    const handleSelectLoad = () => {
        setAdditionalData({
            ...additionalData,
            loadType: carrierLoadDetails.loadType,
            containerType: carrierLoadDetails.containerType,
            // carrier_id: carrierLoadDetails.carrier_id,
            // carrier_vessels: carrierLoadDetails.carrier_vessels,
        })
        setBreakdownData({
            ...breakdownData,
            price_breakdown: initialPriceBreakDown(transportMode, pageData, carrierLoadDetails)
        })
        setSelectCarrierLoadPopup(false)
    }


    const transportModeList = TRANSPORT_MODES_LIST.filter(item => {
        if (item.label === transportMode) {
            return item
        }
    })[0]
    const loadTypeList = /*!isMakeOffer ?*/ fetchCargoCategories(transportModeList).map(item => { return ({ value: item.value, label: item.label }) }) /*": []*/

    const cointainerTypeList = /*!isMakeOffer ?*/ fetchContainerTypes(transportModeList, { value: carrierLoadDetails?.loadType, label: carrierLoadDetails?.loadType }).map(item => {
        return ({ value: item.value, label: item.label })
    }) /* ": []*/
    const handleAdditionalData = (name, value) => {
        // const { name, value } = e.target
        setCarrierLoadDetails({
            ...carrierLoadDetails,
            [name]: value
        })
    }


    console.log("kdjfhbvj",additionalData)
    return (
        <>
            <ModalSimple
                modalTitle={"Select Cargo Details"}
                open={selectCarrierLoadPopup}
                actionButtonText='Proceed'
                action={handleSelectLoad}
                handleClose={() => {
                    if (Boolean(additionalData?.loadType)) {
                        setSelectCarrierLoadPopup(false)
                    } else {
                        handleClose()
                    }
                }}
                fullWidth
                disabled={!carrierLoadDetails.loadType || !carrierLoadDetails.containerType}
                maxWidth="sm"
            >
                <div>
                    {/* <div className={Style.flex_col}>
                        <span className={Style.detail_text}>Select Carrier*</span>
                        <Select
                            options={transportationLineArray}
                            // disabled={method === "edit"}
                            value={carrierLoadDetails?.carrier_id}
                            onChange={(value) => { handleAdditionalData("carrier_id", value) }}
                        />
                    </div>
                    {carrierLoadDetails?.carrier_id &&
                        <div className={Style.flex_col}>
                            <label htmlFor="carrier_vessels" className={Style.detail_text}>Select Vessel</label>

                            <AutoComplete
                                value={transportUnitNameArray?.filter(item => item.value === carrierLoadDetails?.carrier_vessels)[0]?.label || ""}
                                options={transportUnitNameArray}
                                onSelect={(value) => handleAdditionalData("carrier_vessels", value)}
                                placeholder="Enter your text"
                                filterOption={(inputValue, option) =>
                                    option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                                allowClear
                                autoFocus
                                onClear={() => handleAdditionalData("carrier_vessels", "")}
                            />


                        </div>
                    } */}

                    {!isMakeOffer &&
                        <>
                            <div className={Style.flex_col}>
                                {/* <span className={Style.detail_text}>Load Type*</span>
                                <Select
                                    options={loadTypeList}
                                    value={carrierLoadDetails?.loadType}
                                    // disabled={method === "edit"}
                                    onChange={(value) => handleAdditionalData("loadType", value)}
                                /> */}
                                <StyledSelect
                                    options={loadTypeList}
                                    value={carrierLoadDetails?.loadType}
                                    label={"Select Load Type"}
                                    // disabled={method === "edit"}
                                    onChange={(e) => handleAdditionalData("loadType", e.target.value)}
                                />
                            </div>

                            {carrierLoadDetails.loadType &&
                                <div className={Style.flex_col}>
                                    {/* <span className={Style.detail_text}>Container Type*</span>
                                    <Select
                                        options={cointainerTypeList}
                                        // disabled={method === "edit"}
                                        value={carrierLoadDetails?.containerType}
                                        onChange={(value) => handleAdditionalData("containerType", value)}
                                    /> */}
                                    <StyledSelect
                                        options={cointainerTypeList.filter(item => item.value !== CARGO_TYPES.SHIP_OTHER_RORO.value)}
                                        // disabled={method === "edit"}
                                        value={carrierLoadDetails?.containerType}
                                        onChange={(e) => handleAdditionalData("containerType", e.target.value)}
                                        label={`Select ${carrierLoadDetails?.loadType === CARGO_CATEGORIES.RORO.value ? "Cargo Type" : "Container Type"}`}
                                    />
                                </div>
                            }
                            {additionalData?.loadType &&
                                <span className='text-warning font-size-xs'>
                                    Proceeding will reset the current price breakdown data.
                                </span>
                            }

                        </>
                    }
                </div>
            </ModalSimple>

            <div className='d-flex flex-column c-gap-1' >
                <div className='d-flex justify-content-between'>
                    <span className={Style.detail_text_total}>Cargo Details</span>
                    {!isMakeOffer &&
                        <Button
                            onClick={() => setSelectCarrierLoadPopup(true)}
                            variant="contained"
                            size="small"
                            className={"btn-primary"}
                        >
                            Edit
                        </Button>
                    }
                </div>
                <Divider />
                {/* <div className={Style.flex_col}>
                    <span className={`${Style.detail_text_total} font-size-sm`}>Carrier Name</span>
                    <span className={Style.detail_text}>{transportationLineArray.filter(item => item.value === additionalData?.carrier_id)[0]?.label || "NA"}</span>
                </div>

                <div className={Style.flex_col}>
                    <span className={`${Style.detail_text_total} font-size-sm`}>Carrier Vessel</span>
                    <span className={Style.detail_text}>{transportUnitNameArray?.filter(item => item.value === additionalData?.carrier_vessels)[0]?.label || "NA"}</span>
                </div> */}
                {/* {!isMakeOffer && */}
                <>
                    <div className={Style.flex_col}>
                        <span className={`${Style.detail_text_total} font-size-sm`}>Load Type</span>
                        <span className={Style.detail_text}>{additionalData?.loadType || "NA"}</span>
                    </div>


                    <div className={Style.flex_col}>
                        <span className={`${Style.detail_text_total} font-size-sm`}>{additionalData?.loadType === CARGO_CATEGORIES.RORO.value ? "Cargo" : "Container"} Type</span>
                        <span className={Style.detail_text}>{cointainerTypeList.filter(item => item.value === additionalData?.containerType)[0]?.label || "NA"}</span>
                    </div>
                    {isMakeOffer && pageData?.load?.cargoType?.value === CARGO_CATEGORIES.RORO.value &&
                        <div className={Style.flex_col}>
                            <span className={`${Style.detail_text_total} font-size-sm`}>Vehicle Type</span>
                            <span className={Style.detail_text}> {pageData?.load?.cargoDetails?.isVehicleElectric ? "Electric" : "Non Electric"}</span>
                        </div>
                    }

                    {isMakeOffer && getCargoWeightRange(pageData?.load) &&
                        <div className={Style.flex_col}>
                            <span className={`${Style.detail_text_total} font-size-sm`}>Requested Weight Range</span>
                            <span className={Style.detail_text}>{getCargoWeightRange(pageData?.load)}</span>
                        </div>
                    }
                    {isMakeOffer &&
                        <div className={Style.flex_col}>
                            <span className={`${Style.detail_text_total} font-size-sm`}>Cargo Description</span>
                            <span className={Style.detail_text}>{pageData?.load?.cargoDetails?.description ? pageData?.load?.cargoDetails?.description : "NA"}</span>
                        </div>
                    }
                </>
                {/* } */}
            </div>
        </>
    )
}

export default CarrierLoadTypeSelect