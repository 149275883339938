import React, { useState, useEffect } from 'react'
import { Divider } from "@material-ui/core";
import Style from "./Summary.module.css";
import { Button } from "@mui/material";
import { Select } from "antd";
import SectionSubHeading from "components/Common/Heading/SectionSubHeading/SectionSubHeading";
import { TagManagmentService, SourceManagmentService } from 'service';
import { useSelector } from "react-redux";
import { SubSystemType } from "util/subSystemType";
const MetaDetails = ({ handleAdditionalData, additionalData }) => {
    const auth = useSelector(state => state.session.auth)

    const [tags, setTags] = useState([])
    const [sources, setSources] = useState([])

    useEffect(() => {
        const data_to_send = {
            type: SubSystemType.RATE_SHEET,
            is_active: true
        }
        TagManagmentService.fetchTag(data_to_send, auth.token, "rate_sheet", "create").then(res => {
            if (res.error) {
                console.log(res.error)
            } else {
                const array = []
                res.data.map((item) => {
                    array.push({
                        label: item.name,
                        value: item._id
                    })
                })
                setTags(array)
            }
        })
    }, [])

    useEffect(() => {
        const data_to_send = {
            type: SubSystemType.RATE_SHEET,
            is_active: true
        }
        SourceManagmentService.fetchSource(data_to_send, auth.token, "rate_sheet", "create").then(res => {
            if (res.error) {
                console.log(res.error)
            } else {
                const array = []
                res.data.map((item) => {
                    array.push({
                        label: item.name,
                        value: item._id
                    })
                })
                setSources(array)
            }
        })
    }, [])


    return (
        <div className={Style.main}>
            <div className="d-flex flex-column c-gap-1">
                <span className={Style.detail_text_total}>Meta Details</span>
                <Divider />
            </div>
            <div className={Style.flex_col}>
                <span className={Style.detail_text}>Select Tags</span>
                <Select
                    options={tags}
                    mode="multiple"
                    placeholder="Select Tag"
                    defaultValue={additionalData?.tag}
                    onChange={(value) => {
                        handleAdditionalData("tag", value)
                    }}
                />
            </div>
            <div className={Style.flex_col}>
                <span className={Style.detail_text}>Select Source</span>
                <Select
                    options={sources}
                    value={additionalData?.source_id}
                    allowClear={true}
                    // disabled={method === "edit"}
                    onChange={(value) => handleAdditionalData("source_id", value)}
                />
            </div>
        </div>
    )
}

export default MetaDetails