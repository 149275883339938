import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import RateSheetService from 'service/RateSheetService'
import { Button, Tooltip } from "@material-ui/core";
import ModalPopupWrapper from 'components/Common/ModalPopup/ModalPopup';
import Preview from 'sites/shipper/pages/Chat/ChatViewPane/OfferChat/Preview/Preview'
import CachedIcon from '@mui/icons-material/Cached';
import TableWithSearchBar from 'components/Tables/TableWithSearchBar';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ViewAllPrices from '../ViewAllPrices/ViewAllPrices';
import moment from 'moment';
import { transitTimeCalculator } from 'util/RateSheet/rateSheet';

const BulkContainer = ({ route_id, run, setRun, loadType, transportMode }) => {
    const auth = useSelector((state) => state.session.auth)

    const [rates, setRates] = useState([])
    const [loading, setLoading] = useState(false)

    const [previewRateSheet, setPreviewRateSheet] = useState([])

    const [isPreviewOpen, setIsPreviewOpen] = useState(false);

    const togglePreview = (data) => {
        if (!isPreviewOpen) {
            setPreviewRateSheet(data)
        }
        setIsPreviewOpen((state) => !state);
    };

    const [searchKeyWord, setSearchKeyWord] = useState("")

    useEffect(() => {
        setLoading(true)
        const data_to_send = {
            admin_id: auth.accountId,
            route_id: route_id,
            load_type: loadType,
            searchKeyWord: searchKeyWord
        }
        RateSheetService.fetchRateSheetPriceInfo(data_to_send, auth.token).then(res => {
            if (res.error) {
                console.log(res.error)
            } else {
                setRates(res)
            }
            setLoading(false)
        })
    }, [run, searchKeyWord])

    const [viewPricePopUpOpen, setViewPricePopUpOpen] = useState(false)
    const [viewPriceBtnClickData, setViewPriceBtnClick] = useState()

    const handleViewPrices = (details) => {
        setViewPriceBtnClick(details)
        setViewPricePopUpOpen(true)
    }


    const TableHeading = [
        "Carrier",
        "Container Name",
        "Starting Price",
        "Effective Date",
        "Expiry Date",
        "Status",
        "Port to Port Transit Time",
        'Action'
    ]

    const Keys = [
        "carrier_id",
        "container_type",
        "price",
        "effective_date",
        "expiry_date",
        "status",
        "transit_time",
        "_id"
    ]



    const TableData = rates?.map(item => Keys.map(Key => {
        if (Key === '_id') {
            return <>
                <div className="position-relative">
                    <center>
                        <Tooltip title="View All Prices" arrow>
                            <Button
                                variant="text"
                                // startIcon={<VisibilityIcon />}
                                className={`btn-neutral-primary py-1 px-2`}
                                onClick={() => {
                                    handleViewPrices(item)
                                }}>
                                <VisibilityIcon />
                                {/* View All Prices */}
                            </Button>
                        </Tooltip>
                    </center>
                </div>

            </>
        }
        if (Key === "price") {
            return <>{`$${item?.price?.toLocaleString()}`}</>
        }
        if (Key === "effective_date" || Key === "expiry_date") {
            return item[Key] !== "NA" ? moment(item[Key]).format('LL') : "NA"
        }
        if (Key === "status") {
            let style = "m-0 px-3 py-0 h-auto text-success border-1 border-success rounded badge badge-neutral-success"
            if (item.status === "Upcoming") {
                style = "m-0 px-3 py-0 h-auto text-warning border-1 border-warning rounded badge badge-neutral-warning"
            }
            return (<p className={style}>{item.status}</p>)
        }
        if (Key === "carrier_id") {
            return item?.carrier_id?.fullName
        }
        if (Key === "transit_time") {
            return transitTimeCalculator(item.transit_time.port_to_port)
        }
        else {
            return item[Key] || "";
        }
    }))

    return (
        <>


            <TableWithSearchBar
                animatedSearchbar={{
                    show: true,
                    value: searchKeyWord,
                    onChange: (value) => setSearchKeyWord(value),
                    setLoading: setLoading
                }}
                loading={loading}
                TableHeading={TableHeading}
                TableBody={TableData}
                TableGrid={"repeat(6,1fr) 1.3fr 0.7fr"}
                actionButtons={[
                    <Button
                        variant="text"
                        onClick={() => setRun(!run)}
                        className={`btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill`}
                    >
                        <CachedIcon className="w-50" />
                    </Button>
                ]}
            />



            {viewPricePopUpOpen &&
                <ViewAllPrices
                    open={viewPricePopUpOpen}
                    handleClose={() => setViewPricePopUpOpen(false)}
                    data={viewPriceBtnClickData}
                    heading={"View All Prices"}
                    run={run}
                    setRun={setRun}
                    loadType={loadType}
                />
            }

            {/* Preview Rate */}
            <ModalPopupWrapper
                maxWidth={'lg'}
                noStyle
                open={isPreviewOpen}
            >
                {previewRateSheet.length > 0 &&
                    <Preview
                        data={previewRateSheet}
                        open={isPreviewOpen}
                        togglePreview={togglePreview}
                        transportMode={transportMode}
                        loadType={loadType}
                    />
                }
            </ModalPopupWrapper>










        </>
    )
}

export default BulkContainer